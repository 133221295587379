export function getToken() {
  try {
    let token = localStorage.getItem('token');
    return token;
  }catch(e){
    console.log(e);
    let token = localStorage.getItem('token');
    return token || '';
  }
}
