import {
  Component,
  OnInit,
  OnDestroy,
  Input
} from "@angular/core";

import {
  RelayService
} from "../services/relay-service";


import {NavigationStart, Router, ActivatedRoute, ParamMap} from '@angular/router';
import * as moment from "moment";
import 'rxjs/add/operator/switchMap';
import {NotifyService} from "../../lib/services/notify.service";

import {ChartModule} from 'angular2-highcharts';
import * as Highcharts from 'highcharts';
import {FormControl} from "@angular/forms";
import {ChoosenDateType, DateOptionsType} from "../relay.types";

@Component({
  selector: "relay-stats",
  templateUrl: "../templates/relay-stats.html",
  styleUrls: ["../styles/relay-stats.scss"]
})
export class RelayStatsCmp {
  dateType: ChoosenDateType;
  type: string;
  endpoint: string;
  range: any = [];
  line: any = [];
  readings: any;
  chart: Object;
  options: Object;
  chart3: Object;
  options3: any;
  values: any = {
    line: [],
    range: [],
    rangeMinMax:{
      min: 0,
      max: 0
    },
    lineA: [],
    rangeA: [],
    rangeAMinMax:{
      min: 0,
      max: 0
    },
    lineB: [],
    rangeB: [],
    rangeBMinMax:{
      min: 0,
      max: 0
    },
    lineC: [],
    rangeC: [],
    rangeCMinMax:{
      min: 0,
      max: 0
    },
    limits: [],
    limitsMinMax: {
      min: 0,
      max: 0
    }
  };
  _relayService: RelayService;
  _notifyService: NotifyService;
  loading: boolean = true;
  loadingData: boolean = true;
  fromDate = new FormControl(new Date());
  toDate = new FormControl(new Date());
  reportMode: string = 'day';
  from: string;
  to: string;
  dateString: string;
  currentDate: Date = new Date();
  energyDateTypes: DateOptionsType[] = [];
  allowDateTypes = ['today', 'yesterday', 'custom'];
  title: string = 'Напруга';
  relays: any;
  user: any;
  is3Phase: boolean = false;
  isCsvDropdownOpen: boolean = false;

  constructor(public relayService: RelayService,
              public notifyService: NotifyService) {
    this._relayService = relayService;
    this._notifyService = notifyService;
    console.log('RelayStatsCmp constructor', this.loading);

    this.dateString = moment(this.currentDate).format("MMM, YYYY");
    this.currentDate = new Date();

    let container = document.getElementsByClassName('container')[0],
      width;

    width = container?(container.clientWidth-48):(window.innerWidth - 30);

    this.outCsvClick = this.outCsvClick.bind(this)

    this.options = {
      time: {
        useUTC: false
      },
      rangeSelector: {
        enabled: false
      },
      chart: {
        type: 'spline',
        zoomType: 'x',
        width:  width
      },
      title: {text: ''},
      xAxis: {
        type: 'datetime',
        minRange: 30000
      },
      yAxis: {
        title: {
          text: 'Ампер'
        }
      },
      tooltip: {
        crosshairs: true,
        shared: true,
        valueSuffix: ' A'
      },
      legend: {
        enabled: true
      },
      series: [{
        name: 'Напруга',
        data: [],
        zIndex: 1,
        color: '#f3b255',
        marker: {
          enable: true,
          fillColor: '#f3b255',
          lineWidth: 1,
          lineColor: '#f3b255'
        }
      }, {
        name: 'Мін-Макс',
        data: [],
        type: 'arearange',
        lineWidth: 1,
        linkedTo: ':previous',
        zIndex: 0,
        marker: {
          enabled: false
        },
        color: 'rgba(210, 224, 41, 0.4)',
        fillColor: 'rgba(210, 224, 41, 0.3)',
        fillOpacity: 0.1
      },
        {
          name: 'Межа захисту',
          data: [],
          lineWidth: 2,
          lineColor: 'rgba(224,64,55,1)',
          type: 'arearange',
          zIndex: 1,
          marker: {
            enabled: false,
          },
          fillColor: 'transparent',
          fillOpacity: 0,
          color: 'transparent'
        }
        ]
    };

    this.options3 = {
      time: {
        useUTC: false
      },
      rangeSelector: {
        enabled: false
      },
      chart: {
        type: 'spline',
        zoomType: 'x',
        width:  width
      },
      title: {text: ''},
      xAxis: {
        type: 'datetime',
        minRange: 30000
      },
      yAxis: {
        title: {
          text: 'Ампер'
        }
      },
      tooltip: {
        crosshairs: true,
        shared: true,
        valueSuffix: ' A'
      },
      legend: {
        enabled: true
      },
      plotOptions: {
        series: {
          fillOpacity: 0.1
        }
      },
      series: [{
        name: 'Фаза A',
        data: [],
        zIndex: 1,
        color: '#f3b255',
        marker: {
          enable: true,
          fillColor: '#f3b255',
          lineWidth: 1,
          lineColor: '#f3b255'
        }
      }, {
        name: 'Мін-Макс фаза A',
        data: [],
        type: 'arearange',
        lineWidth: 1,
        linkedTo: ':previous',
        zIndex: 0,
        marker: {
          enabled: false
        },
        color: 'rgba(210, 224, 41, 0.4)',
        fillColor: 'rgba(210, 224, 41, 0.3)',
        fillOpacity: 0.1
      },
        {
          name: 'Фаза B',
          data: [],
          zIndex: 1,
          color: '#31e02e',
          marker: {
            enable: true,
            fillColor: '#31e02e',
            lineWidth: 1,
            lineColor: '#31e02e'
          }
        }, {
          name: 'Мін Макс фаза B',
          data: [],
          type: 'arearange',
          lineWidth: 1,
          linkedTo: ':previous',
          zIndex: 0,
          marker: {
            enabled: false
          },
          color: 'rgba(49,224,46,0.4)',
          fillColor: 'rgba(49,224,46,0.3)',
          fillOpacity: 0.1
        },
        {
          name: 'Фаза C',
          data: [],
          zIndex: 1,
          color: '#f355e5',
          marker: {
            enable: true,
            fillColor: '#f355e5',
            lineWidth: 1,
            lineColor: '#f355e5'
          }
        }, {
          name: 'Мін Макс фаза C',
          data: [],
          type: 'arearange',
          lineWidth: 1,
          linkedTo: ':previous',
          zIndex: 0,
          marker: {
            enabled: false
          },
          color: 'rgba(243,85,229,0.4)',
          fillColor: 'rgba(243,85,229,0.3)',
          fillOpacity: 0.1
        },
        {
          name: 'Межа захисту',
          data: [],
          lineWidth: 2,
          lineColor: 'rgba(224,64,55,1)',
          type: 'arearange',
          zIndex: 1,
          marker: {
            enabled: false,
          },
          fillColor: 'transparent',
          fillOpacity: 0,
          color: 'transparent'
        }
        ]
    };
  }

  getLoading(){
    return this.loading;
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  setLoadingData(loading: boolean) {
    this.loadingData = loading;
  }

  saveInstance(chartInstance) {
    let container = document.getElementsByClassName('relay-stats-container')[0],
      width;

    width = container?(container.clientWidth-48):(window.innerWidth - 48);
    this.chart = chartInstance;

    this.chart['setSize'](width);

    this.addDataToChart();
  }

  saveInstance3(chartInstance) {
    let container = document.getElementsByClassName('relay-stats-container')[0],
      width;

    Highcharts.setOptions({
      global: {
        useUTC: false
      }
    });
    width = container?(container.clientWidth-48):(window.innerWidth - 48);
    this.chart3 = chartInstance;

    this.chart3['setSize'](width);

    this.addDataToChart();
  }

  addDataToChart() {
    if (this.values['line'] && this.values['range'] && this.values['range'].length) {
      this.chart['series'][0].setData(this.values['line'], false, true, false);
      this.chart['series'][1].setData(this.values['range'],false, true, false);
      this.chart['series'][2].setData(this.values['limits'],false, true, false);

      this.chart['redraw']();
      this.loading = false;
    }

    if (this.values['lineA'] && this.values['rangeA'] && this.values['rangeA'].length) {

      this.chart3['series'][0].setData(this.values['lineA'], false, true, false);
      this.chart3['series'][1].setData(this.values['rangeA'],false, true, false);

      this.chart3['series'][2].setData(this.values['lineB'], false, true, false);
      this.chart3['series'][3].setData(this.values['rangeB'],false, true, false);

      this.chart3['series'][4].setData(this.values['lineC'], false, true, false);
      this.chart3['series'][5].setData(this.values['rangeC'],false, true, false);

      this.chart3['series'][6].setData(this.values['limits'], false, true, false);

    this.chart3['redraw']();
    this.loading = false;
    }
    this.loading = false;
  }

  runReport(from: number, to: number): void {

    this.loading = true;
    this.loadingData = true;
    this._relayService.getReadingsByType(this.type, from, to)
      .subscribe(
        (success) => {
          console.log('getReadings', success);
          this.values = {
            line: [],
            range: [],
            rangeMinMax:{
              min: 0,
              max: 0
            },
            lineA: [],
            rangeA: [],
            rangeAMinMax:{
              min: 0,
              max: 0
            },
            lineB: [],
            rangeB: [],
            rangeBMinMax:{
              min: 0,
              max: 0
            },
            lineC: [],
            rangeC: [],
            rangeCMinMax:{
              min: 0,
              max: 0
            },
            limits: [],
            limitMinMax: {
              min: 0,
              max: 0
            },
            voltageKoefs: [],
            currentKoefs: [],
          };

          this.values['voltageKoefs'] = success['voltageKoefs'] || [];
          this.values['currentKoefs'] = success['currentKoefs'] || [];
          if (success['range'].length) {
            this.values['line'] = success['line'];
            this.values['range'] = success['range'];
            this.values['limits'] = success['limits'];
            this.values['rangeMinMax'] = success['rangeMinMax'];
            this.values['limitsMinMax'] = success['limitsMinMax'];

            this.chart['series'][0].setData(this.values['line'], false, true, false);
            this.chart['series'][1].setData(this.values['range'],false, true, false);
            this.chart['series'][2].setData(this.values['limits'],false, true, false);

            this.chart['redraw']();
            this.loading = false;
          }else {
            this.values['lineA'] = success['lineA'];
            this.values['rangeA'] = success['rangeA'];
            this.values['limits'] = success['limits'];

            this.values['limits'] = success['limits'];

            this.values['rangeAMinMax'] = success['rangeAMinMax'];
            this.values['rangeBMinMax'] = success['rangeBMinMax'];
            this.values['rangeCMinMax'] = success['rangeCMinMax'];
            this.values['limitsMinMax'] = success['limitsMinMax'];

            this.chart3['series'][0].setData(this.values['lineA'], false, true, false);
            this.chart3['series'][1].setData(this.values['rangeA'],false, true, false);

            this.values['lineB'] = success['lineB'];
            this.values['rangeB'] = success['rangeB'];

            this.chart3['series'][2].setData(this.values['lineB'], false, true, false);
            this.chart3['series'][3].setData(this.values['rangeB'],false, true, false);

            this.values['lineC'] = success['lineC'];
            this.values['rangeC'] = success['rangeC'];

            this.chart3['series'][4].setData(this.values['lineC'], false, true, false);
            this.chart3['series'][5].setData(this.values['rangeC'],false, true, false);

            this.chart3['series'][6].setData(this.values['limits'], false, true, false);

            this.chart3['redraw']();
            this.loading = false;
          }
          this.loadingData = false;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.loadingData = false;
        }
      );
  }

  onResize(event) {
    let container = event.target,
      width;

    if (!this.chart) return;

    console.log('resize');

    width = container?container.clientWidth:(window.innerWidth - 48);

    this.chart['setSize'](width);
    this.chart['redraw']();

    this.chart3['setSize'](width);
    this.chart3['redraw']();
  }

  onChoosenDate(typeObj: ChoosenDateType) {
    this.runReport(typeObj.from, typeObj.to);

    this.dateString = typeObj.dateString;
    this.dateType = typeObj
  }

  hideSeries(index: number, type: string) {
    const chart = type === 'chart3'? this.chart3: this.chart;
    const isVisible: boolean = chart['series'][index].visible;

    if (isVisible) {
      chart['series'][index].hide();
      chart['series'][index + 1] && chart['series'][index + 1].hide();
    }else {
      chart['series'][index].show();
      chart['series'][index + 1] && chart['series'][index + 1].show();
    }
  }

  toggleCsvMenu(e) {
    this.isCsvDropdownOpen = !this.isCsvDropdownOpen;
    e && e.stopPropagation();
    if (this.isCsvDropdownOpen) {
      window.addEventListener('click', this.outCsvClick)
    }else {
      window.removeEventListener('click', this.outCsvClick)
    }
  }

  outCsvClick(){
    if(this.isCsvDropdownOpen) {
      this.isCsvDropdownOpen = false;
    }

    window.removeEventListener('click', this.outCsvClick)
  }
}
