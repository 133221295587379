import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {NotifyService} from "../../../lib/services/notify.service";
import {FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'mqtt-integration-modal',
  template: `
    <div class="modal">
      <div class="modal-header">
        <div class="modal-title">
          Параметри брокера
        </div>
        <div class="modal-close">
          <button mat-icon-button mat-dialog-close="false" aria-hidden="true" autofocus="false">
            <mat-icon aria-label="false" aria-hidden="true">close</mat-icon>
          </button>
        </div>
      </div>
      <mat-dialog-content>
        <div class="modal-body space-top">
          <div class="row">
            <mat-form-field>
              <input matInput placeholder="Адреса брокера" [formControl]="broker" />
              <mat-error *ngIf="broker.hasError('required')">
                Обов'язкове поле
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field>
              <input matInput placeholder="Порт" [formControl]="port" type="number"/>
              <mat-error *ngIf="port.hasError('required')">
                Обов'язкове поле
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field>
              <input matInput placeholder="Ім'я користувача" [formControl]="user" type="text"/>
              <mat-error *ngIf="user.hasError('required')">
                Обов'язкове поле
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field>
              <input matInput placeholder="Пароль" type="text" [formControl]="pass" />
              <mat-error *ngIf="pass.hasError('required')">
                Обов'язкове поле
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions>
        <div class="modal-bottom">
          <button mat-raised-button color="primary" (click)="save()" cdkFocusInitial="true">Зберегти</button>
        </div>
      </mat-dialog-actions>
    </div>
  `,
  styleUrls: ["../../styles/relay-schedule.scss", "../../styles/relay-settings.scss"]
})
export class MqttIntegrationModal {
  integrationEndpoint: string = '';
  broker = new FormControl('', [
    Validators.required,
  ]);
  port = new FormControl('', [
    Validators.min(0),
    Validators.required,
  ]);
  user = new FormControl('', [
    Validators.required,
  ]);
  pass = new FormControl('', [
    Validators.required,
  ]);

  constructor(
    public dialogRef: MatDialogRef<MqttIntegrationModal>,
    private notifyService: NotifyService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.broker = new FormControl(data.broker, [
      Validators.required,
    ]);
    this.port = new FormControl(data.port, [
      Validators.required,
    ]);
    this.user = new FormControl(data.user, [
      Validators.required,
    ]);
    this.pass = new FormControl(data.pass, [
      Validators.required,
    ]);
  }

  validateMQTTData() {
    const broker = this.broker.value;
    const port = this.port.value;
    const user = this.user.value;
    const pass = this.pass.value;

    if (
      !broker ||
      !user ||
      !pass ||
      !port ||
      isNaN(+port)
    ) {
      return false;
    }

    return true;
  }

  save() {
    if (!this.validateMQTTData()) {
      this.notifyService.showMessage({
        text: 'Заповніть всі поля',
        type: "error"
      })
      return;
    }

    this.dialogRef.close({
      broker: this.broker.value,
      port: +this.port.value,
      user: this.user.value,
      pass: this.pass.value
    });
  }
}
