import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {FormControl} from "@angular/forms";
import * as moment from "moment";
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

@Component({
  selector: 'custom-period-modal',
  templateUrl: '../templates/custom-period-modal.html',
  styleUrls: ["../styles/relay-schedule.scss"],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'uk-UA'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class CustomPeriodModal {
  fromDate = new FormControl(new Date());
  toDate = new FormControl(new Date());
  fromStr: string = moment().format('DD MMM');
  toStr: string =  moment().format('DD MMM');

  constructor(
    public dialogRef: MatDialogRef<CustomPeriodModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private adapter: DateAdapter<any>) {
      this.adapter.setLocale('uk');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  changeDayInput(field: string, event: any){
    let date;

    if (field === 'start'){
      date = event.value
      date = moment(date)

      this.fromStr = date.format('DD MMM, YYYY');
    }else if (field === 'end') {
      date = event.value
      date = moment(date)

      this.toStr = date.format('DD MMM, YYYY');
    }
  }

  save() {
    let from = moment(this.fromStr, 'DD MMM, YYYY')
    let to = moment(this.toStr, 'DD MMM, YYYY')

    let result = {
      fromStr: from.format('DD MMM, YYYY'),
      toStr: to.format('DD MMM, YYYY'),
      from: +from,
      to: +to
    }

    this.dialogRef.close(result);
  }
}
