import {
  Component,
  OnInit,
  OnDestroy,
  Input
} from "@angular/core";
import {FormControl} from '@angular/forms';

import { round } from '../../utils/math';
import * as moment from "moment";
import {DecimalPipe} from '@angular/common';

import {RelayService} from "../services/relay-service";

import {NotifyService} from "../../lib/services/notify.service";


import {NavigationStart, Router, ActivatedRoute, ParamMap} from '@angular/router';
import 'rxjs/add/operator/switchMap';

import {ChartModule} from 'angular2-highcharts';
import {UserService} from "../../user/services/user-service";
import {ChoosenDateType, DateModes, DateOptionsType} from "../relay.types";
import {
  energyDateTypes,
  KCP_301_60,
  KMA_LIKE_RELAY_TYPES,
  PHASE_3_RELAY_TYPES,
  KCP_303_60,
  TOT_104_TH3,
  KMT_101_40,
  TOT_106_TH6
} from '../constants'
import * as Highcharts from 'highcharts';
import {Socket} from "ng-socket-io";
import {convertArrayToCsv, getSerialDigitsFromSerialNumber} from '../services/helper'

const UPDATE_TIME_REGULAR_DATA = 60 * 1000;
const UPDATE_TIME_DAY_DATA = 5 * 60 * 1000;

export interface ReadingMonitoring1Phase {
  timestamp: number;
  date: string;
  tot_negEnergyPA: number;
  tot_negEnergyQA: number;
  tot_negEnergySA: number;
  tot_posEnergyPA: number;
  tot_posEnergyQA: number;
  tot_posEnergySA: number;
  voltage: {
    min: number;
    max: number;
    actual: number;
  };
  current: {
    min: number;
    max: number;
    actual: number;
  };
}

export interface ReadingMonitoring3Phase {
  timestamp: number;
  date: string;
  tot_negEnergyPA: number[];
  tot_negEnergyQA: number[];
  tot_negEnergySA: number[];
  tot_posEnergyPA: number[];
  tot_posEnergyQA: number[];
  tot_posEnergySA: number[];
  voltage: {
    min: number[];
    max: number[];
    actual: number[];
  };
  voltageKoef: number;
  current: {
    min: number[];
    max: number[];
    actual: number[];
  }
  currentKoef: number;
}

@Component({
  selector: "relay-energy",
  templateUrl: "../templates/relay-energy.html",
  styleUrls: ["../styles/relay.scss", "../styles/relay-energy.scss"]
})
export class RelayEnergyCmp implements OnInit, OnDestroy {
  isPublicPage: boolean = false;
  energyDateTypes: DateOptionsType[] = energyDateTypes;
  dateType: ChoosenDateType;
  energySettings: any = {};
  relay: any = {};
  chartReady: boolean = false;

  fromDate = new FormControl(new Date());
  toDate = new FormControl(new Date());

  loading: boolean = true;
  loadingData: boolean = true;
  from: string;
  to: string;
  dateString: string;
  currentDate: Date = new Date();
  chartCategories: any = [];

  type: string = 'energy';
  title: string = 'Energy';
  options: any;
  values: any = [];
  total: any = {};
  chart: any;
  user: any;
  relays: any;
  KMA_LIKE_RELAY_TYPES: string[] = KMA_LIKE_RELAY_TYPES;
  chartType: 'all' | 'perPhase' = 'all';
  chartMode: string = 'hour';
  relayType: '3phase' | '1phase' = '1phase';
  seriesState: any =  {
    'active+': true,
    'active-': true,
    'reactive+': true,
    'reactive-': true
  };
  updateTimeout: any = null;
  updateTime: number = 0;
  updateTimeInterval: any = null;
  socketSubscription: any = null;
  isCsvDropdownOpen: boolean = false;

  constructor(public _relayService: RelayService,
              private _notifyService: NotifyService,
              private _route: ActivatedRoute,
              private _userService: UserService,
              private router: Router,
              private socket: Socket) {

    this.dateString = moment(this.currentDate).format("MMM, YYYY");
    this.chartCategories = [];
    this.currentDate = new Date();
    this.isPublicPage = !!window.location.href.match(/\/public\//);

    if (this.isPublicPage) {
      this.energySettings = {};
    }

    this.outCsvClick = this.outCsvClick.bind(this)
  }

  outCsvClick(){
    if(this.isCsvDropdownOpen) {
      this.isCsvDropdownOpen = false;
    }

    window.removeEventListener('click', this.outCsvClick)
  }

  toggleCsvMenu(e) {
    this.isCsvDropdownOpen = !this.isCsvDropdownOpen;
    e && e.stopPropagation();
    if (this.isCsvDropdownOpen) {
      window.addEventListener('click', this.outCsvClick)
    }else {
      window.removeEventListener('click', this.outCsvClick)
    }
  }

  ngOnInit() {
    this.loading = true;

    this._route.paramMap
        .subscribe((success) => {
          this.loading = true;

          if (this.updateTimeout) {
            clearTimeout(this.updateTimeout);
          }

          let dateParams = this._relayService.getDate();

          this.dateString = dateParams.dateString;
          this.dateType = dateParams;

          let sn = this._route.snapshot.paramMap.get('sn');
          if (!sn) {
            let snArr = window.location.href.match(/relay\/(\w*)/);

            sn = snArr?snArr[1]:'';
          }
          this.relay = this._relayService.getLocal(sn);

          if (this.relay.typeRelay === KCP_301_60 || this.relay.typeRelay === KCP_303_60) {
            this.router.navigate([`/relay/${sn}/voltage`]);
          }else if (this.relay.typeRelay === TOT_104_TH3 || this.relay.typeRelay === TOT_106_TH6) {
            this.router.navigate([`/relay/${sn}/temperature`]);
          }else if (this.relay.typeRelay === KMT_101_40) {
            this.router.navigate([`/relay/${sn}/schedule`]);
          }

          if (PHASE_3_RELAY_TYPES.includes(this.relay.typeRelay)) {
            this.relayType = '3phase';
            this.chartType = 'perPhase';
          }else {
            this.relayType = '1phase';
            this.chartType = 'all';
          }

          this.energySettings = this.isPublicPage? {}: this.relay.energySettings;
          this.getChartOptions();
          this.chartReady = true;

          this.runReport(this.dateType.from, this.dateType.to, 'today');
          this.loading = false;
        });

    let user = this._userService.getLocalUser()

    if (this.isPublicPage) {
      return;
    }

    if (user && user['_id']){
      this.user = user;
      this.relays = user['relays'];
    }else {
      let user = JSON.parse(localStorage.getItem('currentUser'));
      if (user) {
        let userId = user._id;
        this._userService.getUser(userId)
          .subscribe((user) => {
            this.user = user;
            this.relays = user['relays'];
          }, (e) => {
            console.log(e);
          })
      }else {
        this.user = {};
      }

    }
    console.log('user', this.user);
  }

  ngOnDestroy() {
    if (this.updateTimeout) {
      clearTimeout(this.updateTimeout);
    }

    if (this.updateTimeInterval) {
      clearInterval(this.updateTimeInterval);
    }

    this._relayService.saveDate(this.dateType);
  }

  addUpdateTimeoutHour() {
    if (this.updateTimeout) {
      clearTimeout(this.updateTimeout);
    }

    this.updateTimeout = setTimeout(() => {}, UPDATE_TIME_REGULAR_DATA);
  }

  addUpdateTimeoutDay() {
    if (this.updateTimeout) {
      clearTimeout(this.updateTimeout);
    }

    this.updateTimeout = setTimeout(() => {
      const from = this.dateType && this.dateType.from || +moment().startOf('day');
      const to = this.dateType && this.dateType.to || +moment().endOf('day');
      this.runReport(from, to, 'today', true);
    }, UPDATE_TIME_DAY_DATA);
  }

  removeUpdateTimeout() {
    if (this.updateTimeout) {
      clearTimeout(this.updateTimeout);
      this.removeUpdateTime();
    }
  }

  startUpdateTime(time: number) {
    this.updateTime = 0;

    this.updateTimeInterval = setInterval(() => {
      if (this.updateTime < 100) {
        const add = 100 * 100 / time;
        this.updateTime += add;
      }else {
        clearInterval(this.updateTimeInterval);
        this.updateTimeInterval = null;
      }

    }, 100);
  }

  removeUpdateTime() {
    if (this.updateTimeInterval) {
      clearInterval(this.updateTimeInterval);
      this.updateTimeInterval = null;
      this.updateTime = 0;
    }
  }

  getChartOptions(){

    let series;

    if (this.chartType === 'perPhase') {
      series = [
          {
            name: 'Спожита активна е/е фаза А, кВт*г',
            data: [],
            stack: 'active',
            color: '#aacbf9',
            showInLegend: false,
            phase3: true,
            phaseText: 'фаза А',
            phaseLabel: 'спожита активна енергія',
            measure: 'кВт*г'
          },
        {
          name: 'Спожита активна е/е фаза B, кВт*г',
          data: [],
          stack: 'active',
          color: '#80b0f6',
          showInLegend: false,
          phase3: true,
          phaseText: 'фаза B',
          phaseLabel: 'спожита активна енергія',
          measure: 'кВт*г'
        },
        {
          name: 'Спожита активна е/е фаза C, кВт*г',
          data: [],
          stack: 'active',
          color: '#5596f3',
          showInLegend: false,
          phase3: true,
          phaseText: 'фаза C',
          phaseLabel: 'спожита активна енергія',
          measure: 'кВт*г'
        },
          {
            name: 'Згенерована активна е/е фаза А, кВт*г',
            data: [],
            stack: 'active',
            color: '#aaf9b1',
            showInLegend: false,
            phase3: true,
            phaseText: 'фаза А',
            phaseLabel: 'згенерована активна енергія',
            measure: 'кВт*г'
          },
        {
          name: 'Згенерована активна е/е фаза B, кВт*г',
          data: [],
          stack: 'active',
          color: '#80f68a',
          showInLegend: false,
          phase3: true,
          phaseText: 'фаза В',
          phaseLabel: 'згенерована активна енергія',
          measure: 'кВт*г'
        },
        {
          name: 'Згенерована активна е/е фаза C, кВт*г',
          data: [],
          stack: 'active',
          color: '#55f363',
          showInLegend: false,
          phase3: true,
          phaseText: 'фаза С',
          phaseLabel: 'згенерована активна енергія',
          measure: 'кВт*г'
        }];


      if (this.energySettings.reactive) {
        series.push(
          {
            name: 'Спожита реактивна е/е фаза A, кВАр*г',
            data: [],
            stack: 'reactive',
            color: '#f9aaf2',
            showInLegend: false,
            phase3: true,
            phaseText: 'фаза А',
            phaseLabel: 'спожита реактивна енергія',
            measure: 'кВАр*г'
          },
          {
            name: 'Спожита реактивна е/е фаза B, кВАр*г',
            data: [],
            stack: 'reactive',
            color: '#f680eb',
            showInLegend: false,
            phase3: true,
            phaseText: 'фаза В',
            phaseLabel: 'спожита реактивна енергія',
            measure: 'кВАр*г'
          },
          {
            name: 'Спожита реактивна е/е фаза C, кВАр*г',
            data: [],
            stack: 'reactive',
            color: '#f355e5',
            showInLegend: false,
            phase3: true,
            phaseText: 'фаза С',
            phaseLabel: 'спожита реактивна енергія',
            measure: 'кВАр*г'
          },
          {
            name: 'Згенерована реактивна е/е фаза A, кВАр*г',
            data: [],
            stack: 'reactive',
            color: '#f9d8aa',
            showInLegend: false,
            phase3: true,
            phaseText: 'фаза А',
            phaseLabel: 'згенерована реактивна енергія',
            measure: 'кВАр*г'
          },
          {
            name: 'Згенерована реактивна е/е фаза B, кВАр*г',
            data: [],
            stack: 'reactive',
            color: '#f6c580',
            showInLegend: false,
            phase3: true,
            phaseText: 'фаза В',
            phaseLabel: 'згенерована реактивна енергія',
            measure: 'кВАр*г'
          },
          {
            name: 'Згенерована реактивна е/е фаза C, кВАр*г',
            data: [],
            stack: 'reactive',
            color: '#f3b255',
            showInLegend: false,
            phase3: true,
            phaseText: 'фаза С',
            phaseLabel: 'згенерована реактивна енергія',
            measure: 'кВАр*г'
          });
      }

    }else {
      if (this.KMA_LIKE_RELAY_TYPES.includes(this.relay.typeRelay)) {
        series = [
          {
            name: 'Спожита активна е/е, кВт*г',
            data: [],
            stack: 'active',
            color: '#5596f3',
            showInLegend: false
          }
        ]
      } else {
        series = [
          {
            name: 'Спожита активна е/е, кВт*г',
            data: [],
            stack: 'active',
            color: '#5596f3',
            showInLegend: false
          },
          {
            name: 'Згенерована активна е/е, кВт*г',
            data: [],
            stack: 'active',
            color: '#55f363',
            showInLegend: false
          }];
      }

      if (this.energySettings.reactive) {
        series.push(
          {
            name: 'Спожита реактивна е/е, кВАр*г',
            data: [],
            stack: 'reactive',
            color: '#f355e5',
            showInLegend: false
          },
          {
            name: 'Згенерована реактивна е/е, кВАр*г',
            data: [],
            stack: 'reactive',
            color: '#f3b255',
            showInLegend: false
          });
      }
    }

    this.options = {
      chart: {
        type: 'column'
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: this.chartCategories,
        crosshair: true
      },
      yAxis: {
        title: {
          text: 'кВт * г'
        }
      },
      legend: {
        enabled: false
      },
      tooltip: {
        formatter: function () {
          const periodName = isNaN(+this.x)? this.x: this.x + ' година';
          if (this.series.userOptions && this.series.userOptions.phase3) {
            const header = this.series.userOptions.phaseLabel;
            const color = this.series.userOptions.color;
            const pointName = this.series.userOptions.phaseText;
            const measure = this.series.userOptions.measure;

            const text = `<span>${periodName}</span><br/><span>${header}</span><br/>
                    <span style="color:${color}">\u25CF</span><span> ${pointName}: <b>${Math.abs(this.y)}</b> ${measure}</span><br/>
                    <span color:{point.color}">\u25CF</span><span> всього: <b>${Math.abs(this.total)}</b> ${measure}</span>
                `

            return text;
          }else {
            return '<span>' + periodName + '</span><br/><span style="color:point.color">\u25CF</span><span> ' +
              this.series.name + ' - </span><b>' + Math.abs(this.y) + '</b>';
          }


        }
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 2,
          stacking: 'normal'
        }
      },
      series: series
    };
  }

  saveInstance(chartInstance: any, type: string) {
    let container = document.getElementsByClassName('relay-chart-energy')[0],
      width;

    width = container ? (container.clientWidth - 48) : (window.innerWidth - 48);

      this.chart = chartInstance;
      this.chart['setSize'](width);

  }

  setChartDataPerPhase() {
    console.log('get readings run report 2');
    let posPA = [],
      posPB = [],
      posPC = [],
      posQA = [],
      posQB = [],
      posQC = [],
      negPA = [],
      negPB = [],
      negPC = [],
      negQA = [],
      negQB = [],
      negQC = [];

    this.chartCategories = [];
    for (let i = 0; i < this.values.length; i++) {
      let date = this.values[i]._id;

      const year = date.year || new Date().getFullYear();
      const month = date.month > 9? date.month: '0' + date.month;
      if (this.chartMode === 'month') {
        let name = moment(year + '/' + month + '/01', 'YYYY/MM/DD').format('MMM, YYYY');
        this.chartCategories.push(name);
      }else if (this.chartMode === 'hour') {
        const day = date.day > 9? date.day: '0' + date.day;
        const hour = date.hour > 9? date.hour: '0' + date.hour;
        let name = moment(year + '/' + month + '/' + day + ' ' + hour, 'YYYY/MM/DD HH').format('HH');
        this.chartCategories.push(name);
      } else {
        const day = date.day > 9? date.day: '0' + date.day;
        let name = moment(year + '/' + month + '/' + day, 'YYYY/MM/DD').format('DD MMM');
        this.chartCategories.push(name);
      }

      posPA.push(this.values[i].posPPerPhase[0] || 0);
      posPB.push(this.values[i].posPPerPhase[1] || 0);
      posPC.push(this.values[i].posPPerPhase[2] || 0);
      negPA.push(this.values[i].negPPerPhase[0] || 0);
      negPB.push(this.values[i].negPPerPhase[1] || 0);
      negPC.push(this.values[i].negPPerPhase[2] || 0);

      if (this.energySettings.reactive) {
        posQA.push(this.values[i].posQPerPhase[0] || 0);
        posQB.push(this.values[i].posQPerPhase[1] || 0);
        posQC.push(this.values[i].posQPerPhase[2] || 0);

        negQA.push(this.values[i].negQPerPhase[0] || 0);
        negQB.push(this.values[i].negQPerPhase[1] || 0);
        negQC.push(this.values[i].negQPerPhase[2] || 0);
      }
    }

    let j = 0;

    this.chart['series'][j].setData(posPA, false, true, false);
    j++;
    this.chart['series'][j].setData(posPB, false, true, false);
    j++;
    this.chart['series'][j].setData(posPC, false, true, false);
    j++;

    if (this.chart['series'][j]) {
      this.chart['series'][j].setData(negPA, false, true, false);
      j++;
      this.chart['series'][j].setData(negPB, false, true, false);
      j++;
      this.chart['series'][j].setData(negPC, false, true, false);
      j++;
    }

    if (this.energySettings.reactive) {
      this.chart['series'][j].setData(posQA, false, true, false);
      j++;
      this.chart['series'][j].setData(posQB, false, true, false);
      j++;
      this.chart['series'][j].setData(posQC, false, true, false);
      j++;

      this.chart['series'][j].setData(negQA, false, true, false);
      j++;
      this.chart['series'][j].setData(negQB, false, true, false);
      j++;
      this.chart['series'][j].setData(negQC, false, true, false);
    }

  }

  setChartDataAll() {
    console.log('get readings run report 2');
    let posP = [], posQ = [], posS = [];
    let negP = [], negQ = [], negS = [];

    this.chartCategories = [];
    for (let i = 0; i < this.values.length; i++) {
      let date = this.values[i]._id;

      const year = date.year || new Date().getFullYear();
      const month = date.month > 9? date.month: '0' + date.month;
      if (this.chartMode === 'month') {
        let name = moment(year + '/' + month + '/01', 'YYYY/MM/DD').format('MMM, YYYY');
        this.chartCategories.push(name);
      }else if (this.chartMode === 'hour') {
        const day = date.day > 9? date.day: '0' + date.day;
        const hour = date.hour > 9? date.hour: '0' + date.hour;
        let name = moment(year + '/' + month + '/' + day + ' ' + hour, 'YYYY/MM/DD HH').format('HH');
        this.chartCategories.push(name);
      } else {
        const day = date.day > 9? date.day: '0' + date.day;
        let name = moment(year + '/' + month + '/' + day, 'YYYY/MM/DD').format('DD MMM');
        this.chartCategories.push(name);
      }

      posP.push(this.values[i].posP || 0);
      negP.push(this.values[i].negP || 0);

      if (this.energySettings.reactive) {
        posQ.push(this.values[i].posQ || 0);
        negQ.push(this.values[i].negQ || 0);
      }

      if (this.energySettings.full) {
        posS.push(this.values[i].posS);
        negS.push(this.values[i].negS);
      }
    }

    let j = 0;

    this.chart['series'][j].setData(posP, false, true, false);
    j++;

    if (this.chart['series'][j]) {
      this.chart['series'][j].setData(negP, false, true, false);
      j++;
    }

    if (this.energySettings.reactive) {
      this.chart['series'][j].setData(posQ, false, true, false);
      j++;
      this.chart['series'][j].setData(negQ, false, true, false);
    }

  }

  runReport(from: number, to: number, mode: DateModes, skipLoading: boolean = false): void {
    const fromString: string = moment(from).format('YYYY-MM-DD');
    const toString: string = moment(to).format('YYYY-MM-DD');

    if (!skipLoading) {
      this.loadingData = true;
    }else if (skipLoading && this.loadingData) {
      return;
    }

    if (mode === 'today') {
      if (this.updateTimeout) {
        clearTimeout(this.updateTimeout);
        this.updateTimeout = null;
      }

      this.removeUpdateTime();
    }

    this.values = [];
    this.total = {};

    this._relayService.getReadingsEnergy(fromString, toString, mode === 'last hour')
      .subscribe(
        (success) => {
          console.log('get readings run report ', success, !!this.chart);
          if (!this.chart) return;

          this.chartCategories = [];
          this.values = success['data'] || [];

          console.log(1, this.values);
          this.total = success['total'] || {};
          this.chartMode = success['mode'];
          try {
            for (let key in this.total){
              if (typeof this.total[key] !== 'object') {
                this.total[key] = Math.abs(this.total[key])
              }
            }
          }catch(e){
            console.log(e);
          }

          if (this.chartType === 'perPhase') {
            this.setChartDataPerPhase();
          } else {
            this.setChartDataAll();
          }

          this.chart.xAxis[0].setCategories(this.chartCategories);
          this.chart['redraw']();
          this.loadingData = false;

          if (mode === 'today') {
            this.addUpdateTimeoutDay();
            this.startUpdateTime(UPDATE_TIME_DAY_DATA);
          }
        },
        (error) => {
          this.loadingData = false;
          this._notifyService.showMessage({
            message: 'Some errors, try later',
            type: 'error'
          });
        }
      );
  }

  onResize(event) {
    let container = event.target,
      width;

    if (!this.chart) return;

    console.log('resize');

    width = container?container.clientWidth:(window.innerWidth - 48);

    this.chart['setSize'](width);
    this.chart['redraw']();
  }

  subscribeToMonitoringReadings() {
    let subscription = this.socket.fromEvent('regular data from relay')
      .subscribe(
        (data: {relayId: string, reading: ReadingMonitoring1Phase | ReadingMonitoring3Phase}) => {

          if (data.relayId !== this.relay._id) {
            console.log('Id did not match', data['relayId'], this.relay._id)
            return;
          }

          const to = moment(this.dateType.to).format('YYYY-MM-DD');
          const now = moment().format('YYYY-MM-DD');
          const readingDate = moment(data.reading.timestamp).format('YYYY-MM-DD');

          // if date not include today or reading not todays - skip adding this reading to chart
          if (to !== now || readingDate !== now) {
            return;
          }

          if (typeof data.reading.tot_negEnergyPA === "number") {
            this.convertRegularData(data.reading as ReadingMonitoring1Phase);
          }else if (data.reading.tot_negEnergyPA.length){
            this.convertRegularData3Phase(data.reading as ReadingMonitoring3Phase);
          }


        }
      );

    this.socketSubscription = subscription;
  }

  unSubscribeToMonitoringReadings() {
    if (this.socketSubscription) {
      this.socketSubscription.unsubscribe();
    }
  }

  convertRegularData3Phase(reading: ReadingMonitoring3Phase) {
    const readingDate = moment(reading.timestamp).startOf('hour');

    const id = {
      day: readingDate.date(),
      month: readingDate.month()+1,
      year: readingDate.year(),
      hour: readingDate.hour(),
    };

    if ( !this.values || !this.values[0] ) {
      return;
    }

    const defaultReading = {
      _id: id,
      ts: reading.timestamp,
      dateStart: reading.date,
      dateEnd: reading.date,
      voltageKoef: reading['voltageKoef'] || 1,
      currentKoef: reading['currentKoef'] || 1,
      negP: 0,
      negP1A: 0,
      negP2A: 0,
      negP1B: 0,
      negP2B: 0,
      negP1C: 0,
      negP2C: 0,
      negPPerPhase: [0, 0, 0],
      negQ: 0,
      negQ1A: 0,
      negQ2A: 0,
      negQ1B: 0,
      negQ2B: 0,
      negQ1C: 0,
      negQ2C: 0,
      negQPerPhase: [0, 0, 0],
      negS: 0,
      negS1A: 0,
      negS2A: 0,
      negS1B: 0,
      negS2B: 0,
      negS1C: 0,
      negS2C: 0,
      negSPerPhase: [0, 0, 0],
      posP: 0,
      posP1A: 0,
      posP2A: 0,
      posP1B: 0,
      posP2B: 0,
      posP1C: 0,
      posP2C: 0,
      posPPerPhase: [0, 0, 0],
      posQ: 0,
      posQ1A: 0,
      posQ2A: 0,
      posQ1B: 0,
      posQ2B: 0,
      posQ1C: 0,
      posQ2C: 0,
      posQPerPhase: [0, 0, 0],
      posS: 0,
      posS1A: 0,
      posS2A: 0,
      posS1B: 0,
      posS2B: 0,
      posS1C: 0,
      posS2C: 0,
      posSPerPhase: [0, 0, 0],
    };

    const convertedReading = this.values && this.values[0]?
      {
        ...defaultReading,
        ...this.values[0],
      }: defaultReading;

    convertedReading['_id'] = id;
    convertedReading['ts'] = reading.timestamp;

    const energyKoef = convertedReading.currentKoef * convertedReading.voltageKoef;

    const letterForPhase = ['A', 'B', 'C'];

    if (this.values[0]._id.hour !== id.hour) {
      // next hour
      convertedReading.negPPerPhase = [];
      reading.tot_negEnergyPA.forEach((item, i) => {
        convertedReading.negPPerPhase[i] = round(
          (-Math.abs(reading.tot_negEnergyPA[i] * energyKoef) + Math.abs(convertedReading[`negP2${letterForPhase[i]}`])) / 1000, 3
        );
      });
      convertedReading.negP = round(convertedReading.negPPerPhase.reduce((acc, item) => {
        return acc + item;
      }, 0), 3);

      convertedReading.negQPerPhase = [];
      reading.tot_negEnergyQA.forEach((item, i) => {
        convertedReading.negQPerPhase[i] = round(
          (-Math.abs(reading.tot_negEnergyQA[i] * energyKoef) + Math.abs(convertedReading[`negQ2${letterForPhase[i]}`])) / 1000, 3
        );
      });
      convertedReading.negQ = round(convertedReading.negQPerPhase.reduce((acc, item) => {
        return acc + item;
      }, 0), 3);


      convertedReading.posPPerPhase = [];
      reading.tot_posEnergyPA.forEach((item, i) => {
        convertedReading.posPPerPhase[i] = round(
          (Math.abs(reading.tot_posEnergyPA[i] * energyKoef) - Math.abs(convertedReading[`posP2${letterForPhase[i]}`])) / 1000, 3
        );
      });
      convertedReading.posP = round(convertedReading.posPPerPhase.reduce((acc, item) => {
        return acc + item;
      }, 0), 3);

      convertedReading.posQPerPhase = [];
      reading.tot_posEnergyQA.forEach((item, i) => {
        convertedReading.posQPerPhase[i] = round(
          (Math.abs(reading.tot_posEnergyQA[i] * energyKoef) - Math.abs(convertedReading[`posQ2${letterForPhase[i]}`])) / 1000, 3
        );
      });
      convertedReading.posQ = round(convertedReading.posQPerPhase.reduce((acc, item) => {
        return acc + item;
      }, 0), 3);


      letterForPhase.forEach((letter, i) => {
        convertedReading[`negP1${letterForPhase[i]}`] = -Math.abs(reading.tot_negEnergyPA[i]) * energyKoef;
        convertedReading[`negQ1${letterForPhase[i]}`] = -Math.abs(reading.tot_negEnergyQA[i]) * energyKoef;

        convertedReading[`posP1${letterForPhase[i]}`] = Math.abs(reading.tot_posEnergyPA[i]) * energyKoef;
        convertedReading[`posQ1${letterForPhase[i]}`] = Math.abs(reading.tot_posEnergyQA[i]) * energyKoef;
      });
    } else {
      convertedReading.negPPerPhase = [];
      reading.tot_negEnergyPA.forEach((item, i) => {
        convertedReading.negPPerPhase[i] = round(
          (-Math.abs(reading.tot_negEnergyPA[i] * energyKoef) + Math.abs(convertedReading[`negP1${letterForPhase[i]}`])) / 1000, 3
        );
      });
      convertedReading.negP = round(convertedReading.negPPerPhase.reduce((acc, item) => {
        return acc + item;
      }, 0), 3);

      convertedReading.negQPerPhase = [];
      reading.tot_negEnergyQA.forEach((item, i) => {
        convertedReading.negQPerPhase[i] = round(
          (-Math.abs(reading.tot_negEnergyQA[i] * energyKoef) + Math.abs(convertedReading[`negQ1${letterForPhase[i]}`])) / 1000, 3
        );
      });
      convertedReading.negQ = round(convertedReading.negQPerPhase.reduce((acc, item) => {
        return acc + item;
      }, 0), 3);


      convertedReading.posPPerPhase = [];
      reading.tot_posEnergyPA.forEach((item, i) => {
        convertedReading.posPPerPhase[i] = round(
          (Math.abs(reading.tot_posEnergyPA[i] * energyKoef) - Math.abs(convertedReading[`posP1${letterForPhase[i]}`])) / 1000, 3
        );
      });
      convertedReading.posP = round(convertedReading.posPPerPhase.reduce((acc, item) => {
        return acc + item;
      }, 0), 3);

      convertedReading.posQPerPhase = [];
      reading.tot_posEnergyQA.forEach((item, i) => {
        convertedReading.posQPerPhase[i] = round(
          (Math.abs(reading.tot_posEnergyQA[i] * energyKoef) - Math.abs(convertedReading[`posQ1${letterForPhase[i]}`])) / 1000, 3
        );
      });
      convertedReading.posQ = round(convertedReading.posQPerPhase.reduce((acc, item) => {
        return acc + item;
      }, 0), 3);


      letterForPhase.forEach((letter, i) => {
        convertedReading[`negP2${letterForPhase[i]}`] = -Math.abs(reading.tot_negEnergyPA[i] * energyKoef);
        convertedReading[`negQ2${letterForPhase[i]}`] = -Math.abs(reading.tot_negEnergyQA[i] * energyKoef);

        convertedReading[`posP2${letterForPhase[i]}`] = Math.abs(reading.tot_posEnergyPA[i] * energyKoef);
        convertedReading[`posQ2${letterForPhase[i]}`] = Math.abs(reading.tot_posEnergyQA[i] * energyKoef);
      });

    }

    const total = {
      totalNegP: convertedReading.negP,
      totalNegQ: convertedReading.negQ,
      totalNegS: convertedReading.negS,
      totalPosP: convertedReading.posP,
      totalPosQ: convertedReading.posQ,
      totalPosS: convertedReading.posS,
    };

    this.addMonitoringData({data: [convertedReading], total});
  }

  convertRegularData(reading: ReadingMonitoring1Phase) {
    const readingDate = moment(reading.timestamp).startOf('hour');

    const id = {
      day: readingDate.date(),
      month: readingDate.month()+1,
      year: readingDate.year(),
      hour: readingDate.hour(),
    };

    if ( !this.values || !this.values[0] ) {
      return;
    }

    const defaultReading = {
      _id: id,
      ts: reading.timestamp,
      dateStart: reading.date,
      dateEnd: reading.date,
      negP: 0,
      negP1: 0,
      negP2: 0,
      negQ: 0,
      negQ1: 0,
      negQ2: 0,
      negS: 0,
      negS1: 0,
      negS2: 0,
      posP: 0,
      posP1: 0,
      posP2: 0,
      posQ: 0,
      posQ1: 0,
      posQ2: 0,
      posS: 0,
      posS1: 0,
      posS2: 0,
    };
    const convertedReading = this.values && this.values[0]?
      {
        ...defaultReading,
        ...this.values[0]
      }: defaultReading;

    convertedReading['_id'] = id;
    convertedReading['ts'] = reading.timestamp;

    if (this.values[0]._id.hour !== id.hour) {
      // next hour
      convertedReading.negP = round((-Math.abs(reading.tot_negEnergyPA) + Math.abs(convertedReading.negP2)) / 1000, 3);
      convertedReading.negQ = round((-Math.abs(reading.tot_negEnergyQA) + Math.abs(convertedReading.negQ2)) / 1000, 3);
      convertedReading.negS = round((-Math.abs(reading.tot_negEnergySA) + Math.abs(convertedReading.negS2)) / 1000, 3);

      convertedReading.posP = round((Math.abs(reading.tot_posEnergyPA) - Math.abs(convertedReading.posP2)) / 1000, 3);
      convertedReading.posQ = round((Math.abs(reading.tot_posEnergyQA) - Math.abs(convertedReading.posQ2)) / 1000, 3);
      convertedReading.posS = round((Math.abs(reading.tot_posEnergySA) - Math.abs(convertedReading.posS2)) / 1000, 3);


      convertedReading.negP1 = -Math.abs(reading.tot_negEnergyPA);
      convertedReading.negQ1 = -Math.abs(reading.tot_negEnergyQA);
      convertedReading.negS1 = -Math.abs(reading.tot_negEnergySA);

      convertedReading.posP1 = Math.abs(reading.tot_posEnergyPA);
      convertedReading.posQ1 = Math.abs(reading.tot_posEnergyQA);
      convertedReading.posS1 = Math.abs(reading.tot_posEnergySA);
    } else {
      convertedReading.negP2 = -Math.abs(reading.tot_negEnergyPA);
      convertedReading.negQ2 = -Math.abs(reading.tot_negEnergyQA);
      convertedReading.negS2 = -Math.abs(reading.tot_negEnergySA);

      convertedReading.posP2 = Math.abs(reading.tot_posEnergyPA);
      convertedReading.posQ2 = Math.abs(reading.tot_posEnergyQA);
      convertedReading.posS2 = Math.abs(reading.tot_posEnergySA);

      convertedReading.negP = round((-Math.abs(convertedReading.negP2) + Math.abs(convertedReading.negP1)) / 1000, 3);
      convertedReading.negQ = round((-Math.abs(convertedReading.negQ2) + Math.abs(convertedReading.negQ1)) / 1000, 3);
      convertedReading.negS = round((-Math.abs(convertedReading.negS2) + Math.abs(convertedReading.negS1)) / 1000, 3);

      convertedReading.posP = round((Math.abs(convertedReading.posP2) - Math.abs(convertedReading.posP1)) / 1000, 3);
      convertedReading.posQ = round((Math.abs(convertedReading.posQ2) - Math.abs(convertedReading.posQ1)) / 1000, 3);
      convertedReading.posS = round((Math.abs(convertedReading.posS2) - Math.abs(convertedReading.posS1)) / 1000, 3);
    }

    const total = {
      totalNegP: convertedReading.negP,
      totalNegQ: convertedReading.negQ,
      totalNegS: convertedReading.negS,
      totalPosP: convertedReading.posP,
      totalPosQ: convertedReading.posQ,
      totalPosS: convertedReading.posS,
    };

    this.addMonitoringData({data: [convertedReading], total});
  }

  addMonitoringData(newData: {data: object[], total: object}) {
    this.values = newData.data;
    this.total = newData.total;

    this.removeUpdateTime();
    this.startUpdateTime(UPDATE_TIME_REGULAR_DATA);

    if (this.chartType === 'perPhase') {
      this.setChartDataPerPhase();
    } else {
      this.setChartDataAll();
    }

    const category = this.values[0]._id.hour;

    this.chart.xAxis[0].setCategories([category]);
  }

  onChoosenDate(typeObj: ChoosenDateType) {
    console.log('onChoosenDate', typeObj);

    this.runReport(typeObj.from, typeObj.to, typeObj.mode);

    if (typeObj.mode === 'last hour') {
      this.subscribeToMonitoringReadings();
      this.dateString = 'Остання година';
      this.removeUpdateTime();
      this.addUpdateTimeoutHour();
      this.startUpdateTime(UPDATE_TIME_REGULAR_DATA);
    }else {
      this.unSubscribeToMonitoringReadings();
      this.dateString = typeObj.dateString;
      this.removeUpdateTime();
      this.removeUpdateTimeout()
    }

    this.dateType = typeObj
  }

  hideSeries(name: string) {
    const indexByName = this.chartType === 'perPhase'? {
      'active+': 0,
      'active-': 3,
      'reactive+': 6,
      'reactive-': 9
    }: {
      'active+': 0,
      'active-': 1,
      'reactive+': 2,
      'reactive-': 3
    };

    const index: number = indexByName[name] || 0;

    const isVisible: boolean = this.chart.series[index].visible;

    if (isVisible) {
      this.seriesState[name] = false;
      this.chart.series[index].hide();

      if (this.chartType === 'perPhase') {
        this.chart.series[index + 1].hide();
        this.chart.series[index + 2].hide();
      }
    }else {
      this.chart.series[index].show();
      this.seriesState[name] = true;

      if (this.chartType === 'perPhase') {
        this.chart.series[index + 1].show();
        this.chart.series[index + 2].show();
      }
    }


  }

  downloadChartCsv(spreadsheetType: 'excel' | 'google') {
    this.isCsvDropdownOpen = false;
    window.removeEventListener('click', this.outCsvClick);

    if (!this.values || !this.values.length) {
      // no data
      this._notifyService.showMessage({
        message: 'Немає даних',
        type: 'error'
      })
      return;
    }

    try {
      const csvData = convertArrayToCsv(this.getChartDataArray(), spreadsheetType);
      let csvContent = `data:text/csv;charset=utf-8,${csvData}`

      const from = moment(this.dateType.from).format('DDMMYYYY');
      const to = moment(this.dateType.to).format('DDMMYYYY');
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      const dateName = this.chartMode === 'hour'? from: `${from}-${to}`
      link.setAttribute("download", `${getSerialDigitsFromSerialNumber(this.relay.serialNumber)}Energy${dateName}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }catch(e) {
      console.log(e);
      this._notifyService.showMessage({
        message: 'Вибачте, сталася помилка. Спробуйте ще раз.',
        type: 'error'
      })
    }
  }

  // spreadsheetType - google or excel
  getChartDataArray(): (string|number)[][] {
    const result = [];

    const type = this.relay.typeRelay;
    const SN = this.relay.serialNumber;

    const voltageKoef = this.energySettings && this.energySettings.voltageKoef || 1;
    const currentKoef = this.energySettings && this.energySettings.currentKoef || 1;
    const energyKoef = voltageKoef * currentKoef;

    const headerName = [`${type}. s/n: ${SN}`];
    result.push(headerName)
    const koefHeader = `Current coefficient - ${energyKoef}`
    result.push([koefHeader], [], []);

    const from = moment(this.dateType.from).format('DD.MM.YYYY');
    const to = moment(this.dateType.to).format('DD.MM.YYYY');
    const timeHeader = `Energy ${from} - ${to}`;

    result.push([timeHeader])
    const dateFormat = this.chartMode === 'month'? 'MM.YYYY': 'DD.MM.YYYY'

    const header = ['Date'];
    const measureHeader = [dateFormat]

    if (this.chartMode === 'hour') {
      header.push('Time');
      measureHeader.push('hh:mm');
    }

    header.push('Positive active energy', 'Negative active energy');
    measureHeader.push('kW*h', 'kW*h');

    if (this.energySettings.reactive) {
      header.push('Positive reactive energy', 'Negative reactive energy');
      measureHeader.push('kVAr*h', 'kVar*h');
    }

    if (this.energySettings.full) {
      header.push('Positive full energy, kW*h', 'Negative full energy, kW*h');
      measureHeader.push('kW*h', 'kW*h');
    }

    result.push(header, measureHeader)

    for (let i = 0; i < this.values.length; i++) {
      let date = this.values[i]._id;
      const year = date.year || new Date().getFullYear();
      const month = date.month > 9 ? date.month : '0' + date.month;
      const day = date.day? date.day > 9 ? date.day : '0' + date.day: '01';
      const hour = date.hour > 9 ? date.hour : '0' + date.hour;
      const minute = date.minute ? date.minute > 9 ? date.minute : '0' + date.minute : '00';
      const row = []
      row.push(moment(year + '.' + month + '.' + day, 'YYYY.MM.DD').format(dateFormat))

      if (this.chartMode === 'hour') {
        row.push(moment(`${year}/${month}/${day} ${hour}:${minute}`, 'YYYY.MM.DD HH:mm').format('hh:mm'))
      }

      row.push(this.values[i].posP, Math.abs(this.values[i].negP))

      if (this.energySettings.reactive) {
        row.push(this.values[i].posQ, Math.abs(this.values[i].negQ))
      }

      if (this.energySettings.full) {
        row.push(this.values[i].posS, this.values[i].negS)
      }

      result.push(row);
    }

    return result;
  }
}
