import { Component, Input } from '@angular/core';

@Component({
  selector: 'elemeter-loading-spinner',
  templateUrl: "loading-spinner.html",
  styleUrls: ["loading-spinner.css"]
})
export class LoadingSpinnerCmp {
  @Input()  loading: boolean;

  constructor() {
    this.loading = true;
  }

}
