import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'remove-appliance-dialog',
  templateUrl: '../templates/remove-appliance.html',
})
export class RemoveApplianceComponent {
  constructor(
    public dialogRef: MatDialogRef<RemoveApplianceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
