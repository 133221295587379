export interface SyncCounterType {
  show: boolean;
  value: number;
  measurementError?: number;
  offset: number
}

export const getSyncCounterData = (syncCounter: SyncCounterType, tot_posEnergyPA: number, voltageKoef: number, currentKoef: number): SyncCounterType => {
  const offset: number = syncCounter.offset;
  //const measurementError: number = (100 + (syncCounter.measurementError || 0))/100;
  const energyActive: number = tot_posEnergyPA / 1000 + offset;

  const powerKoef: number = (voltageKoef || 1) * (currentKoef || 1);

  const convertedValue: number = Math.round( powerKoef * energyActive * 1000)/1000;
  return {
    ...syncCounter,
    value: convertedValue,
  };
}

export const calculateSyncCounterOffset = (syncCounter: SyncCounterType, tot_posEnergyPA: number, voltageKoef: number, currentKoef: number): number => {
  const powerKoef = (voltageKoef || 1) * (currentKoef || 1);
  const convertedValue = syncCounter.value / powerKoef;

  return convertedValue - tot_posEnergyPA / 1000;
}

export const convertArrayToCsv = (arr: (string|number)[][], spreadsheetType: string): string => {
  const delimeter = spreadsheetType === 'excel'? ';': ',';
  const numberDelimeter = spreadsheetType === 'excel'? ',': '.';
  const result = arr.reduce((acc, row) => {
    const rowRes = row.reduce((rowAcc, el) => {
      const convertedValue = isNaN(+el)? el: el.toString().replace('.', numberDelimeter);

      rowAcc += convertedValue + delimeter;
      return rowAcc;
    }, '');

    acc += rowRes + '\n';

    return acc;
  }, '');

  return result;
}

export const getSerialDigitsFromSerialNumber = (serialNumber: string = "") => {
  return serialNumber.split('_')[0]
}
