import {Component, OnInit, OnDestroy } from "@angular/core";
import {AuthService} from "../../auth/services/auth-service";
import {Router, ActivatedRoute, Params} from '@angular/router';

import { HttpClient } from '@angular/common/http';

import { NotifyService } from '../../lib/services/notify.service';

@Component({
  selector: "user",
  templateUrl: "../templates/addRelayByQR.html",
  styleUrls: ["../styles/addRelayByQR.scss"]
})
export class AddRelayByQRCmp implements OnInit, OnDestroy {
  responseMessage: string;
  serialNumber: string;
  ENDPOINT: string = 'api/users/';
  loading: boolean = true;


  constructor(private _authHttp: HttpClient,
              private _router: Router,
              private _notifyService: NotifyService,
              private activatedRoute: ActivatedRoute
  ) {
    this.responseMessage = '';
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      console.log(params);
      this.serialNumber = params.serialNumber;
      this.addRelay(this.serialNumber);
    });

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  addRelay(serialNumber: string) {
    let userId = JSON.parse(localStorage.getItem('currentUser'))._id;
    let date = new Date();
    let dateString = date.toDateString();

    this._authHttp.post(
      this.ENDPOINT + userId + "/relay",
      {
        relay: {
          serialNumber: serialNumber,
          relayName: 'Relay added by QR code at ' + dateString
        }
      }
    ).subscribe(
      (success) => {
        this.loading = false;
        let relay = success;
        this._notifyService.showMessage(
          {
            text: 'Relay have been added',
            type: 'success'
          }
        );
        this.responseMessage = 'Relay have been added. You will be redirect to dashboard in 5 seconds.';
        setTimeout(()=>{
          this._router.navigate(['/user']);
        }, 5000);


      },
      (error) => {
        this.loading = false;
        console.log('error - ', error);
        this.responseMessage = 'Error - ', error;
      }
    );


  }

}


