import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {NotifyService} from "../../../lib/services/notify.service";

@Component({
  selector: 'webhook-integration-modal',
  template: `
    <div class="modal">
      <div class="modal-header">
        <div class="modal-title">
          Параметри Webhook інтеграції
        </div>
        <div class="modal-close">
          <button mat-icon-button mat-dialog-close="false" aria-hidden="true" autofocus="false">
            <mat-icon aria-label="false" aria-hidden="true">close</mat-icon>
          </button>
        </div>
      </div>
      <mat-dialog-content>
        <div class="modal-body">
          <div class="row">
            <p class="red-text" *ngIf="integrationEndpointError">
              Невалідна адреса. Адреса повинна починатися з протоколу, мати валідну адресу серверу.
              Наприклад, http://website.com/endpoint або https://website-example.com/endpoint.
            </p>

            <mat-form-field>
              <input matInput placeholder="Адреса надсилання данних (http(s)://...)" [(ngModel)]="integrationEndpoint" />
            </mat-form-field>
          </div>

          <div class="row">
            <p>
              Дані будуть надсилатися методом POST. Content-Type: application/json.
            <figure>
              <figcaption>Тіло запросу має вигляд:</figcaption>
              <pre>
                  <code>
                    reading: {{'{'}}
                    tot_negEnergyPA: float,
                      tot_negEnergyQA: float,
                      tot_negEnergySA: float,
                      tot_posEnergyPA: float,
                      tot_posEnergyQA: float,
                      tot_posEnergySA: float,
                      timestamp: integer,
                    {{'}'}}
                   </code>
                </pre>
            </figure>

            </p>
          </div>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions>
        <div class="modal-bottom">
          <button mat-raised-button color="primary" (click)="save()" cdkFocusInitial="true">Зберегти</button>
        </div>
      </mat-dialog-actions>
    </div>
  `,
  styleUrls: ["../../styles/relay-schedule.scss", "../../styles/relay-settings.scss"]
})
export class WebhookIntegrationModal {
  integrationEndpoint: string = '';
  integrationEndpointError: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<WebhookIntegrationModal>,
    private notifyService: NotifyService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.integrationEndpoint = data.integrationEndpoint

  }

  save() {
    const urlRegex = new RegExp(/^https?:\/\/[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/);

    if (!this.integrationEndpoint || !this.integrationEndpoint.match(urlRegex)) {
      this.integrationEndpointError = true;
      return;
    }

    this.integrationEndpointError = false;

    this.dialogRef.close({
      integrationEndpoint: this.integrationEndpoint
    });
  }
}
