import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse} from '@angular/common/http';

import { Observable} from "rxjs/Observable";
import 'rxjs/add/operator/do';
import { Router } from '@angular/router';

import { NotifyService } from '../services/notify.service';
import {HttpHeaders} from "@angular/common/http/src/headers";

const UNAUTHORIZED = 401;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor (private notifyService: NotifyService, private router: Router) {

  }

  uuidv4() {
    // @ts-ignore
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).toString().replace(/[018]/g, c =>
      // @ts-ignore
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  setAnonymousId() {
    const idKey = 'anonymousId'
    const isAnonymousIdSet = document.cookie.match(idKey)

    if (!isAnonymousIdSet) {
      const date = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000);
      const dateString = date.toUTCString();
      const uuid = this.uuidv4()
      const anonymousCookie = `${idKey}=${uuid};path=/;expires=${dateString};`
      document.cookie = anonymousCookie + anonymousCookie
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.setAnonymousId()
    let token = ''
    console.log('interceptor start');
    try {
      token = localStorage.getItem('token') || '';
    }catch(e) {
      console.log(e);
    }

    // Clone the request to add the new header.
    const headers: HttpHeaders = req.headers.set('Authorization', token);
    headers.set('x-anonymousId', 'sdfsdfsdfsdf');
    const authReq = req.clone({headers: headers});

    // Pass on the cloned request instead of the original request.
    return next.handle(authReq)
      .do(
        (event) => {
        if (event instanceof HttpResponse) {
          console.log('event', event);
        }
      },
        (errorRes) => {
          console.log('ERORR IN AUTH INTER', errorRes);
          if (errorRes instanceof HttpErrorResponse) {
            console.log('ERROR RESPONSE', errorRes);

            if (errorRes.status === UNAUTHORIZED){
              this.router.navigate(['/auth/login'], { queryParams: { returnUrl: window.location.href }});
              return;
            }
            let message, otherMessages = {};
            try {
              let error;
              if (errorRes['error'] && errorRes['error']) {
                error = JSON.parse(errorRes['error'])
              }
              message = error.message;
              otherMessages = error.errors || {};
            }catch(e) {
              console.log('ERORR PARSING MESSAGE', errorRes['error']);
            }

            if (typeof otherMessages === 'object') {
              console.log('otherMessages MESSAGE', otherMessages);

              let keys = Object.keys(otherMessages);

              if (typeof otherMessages[keys[0]] === 'string') {
                message = otherMessages[keys[0]];
              }
            }

            if (message) {
              console.log('SHOW MESSAGE', message);
              this.notifyService.showMessage(
                {
                  text: message || 'Server error',
                  type: 'error'
                }
              );
            }



          }
        });
  }
}
