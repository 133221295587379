import {
  Component,
  OnInit,
  OnDestroy
} from "@angular/core";

import {DefenceCardValues, DefenceSettingsType, RelayScriptType} from "../relay.types";
import {copyObj, isDefined} from "../../utils/common";
import {RelayService} from "../services/relay-service";
import {NotifyService} from "../../lib/services/notify.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Socket} from "ng-socket-io";
import {getToken} from '../../utils/authTokens';
import { ComponentCanDeactivate } from '../../lib/services/save-changes.guard';
import { HostListener } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { saveChangesModal } from "../../lib/components/saveChangesModal/save-changes.modal";
import {MatDialog} from "@angular/material";
import {
  TOP_RELAYS,
  TOP_326_T01,
  TOP_323_T5,
  TOP_323_T01,
  KCP_323_60,
  TOP_323_HT5,
  TOP_326_T5,
  TOC_326_T5,
  TOP_326_HT5,
  KCP_303_60,
  TOT_104_TH3,
  RelayNames,
  SensorsNames,
  TOT_106_TH6,
  TOP_324_T5,
  TOP_324_T01,
  TEP_321_T01,
  PHASE_3_RELAY_TYPES,
  convertSensorPercentsToValue,
  convertSensorValueToPercents,
  getSensorIndexByChannel
} from '../constants';

const PROTECT_TYPE: string = 'protect'
const DELAY_TYPE: string = 'delay'
const ACTION_TYPE: string = 'action'
const ORDER_TYPE: string = 'order'

const PROTECT_TYPES: string[] = [
  PROTECT_TYPE,
  DELAY_TYPE,
  ACTION_TYPE
]

const MinVoltage = 80;
const MaxVoltage = 280;
const MaxTop323HT5Voltage = 75;
const MinTop323HT5Voltage = 40;

const VOLTAGE_DEFENCE: DefenceCardValues = {
  name: 'voltage',
  type: PROTECT_TYPE,
  alias: 'Напруга',
  measure: 'Вольт',
  relayAlias: 'V',
  values: [
    {
      name: 'max',
      alias: 'Максимум',
      type: 'number',
      koef: 'voltageKoef',
      value: 242,
      step: 1,
      min: 230,
      max: 280
    },
    {
      name: 'min',
      alias: 'Мінімум',
      type: 'number',
      koef: 'voltageKoef',
      value: 198,
      step: 1,
      min: 80,
      max: 210
    },
    {
      name: 'step',
      alias: 'Крок регулювання',
      type: 'number',
      koef: 'voltageKoef',
      value: 5,
      step: 1,
      min: 0,
      max: 20
    },
    {
      name: 'chanel',
      alias: 'Канал вимірювання',
      value: '1',
      type: 'select',
      options: [
        {label: 'A', value: '1'},
        {label: 'B', value: '2'},
        {label: 'C', value: '4'},
        {label: 'AB', value: '3'},
        {label: 'BC', value: '6'},
        {label: 'AC', value: '5'},
        {label: 'ABC', value: '7'}
      ]
    }
  ]
}

const VOLTAGE_TOP_323_HT5_DEFENCE: DefenceCardValues = {
  name: 'voltage',
  type: PROTECT_TYPE,
  alias: 'Напруга',
  measure: 'Кіловольт',
  relayAlias: 'V',
  values: [
    {
      name: 'max',
      alias: 'Максимум',
      type: 'number',
      koef: 'voltageKoef',
      value: MaxTop323HT5Voltage,
      step: 1,
      min: 0.7 * MaxTop323HT5Voltage,
      max: MaxTop323HT5Voltage,
      divider: 1000
    },
    {
      name: 'min',
      alias: 'Мінімум',
      type: 'number',
      koef: 'voltageKoef',
      value: MinTop323HT5Voltage,
      step: 1,
      min: MinTop323HT5Voltage,
      max: 0.9 * MaxTop323HT5Voltage,
      divider: 1000
    },
    {
      name: 'step',
      alias: 'Крок регулювання',
      type: 'number',
      koef: 'voltageKoef',
      value: 5,
      step: 1,
      min: 0,
      max: 20,
      divider: 1000
    },
    {
      name: 'chanel',
      alias: 'Канал вимірювання',
      value: '1',
      type: 'select',
      options: [
        {label: 'A', value: '1'},
        {label: 'B', value: '2'},
        {label: 'C', value: '4'},
        {label: 'AB', value: '3'},
        {label: 'BC', value: '6'},
        {label: 'AC', value: '5'},
        {label: 'ABC', value: '7'}
      ]
    }
  ]
}

const CURRENT_DEFENCE: DefenceCardValues = {
  name: 'current',
  type: PROTECT_TYPE,
  alias: 'Струм',
  measure: 'мА',
  relayAlias: 'I',
  values: [
    {
      name: 'max',
      alias: 'Максимум',
      type: 'number',
      koef: 'currentKoef',
      value: 63000,
      step: 100,
      min: 100,
      max: 100000,
    },
    {
      name: 'step',
      alias: 'Крок регулювання',
      type: 'number',
      koef: 'currentKoef',
      value: 100,
      step: 10,
      min: 0,
      max: 6300,
    },
    {
      name: 'chanel',
      alias: 'Канал вимірювання',
      value: '1',
      type: 'select',
      options: [
        {label: 'A', value: '1'},
        {label: 'B', value: '2'},
        {label: 'C', value: '4'},
        {label: 'AB', value: '3'},
        {label: 'BC', value: '6'},
        {label: 'AC', value: '5'},
        {label: 'ABC', value: '7'}
      ]
    }
  ]
}

const ACTIVE_POWER_DEFENCE: DefenceCardValues = {
  name: 'power',
  type: PROTECT_TYPE,
  alias: 'Активна потужність',
  measure: 'Вт',
  relayAlias: 'P',
  values: [
    {
      name: 'max',
      alias: 'Максимум',
      type: 'number',
      koef: 'powerKoef',
      value: 23000,
      step: 100,
      min: -23000,
      max: 23000,
    },
    {
      name: 'min',
      alias: 'Мінімум',
      type: 'number',
      koef: 'powerKoef',
      value: -23000,
      step: 100,
      min: -23000,
      max: 23000,
    },
    {
      name: 'step',
      alias: 'Крок регулювання',
      type: 'number',
      koef: 'powerKoef',
      value: 100,
      step: 10,
      min: 0,
      max: 23000,
    },
    {
      name: 'chanel',
      alias: 'Канал вимірювання',
      value: '1',
      type: 'select',
      options: [
        {label: 'A', value: '1'},
        {label: 'B', value: '2'},
        {label: 'C', value: '4'},
        {label: 'AB', value: '3'},
        {label: 'BC', value: '6'},
        {label: 'AC', value: '5'},
        {label: 'ABC', value: '7'}
      ]
    }
  ]
};

const ACTIVE_POWER_ALL_DEFENCE: DefenceCardValues = {
  name: 'power_all',
  type: PROTECT_TYPE,
  alias: 'Всього активна потужність',
  measure: 'Вт',
  relayAlias: 'P',
  values: [
    {
      name: 'max',
      alias: 'Максимум',
      type: 'number',
      koef: 'powerKoef',
      value: 69000,
      step: 100,
      min: -69000,
      max: 69000,
    },
    {
      name: 'min',
      alias: 'Мінімум',
      type: 'number',
      koef: 'powerKoef',
      value: -69000,
      step: 100,
      min: -69000,
      max: 69000,
    },
    {
      name: 'step',
      alias: 'Крок регулювання',
      type: 'number',
      koef: 'powerKoef',
      value: 100,
      step: 10,
      min: 0,
      max: 6900,
    }
  ]
};

const REACTIVE_POWER_DEFENCE: DefenceCardValues = {
  name: 'reactive_power',
  type: PROTECT_TYPE,
  alias: 'Реактивна потужність',
  measure: 'ВАр',
  relayAlias: 'Q',
  values: [
    {
      name: 'max',
      alias: 'Максимум',
      type: 'number',
      koef: 'powerKoef',
      value: 22000,
      step: 100,
      min: -23000,
      max: 23000,
    },
    {
      name: 'min',
      alias: 'Мінімум',
      type: 'number',
      koef: 'powerKoef',
      value: -22000,
      step: 100,
      min: -23000,
      max: 23000,
    },
    {
      name: 'step',
      alias: 'Крок регулювання',
      type: 'number',
      koef: 'powerKoef',
      value: 100,
      step: 10,
      min: 0,
      max: 23000,
    },
    {
      name: 'chanel',
      alias: 'Канал вимірювання',
      value: '1',
      type: 'select',
      options: [
        {label: 'A', value: '1'},
        {label: 'B', value: '2'},
        {label: 'C', value: '4'},
        {label: 'AB', value: '3'},
        {label: 'BC', value: '6'},
        {label: 'AC', value: '5'},
        {label: 'ABC', value: '7'}
      ]
    }
  ]
};

const REACTIVE_POWER_ALL_DEFENCE: DefenceCardValues = {
  name: 'reactive_power_all',
  type: PROTECT_TYPE,
  alias: 'Всього реактивна потужність',
  measure: 'ВАр',
  relayAlias: 'Q',
  values: [
    {
      name: 'max',
      alias: 'Максимум',
      type: 'number',
      koef: 'powerKoef',
      value: 66000,
      step: 100,
      min: -69000,
      max: 69000,
    },
    {
      name: 'min',
      alias: 'Мінімум',
      type: 'number',
      koef: 'powerKoef',
      value: -66000,
      step: 100,
      min: -69000,
      max: 69000,
    },
    {
      name: 'step',
      alias: 'Крок регулювання',
      type: 'number',
      koef: 'powerKoef',
      value: 100,
      step: 10,
      min: 0,
      max: 6900,
    }
  ]
};

const FREQUENCY_DEFENCE: DefenceCardValues = {
  name: 'frequency',
  type: PROTECT_TYPE,
  alias: 'Частота',
  measure: 'Гц',
  relayAlias: 'F',
  chanel: 1,
  values: [
    {
      name: 'max',
      alias: 'Максимум',
      type: 'number',
      value: 50.5,
      step: 0.1,
      min: 50.1,
      max: 65
    },
    {
      name: 'min',
      alias: 'Мінімум',
      type: 'number',
      value: 49.5,
      step: 0.1,
      min: 45,
      max: 49.9
    },
    {
      name: 'step',
      alias: 'Крок регулювання',
      type: 'number',
      value: 0.1,
      step: 0.1,
      min: 0.1,
      max: 1
    }
  ]
};

const TEMPERATURE_DEFENCE = (chanel: number, sensors: SensorsNames = {enable: false, list: []}): DefenceCardValues => {
  let index;
  let alias;
  const { enable, list } = sensors;
  let listIndex = 0;
  switch (chanel) {
    case 4:
      listIndex = 1;
      break;
    case 8:
      listIndex = 2;
      break;
    case 16:
      listIndex = 3;
      break;
    case 32:
      listIndex = 4;
      break;
    case 64:
      listIndex = 5;
      break;
  }

  const isFrameTemp = chanel === 1;

  const tempElem = list[listIndex] || {
    name: '',
    index: 0,
    measure: '',
    zeroValue: 0,
    fullValue: 100,
  };
  const max = tempElem['fullValue'];
  const min = tempElem['zeroValue'];
  const minMultiplier = isFrameTemp ? 0.4 : 0.5
  const maxValue = isFrameTemp ? 100 : 120;
  const minValue = isFrameTemp ? -40 : -50;
  const floor = min - minMultiplier * (max - min);
  const step = Math.round((max - min) / 100);

  if (chanel === 1) {
    index = 0;
    alias = 'Температура корпуса';
  } else if (chanel === 2) {
    index = 1;
    alias = enable ? tempElem.name: `Температура T${index}`;
  }else if (chanel === 4){
    index = 2;
    alias =  enable ? tempElem.name: `Температура T${index}`;
  }else if (chanel === 8){
    index = 3;
    alias =  enable ? tempElem.name: `Температура T${index}`;
  }else if (chanel === 16){
    index = 4;
    alias =  enable ? tempElem.name: `Температура T${index}`;
  }else if (chanel === 32){
    index = 5;
    alias =  enable ? tempElem.name: `Температура T${index}`;
  }else if (chanel === 64){
    index = 6;
    alias =  enable ? tempElem.name: `Температура T${index}`;
  } else {
    throw new Error('Wrong chanel for TEMPERATURE_DEFENCE');
  }

  return {
    name: `temperature${index}`,
    type: PROTECT_TYPE,
    alias: alias,
    measure: enable? tempElem.measure: 'градус Цельсія',
    relayAlias: 'T',
    chanel: chanel,
    values: [
      {
        name: 'max',
        alias: 'Максимум',
        type: 'number',
        value: enable? max * 0.75: 75,
        step: enable? step: 1,
        min: enable? floor: minValue,
        max: enable? max: maxValue,
      },
      {
        name: 'min',
        alias: 'Мінімум',
        type: 'number',
        value: enable? min: 0,
        step: enable? step: 1,
        min: enable? floor: minValue,
        max: enable? max: maxValue,
      },
      {
        name: 'step',
        alias: 'Крок регулювання',
        type: 'number',
        value: 1,
        step: 1,
        min: 0,
        max: 10
      }
    ]
  }
}

const LEVEL_DEFENCE = (chanel: number, sensors: SensorsNames = {enable: false, list: []}, levelSettings = {}): DefenceCardValues => {
  let index;
  let alias;
  const { enable, list } = sensors;
  const enableLevel = levelSettings['showLevel'];
  const listIndex = chanel === 8? 2: chanel === 4? 1: 0;
  const tempElem = list[listIndex] || {
    name: '',
    index: 0,
    measure: 'м',
    zeroValue: 0,
    fullValue: 100,
  };

  const max = levelSettings['showLevel'] && levelSettings['fullS20maValue'] !== undefined?
    levelSettings['fullS20maValue']:
    tempElem['fullValue'];
  const min = levelSettings['showLevel'] && levelSettings['zeroS20maValue'] !== undefined?
    levelSettings['zeroS20maValue']:
    tempElem['zeroValue'];
  const floor = min;
  const step = (max - min) >= 100? 1: 0.1;

  if (chanel === 2) {
    index = 1;
    alias = enable ? tempElem.name: `Рівень ${index}`;
  }else if (chanel === 4){
    index = 2;
    alias =  enable ? tempElem.name: `Рівень ${index}`;
  }else if (chanel === 8){
    index = 3;
    alias =  enable ? tempElem.name: `Рівень ${index}`;
  }else {
    throw new Error('Wrong chanel for LEVEL_DEFENCE');
  }

  return {
    name: `level${index}`,
    type: PROTECT_TYPE,
    alias: alias,
    measure: 'м',
    relayAlias: 'T',
    chanel: chanel,
    values: [
      {
        name: 'max',
        alias: 'Максимум',
        type: 'number',
        value: enableLevel? max: 75,
        step: enableLevel? step: 1,
        min: enableLevel? floor: 0,
        max: enableLevel? max: 100,
      },
      {
        name: 'min',
        alias: 'Мінімум',
        type: 'number',
        value: enableLevel? min: 0,
        step: enableLevel? step: 1,
        min: enableLevel? floor: 0,
        max: enableLevel? max: 100,
      },
      {
        name: 'step',
        alias: 'Крок регулювання',
        type: 'number',
        value: step,
        step: step,
        min: 0,
        max: enableLevel && max >= 100? 10: 1,
      }
    ]
  }
}

const DIF_CURRENT_DEFENCE: DefenceCardValues = {
  name: 'i_dif',
  type: PROTECT_TYPE,
  alias: 'Диференційний струм',
  measure: 'мА',
  relayAlias: 'I_dif',
  values: [
    {
      name: 'max',
      alias: 'Максимум',
      value: '100',
      type: 'select',
      koef: 'currentKoef',
      options: [
        {label: '30 мА', value: '30'},
        {label: '60 мА', value: '60'},
        {label: '100 мА', value: '100'},
        {label: '300 мА', value: '300'},
        {label: '600 мА', value: '600'}
      ]
    },
    {
      name: 'chanel',
      alias: 'Канал вимірювання',
      value: '1',
      type: 'select',
      options: [
        {label: 'A', value: '1'},
        {label: 'B', value: '2'},
        {label: 'C', value: '4'},
        {label: 'AB', value: '3'},
        {label: 'BC', value: '6'},
        {label: 'AC', value: '5'},
        {label: 'ABC', value: '7'}
      ]
    }
  ]
}

const DELAY_DEFENCE: DefenceCardValues =  {
  name: 'delay',
  type: DELAY_TYPE,
  alias: 'Затримка',
  measure: 'с',
  relayAlias: '',
  values: [
    {
      name: 'auto_turn',
      alias: 'Автоматичне ввімкнення',
      type: 'checkbox',
      value: 1,
      options: [
        {label: 'Так', value: 1},
        {label: 'Ні', value: 0}
      ]
    },
    {
      name: 'delay_on',
      alias: 'Затримка увімкнення, с',
      type: 'number',
      value: 10,
      min: 0,
      max: 1800,
      step: 1,
    },
    {
      name: 'delay_off',
      alias: 'Затримка вимкнення, с',
      type: 'number',
      value: 10,
      min: 0,
      max: 1800,
      step: 1,
    }
  ]
}

const ORDER_PHASE_DEFENCE: DefenceCardValues = {
  name: 'order_phase',
  type: ORDER_TYPE,
  alias: 'Порядок фаз',
  measure: '',
  relayAlias: '',
  values: [
    {
      name: 'seq',
      alias: 'Напрям фаз',
      type: 'select',
      value: '1',
      options: [
        {label: 'пряма послідовність', value: '1'},
        {label: 'зворотня послідовність', value: '-1'},
      ]
    },
  ]
}

let ACTION_DEFENCE = (relayNames: RelayNames): DefenceCardValues => {
  const { enable, list } = relayNames;
  const names = Object.values(list);

  return {
    name: 'action',
    type: ACTION_TYPE,
    alias: 'Виконати дію',
    measure: '',
    relayAlias: '',
    values: [
      {
        name: 'relay',
        alias: enable? 'Реле': 'Номер реле',
        type: 'select',
        value: '0',
        options: [
          {label: enable && names[0]? names[0].name: '1', value: '0'},
          {label: enable && names[1]? names[1].name: '2', value: '1'},
          {label: enable && names[2]? names[2].name: '3', value: '2'},
          {label: enable && names[3]? names[3].name: '4', value: '3'},
          {label: enable && names[4]? names[4].name: '5', value: '4'},
          {label: enable && names[5]? names[5].name: '6', value: '5'}
        ]
      },
      {
        name: 'smooth',
        alias: 'Тип дії',
        type: 'checkbox',
        tooltip: 'Вимкнути - вимкнення реле при перевищенні значень захисту, шім - широтно імпульсна модуляція',
        value: 0,
        options: [
          {label: 'ШІМ', value: 1},
          {label: 'Вимкнути', value: 0}
        ]
      }
    ]
  }
}

let ACTION_DEFENCE_TOR_326 = (relayNames: RelayNames): DefenceCardValues => {
  const { enable, list } = relayNames;
  const names = Object.values(list);

  return {
    name: 'action',
    type: ACTION_TYPE,
    alias: 'Виконати дію',
    measure: '',
    relayAlias: '',
    values: [
      {
        name: 'relay',
        alias: enable? 'Реле': 'Номер реле',
        type: 'select',
        value: '0',
        options: [
          {label: enable && names[0]? names[0].name: '1', value: '0'},
          {label: enable && names[1]? names[1].name: '2', value: '1'},
          {label: enable && names[2]? names[2].name: '3', value: '2'},
          {label: enable && names[3]? names[3].name: '4', value: '3'},
          {label: enable && names[4]? names[4].name: '5', value: '4'},
          {label: enable && names[5]? names[5].name: '6', value: '5'}
        ]
      },
      {
        name: 'smooth',
        alias: 'Тип дії',
        type: 'checkbox',
        tooltip: 'Вимкнути - вимкнення реле при перевищенні значень захисту, шім - широтно імпульсна модуляція',
        value: 0,
        options: [
          {label: 'ШІМ', value: 1},
          {label: 'Вимкнути', value: 0}
        ]
      }
    ]
  }
}


const ACTION_DEFENCE_TOP_323 = (relayNames: RelayNames): DefenceCardValues => {
  const { enable, list } = relayNames;
  const names = Object.values(list);

  return {
    name: 'action',
    type: ACTION_TYPE,
    alias: 'Виконати дію',
    measure: '',
    relayAlias: '',
    values: [
      {
        name: 'relay',
        alias: enable? 'Реле': 'Номер реле',
        type: 'select',
        value: '0',
        options: [
          {label: enable && names[0]? names[0].name: '1', value: '0'},
          {label: enable && names[1]? names[1].name: '2', value: '1'},
          {label: enable && names[2]? names[2].name: '3', value: '2'},
        ]
      }
    ]
  }
};

const ACTION_DEFENCE_TOT_104_TH3 = (relayNames: RelayNames): DefenceCardValues => {
  const {enable, list} = relayNames;
  const names = Object.values(list);

  return {
    name: 'action',
    type: ACTION_TYPE,
    alias: 'Виконати дію',
    measure: '',
    relayAlias: '',
    values: [
      {
        name: 'relay',
        alias: enable? 'Реле': 'Номер реле',
        type: 'select',
        value: '0',
        options: [
          {label: enable && names[0]? names[0].name: '1', value: '0'},
          {label: enable && names[1]? names[1].name: '2', value: '1'},
          {label: enable && names[2]? names[2].name: '3', value: '2'},
          {label: enable && names[3]? names[3].name: '4', value: '3'},
        ]
      }
    ]
  }
};

let ACTION_DEFENCE_TEP_321_T01 = (relayNames: RelayNames): DefenceCardValues => {
  const { enable, list } = relayNames;
  const names = Object.values(list);

  return {
    name: 'action',
    type: ACTION_TYPE,
    alias: 'Виконати дію',
    measure: '',
    relayAlias: '',
    values: [
      {
        name: 'relay',
        alias: enable? 'Реле': 'Номер реле',
        type: 'select',
        value: '0',
        options: [
          {label: '1', value: '0'},
        ]
      },
      {
        name: 'smooth',
        alias: 'Тип дії',
        type: 'checkbox',
        tooltip: 'Вимкнути - вимкнення реле при перевищенні значень захисту, шім - широтно імпульсна модуляція',
        value: 0,
        options: [
          {label: 'ШІМ', value: 1},
          {label: 'Вимкнути', value: 0}
        ]
      }
    ]
  }
}

const POWER_ALL_CHANELS = 71;

const DEFENCE_TYPES: {[name: string]: DefenceCardValues} = {
  voltage: VOLTAGE_DEFENCE,
  current: CURRENT_DEFENCE,
  power: ACTIVE_POWER_DEFENCE,
  power_all: ACTIVE_POWER_ALL_DEFENCE,
  reactive_power: REACTIVE_POWER_DEFENCE,
  reactive_power_all: REACTIVE_POWER_ALL_DEFENCE,
  frequency: FREQUENCY_DEFENCE,
  temperature0: TEMPERATURE_DEFENCE(1),
  temperature1: TEMPERATURE_DEFENCE(2),
  temperature2: TEMPERATURE_DEFENCE(4),
  temperature3: TEMPERATURE_DEFENCE(8),
  temperature4: TEMPERATURE_DEFENCE(16),
  temperature5: TEMPERATURE_DEFENCE(32),
  temperature6: TEMPERATURE_DEFENCE(64),
  level1: LEVEL_DEFENCE(2),
  level2: LEVEL_DEFENCE(4),
  level3: LEVEL_DEFENCE(8),
  i_dif: DIF_CURRENT_DEFENCE,
  order_phase: ORDER_PHASE_DEFENCE,
  delay: DELAY_DEFENCE,
  action: ACTION_DEFENCE({enable: false, list: []})
}

interface Scenario {
  name: string
  cards: DefenceCardValues[]
}

interface EnergyKoefs {
  currentKoef: number;
  voltageKoef: number;
  powerKoef: number;
  voltageKoefU1?: number;
  voltageKoefU2?: number;
  currentKoefI1?: number;
  currentKoefI2?: number;
  voltageMax?: number;
  voltageMin?: number;
  voltageExist?: boolean;
  currentMax?: number;
  currentMin?: number;
  currentExist?: boolean;
  powerMax?: number;
  powerMin?: number;
  powerExist?: boolean;
}

@Component({
  selector: "relay-defences",
  templateUrl: "../templates/relay-defences.html",
  styleUrls: ["../styles/relay-defences.scss"]
})

export class RelayDefencesComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
  relaySN: string;
  relayId: string;
  loading: boolean;
  formChanged: boolean = false;
  typeRelay: string;
  scenarios: Scenario[];
  energySettings: any = {};
  koefs: EnergyKoefs = {
    voltageKoef: 1,
    currentKoef: 1,
    powerKoef: 1
  };
  levelSettings: any = {};
  DEFENCE_TYPES: {[name: string]: DefenceCardValues} = {...DEFENCE_TYPES}
  cardTypes: DefenceCardValues[] = Object.values(this.DEFENCE_TYPES);
  subscriptions: any[] = [];
  relayNames: RelayNames;
  sensors: SensorsNames;
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.formChanged
  }

  constructor(
    private _relayService: RelayService,
    private route: ActivatedRoute,
    private router: Router,
    private _socket: Socket,
    private _notifyService: NotifyService,
    private _dialog: MatDialog
  ) {
    let sn = this.route.snapshot.paramMap.get('sn');

    if (!sn) {
      let snArr = window.location['href'].match(/relay\/(\w*)/);

      sn = snArr?snArr[1]:'';
    }

    this.relaySN = sn;
  }

  ngOnInit() {
    this.loading = true;

    const confirmDefencesEvent = this._socket.fromEvent('server:defences:confirmed')
      .subscribe(
        (data) => {
          console.log('server:defences:confirmed', data);

          if (data['error'] === 3) {
            this._notifyService.showMessage({
              text: 'Некоректні дані. Налаштування не збережено.',
              type: 'error'
            });
            return;
          }else if (data['error'] || !data['ok']) {
            this._notifyService.showMessage({
              text: 'Налаштування не збережено.',
              type: 'error'
            });
            return;
          }

          this.formChanged = false;

          if (data['defences']) {
            const defences = data['defences'] && data['defences'].length ? data['defences'] : [];

            const scenarios: Scenario[] = defences.map((d, i) => {
              const cards = this.convertRelayScript(d);
              return {
                name: `Сценарій ${i+1}`,
                cards: cards
              }
            });

            this.scenarios = scenarios;
          }

          this._notifyService.showMessage({
            text: 'Дані змінено у пристрої.',
            type: 'success'
          })

        });

    this.subscriptions.push(confirmDefencesEvent);

    this.route.paramMap
      .subscribe((success) => {
        let sn = this.route.snapshot.paramMap.get('sn');

        if (!sn) {
          let snArr = window.location['href'].match(/relay\/(\w*)/);

          sn = snArr?snArr[1]:'';
        }

        this.relaySN = sn;
        this._relayService.get(this.relaySN)
          .subscribe(
            (success) => {
              console.log('get relay', success);
              if (success && success.relay) {
                this.relayId = success.relay._id;

                if (!success.relay.energySettings) {
                  this._notifyService.showMessage({
                    text: 'Error loading relay',
                    type: 'error'
                  })
                }else {
                  this.energySettings = success.relay.energySettings;
                  this.typeRelay = success.relay.typeRelay;
                  this.levelSettings = success.relay.levelSettings;

                  this.koefs = {
                    voltageKoef: success.relay.energySettings['voltageKoef'] || 1,
                    voltageKoefU1: success.relay.energySettings['voltageKoefU1'] || 230,
                    voltageKoefU2: success.relay.energySettings['voltageKoefU2'] || 230,
                    currentKoef: success.relay.energySettings['currentKoef'] || 1,
                    currentKoefI1: success.relay.energySettings['currentKoefI1'] || 60,
                    currentKoefI2: success.relay.energySettings['currentKoefI2'] || 60,
                    powerKoef: 1,
                  };

                  this.koefs.powerKoef = this.koefs.voltageKoef * this.koefs.currentKoef;

                  this.koefs.powerMax = this.koefs.voltageKoefU1 * this.koefs.currentKoefI1;
                }

                if (!TOP_RELAYS.includes(success.relay.typeRelay) && success.relay.typeRelay !== TEP_321_T01) {
                  this.router.navigate([`/relay/${this.relaySN}/energy`]);
                }

                this.DEFENCE_TYPES = {...DEFENCE_TYPES};

                const relayNames = success.relay['relayNames'] as RelayNames;
                this.relayNames = relayNames;
                const sensors = success.relay['sensors'] as SensorsNames;
                this.sensors = sensors;

                this.DEFENCE_TYPES.temperature1 = TEMPERATURE_DEFENCE(2, sensors);
                this.DEFENCE_TYPES.temperature2 = TEMPERATURE_DEFENCE(4, sensors);
                this.DEFENCE_TYPES.temperature3 = TEMPERATURE_DEFENCE(8, sensors);

                if (!PHASE_3_RELAY_TYPES.includes(success.relay.typeRelay)) {
                  delete this.DEFENCE_TYPES.order;
                  this.cardTypes = Object.values(this.DEFENCE_TYPES);
                }

                if (success.relay.typeRelay !== TOT_106_TH6) {
                  delete this.DEFENCE_TYPES.temperature4;
                  delete this.DEFENCE_TYPES.temperature5;
                  delete this.DEFENCE_TYPES.temperature6;
                }
                delete this.DEFENCE_TYPES.level1;
                delete this.DEFENCE_TYPES.level2;
                delete this.DEFENCE_TYPES.level3;
                this.DEFENCE_TYPES.voltage = VOLTAGE_DEFENCE;
                this.DEFENCE_TYPES.action = ACTION_DEFENCE(relayNames);

                if (success.relay.typeRelay === TOP_323_HT5 || success.relay.typeRelay === TOP_326_HT5) {
                  ACTION_DEFENCE = ACTION_DEFENCE_TOP_323;
                  this.DEFENCE_TYPES.action = ACTION_DEFENCE(relayNames);
                  this.DEFENCE_TYPES.voltage = VOLTAGE_TOP_323_HT5_DEFENCE;
                  delete this.DEFENCE_TYPES.i_dif
                  this.cardTypes = Object.values(this.DEFENCE_TYPES);
                }

                if (success.relay.typeRelay === TOP_323_T01 || success.relay.typeRelay === TOP_323_T5) {
                  ACTION_DEFENCE = ACTION_DEFENCE_TOP_323;
                  this.DEFENCE_TYPES.action = ACTION_DEFENCE(relayNames);
                  delete this.DEFENCE_TYPES.i_dif
                  this.cardTypes = Object.values(this.DEFENCE_TYPES);
                }

                if (success.relay.typeRelay === TEP_321_T01) {
                  ACTION_DEFENCE = ACTION_DEFENCE_TEP_321_T01;
                  this.DEFENCE_TYPES.action = ACTION_DEFENCE(relayNames);
                  delete this.DEFENCE_TYPES.i_dif
                  delete this.DEFENCE_TYPES.temperature1;
                  delete this.DEFENCE_TYPES.temperature2;
                  delete this.DEFENCE_TYPES.temperature3;
                  this.cardTypes = Object.values(this.DEFENCE_TYPES);
                }

                if (success.relay.typeRelay === TOP_326_T01 || success.relay.typeRelay === TOP_326_T5) {
                  ACTION_DEFENCE = ACTION_DEFENCE_TOR_326;
                  this.DEFENCE_TYPES.action = ACTION_DEFENCE(relayNames);
                  this.DEFENCE_TYPES.i_dif = DIF_CURRENT_DEFENCE;
                  this.cardTypes = Object.values(this.DEFENCE_TYPES);
                }

                if (success.relay.typeRelay === KCP_323_60) {
                  ACTION_DEFENCE = ACTION_DEFENCE_TOP_323;
                  this.DEFENCE_TYPES.action = ACTION_DEFENCE(relayNames);
                  delete this.DEFENCE_TYPES.i_dif;
                  delete this.DEFENCE_TYPES.temperature1;
                  delete this.DEFENCE_TYPES.temperature2;
                  delete this.DEFENCE_TYPES.temperature3;
                  this.cardTypes = Object.values(this.DEFENCE_TYPES);
                }

                if (success.relay.typeRelay === TOC_326_T5) {
                  ACTION_DEFENCE = ACTION_DEFENCE_TOR_326;
                  this.DEFENCE_TYPES.action = ACTION_DEFENCE(relayNames);
                  this.DEFENCE_TYPES.level1 = LEVEL_DEFENCE(2, sensors, this.levelSettings);
                  this.DEFENCE_TYPES.level2 = LEVEL_DEFENCE(4, sensors, this.levelSettings);
                  this.DEFENCE_TYPES.level3 = LEVEL_DEFENCE(8, sensors, this.levelSettings);

                  delete this.DEFENCE_TYPES.i_dif;
                  delete this.DEFENCE_TYPES.temperature1;
                  delete this.DEFENCE_TYPES.temperature2;
                  delete this.DEFENCE_TYPES.temperature3;
                  this.cardTypes = Object.values(this.DEFENCE_TYPES);
                }

                if (success.relay.typeRelay === KCP_303_60) {
                  ACTION_DEFENCE = ACTION_DEFENCE_TOP_323;
                  this.DEFENCE_TYPES.action = ACTION_DEFENCE(relayNames);
                  delete this.DEFENCE_TYPES.i_dif;
                  delete this.DEFENCE_TYPES.temperature1;
                  delete this.DEFENCE_TYPES.temperature2;
                  delete this.DEFENCE_TYPES.temperature3;

                  delete this.DEFENCE_TYPES.current;
                  delete this.DEFENCE_TYPES.power;
                  delete this.DEFENCE_TYPES.power_all;
                  delete this.DEFENCE_TYPES.reactive_power;
                  delete this.DEFENCE_TYPES.reactive_power_all;

                  this.cardTypes = Object.values(this.DEFENCE_TYPES);
                }

                if (success.relay.typeRelay === TOP_324_T01 || success.relay.typeRelay === TOP_324_T5) {
                  ACTION_DEFENCE = ACTION_DEFENCE_TOT_104_TH3;
                  this.DEFENCE_TYPES.action = ACTION_DEFENCE(relayNames);
                  delete this.DEFENCE_TYPES.i_dif
                  this.cardTypes = Object.values(this.DEFENCE_TYPES);
                }

                if (success.relay.typeRelay === TOT_104_TH3) {
                  ACTION_DEFENCE = ACTION_DEFENCE_TOT_104_TH3;
                  this.DEFENCE_TYPES.action = ACTION_DEFENCE(relayNames);
                  delete this.DEFENCE_TYPES.i_dif;

                  delete this.DEFENCE_TYPES.current;
                  delete this.DEFENCE_TYPES.frequency;
                  delete this.DEFENCE_TYPES.voltage;
                  delete this.DEFENCE_TYPES.power;
                  delete this.DEFENCE_TYPES.power_all;
                  delete this.DEFENCE_TYPES.reactive_power;
                  delete this.DEFENCE_TYPES.reactive_power_all;

                  this.cardTypes = Object.values(this.DEFENCE_TYPES);
                }


                if (success.relay.typeRelay === TOT_106_TH6) {
                  ACTION_DEFENCE = ACTION_DEFENCE_TOR_326;
                  this.DEFENCE_TYPES.action = ACTION_DEFENCE(relayNames);

                  this.DEFENCE_TYPES.temperature4 = TEMPERATURE_DEFENCE(16, sensors);
                  this.DEFENCE_TYPES.temperature5 = TEMPERATURE_DEFENCE(32, sensors);
                  this.DEFENCE_TYPES.temperature6 = TEMPERATURE_DEFENCE(64, sensors);
                  delete this.DEFENCE_TYPES.i_dif;

                  delete this.DEFENCE_TYPES.current;
                  delete this.DEFENCE_TYPES.frequency;
                  delete this.DEFENCE_TYPES.voltage;
                  delete this.DEFENCE_TYPES.power;
                  delete this.DEFENCE_TYPES.power_all;
                  delete this.DEFENCE_TYPES.reactive_power;
                  delete this.DEFENCE_TYPES.reactive_power_all;

                  this.cardTypes = Object.values(this.DEFENCE_TYPES);
                }
              }

              this._relayService.getDefences(this.relaySN)
                .subscribe(
                  (success) => {
                    console.log('success', success);

                    const defences = success['scenarios'];
                    const convertedDefences = [];

                    for (let i = 0; i < defences.length; i++) {
                      const cards = this.convertRelayScript(defences[i].data);
                      convertedDefences.push({name: defences[i].name, cards: cards});
                    }

                    this.scenarios = copyObj(convertedDefences);
                    this.loading = false;
                  },
                  (error) => {
                    console.log('error', error);
                    this.loading = false;
                  });
            });
      });

  }

  ngOnDestroy() {
    this.subscriptions.forEach((item) => {
      item.unsubscribe();
    });
  }

  async askSaveChanges() {
    if (!this.formChanged) {
      return true
    }

    let dialogRef = this._dialog.open(saveChangesModal, {
      width: '375px',
      data: {
        saveChanges: false
      }
    });

    return dialogRef.afterClosed().subscribe(async (result) => {

      if (!result || result.saveChanges !== true) {
        return true;
      }

      await this.saveScenarios();
      return true;
    })
  }

  onChangeCard(event: {index: number, values: DefenceSettingsType[], name: string}) {
    this.formChanged = true;
    const { index, values, name} = event;
    this.scenarios[index].cards.find((item, i) => {
      if(item.name === name) {
        this.scenarios[index].cards[i].values = values
        return true
      }else {
        return false
      }
    })
  }

  checkDefence(defence: DefenceCardValues, scenarioIndex: number): boolean {
    if (!this.scenarios || !this.scenarios[scenarioIndex] || !this.scenarios[scenarioIndex].cards) {
      return false;
    }

    const existCard = !!this.scenarios[scenarioIndex].cards.find((item: DefenceCardValues) => {
      return item.name === defence.name
    });

    const existTemperatureCard = !!this.scenarios[scenarioIndex].cards.find((item: DefenceCardValues) => {
      return (item.name.includes('temperature') || item.name.includes('level')) &&
        (defence.name.includes('level') || defence.name.includes('temperature'));
    });

    const activeCardNames = [ACTIVE_POWER_DEFENCE.name, ACTIVE_POWER_ALL_DEFENCE.name];
    const powerActiveExistCard = !!this.scenarios[scenarioIndex].cards.find((item: DefenceCardValues) => {
      return activeCardNames.includes(item.name) && activeCardNames.includes(defence.name);
    });

    const reactiveCardNames = [REACTIVE_POWER_DEFENCE.name, REACTIVE_POWER_ALL_DEFENCE.name];
    const powerReactiveExistCard = !!this.scenarios[scenarioIndex].cards.find((item: DefenceCardValues) => {
      return reactiveCardNames.includes(item.name) && reactiveCardNames.includes(defence.name);
    });

    return existCard || existTemperatureCard || powerReactiveExistCard || powerActiveExistCard;
  }

  addScenario() {
    this.formChanged = true;
    if (this.scenarios) {
      this.scenarios.push({
        name: `Сценарій ${this.scenarios.length + 1}`,
        cards: [
          copyObj(this.DEFENCE_TYPES['delay']),
          copyObj(this.DEFENCE_TYPES['action']),
        ]
      });
    }else {
      this.scenarios = [{
        name: `Сценарій 1`,
        cards: [
          copyObj(this.DEFENCE_TYPES['delay']),
          copyObj(this.DEFENCE_TYPES['action']),
        ]
      }];
    }

  }

  addCard(cardName: string, index: number) {
    this.formChanged = true;

    let foundCard: DefenceCardValues = this.DEFENCE_TYPES[cardName];
    let length = this.scenarios[index].cards.length;

    if (!foundCard) return

    const card = copyObj(foundCard);
    if (card.name === 'voltage'){
      if (this.typeRelay === TOP_323_HT5 || this.typeRelay === TOP_326_HT5) {
        const divider = 1000;
        card.values[0].value = Math.round(100 * MaxTop323HT5Voltage * this.koefs.voltageKoef / divider) / 100;
        card.values[0].min = Math.round(100 * 0.7 * MaxTop323HT5Voltage * this.koefs.voltageKoef / divider) / 100;
        card.values[0].max = Math.round(100 * MaxTop323HT5Voltage * this.koefs.voltageKoef / divider) / 100;
        card.values[0].step = card.values[0].max > 50? 1: 0.1;

        card.values[1].value = Math.round(100 * MinTop323HT5Voltage * this.koefs.voltageKoef / divider) / 100;
        card.values[1].min = Math.round(100 * MinTop323HT5Voltage * this.koefs.voltageKoef / divider) / 100;
        card.values[1].max = Math.round(100 * 0.9 * MaxTop323HT5Voltage * this.koefs.voltageKoef / divider) / 100;
        card.values[1].step = card.values[1].max > 50? 1: 0.1;

        card.values[2].value = Math.round(100 * (card.values[0].max / 20)) / 100;
        card.values[2].min = 0;
        card.values[2].max = Math.round(100 * (card.values[0].max / 10)) / 100;
        card.values[2].step = card.values[2].max > 5? 0.1: 0.01;
      }else {
        card.values[0].value = 1.2 * this.koefs.voltageKoefU1;
        card.values[0].min = 0.7 * this.koefs.voltageKoefU1;
        card.values[0].max = MaxVoltage * this.koefs.voltageKoef;
        card.values[0].step = this.defineStep(card.values[0].max);

        card.values[1].value = 0.7 * this.koefs.voltageKoefU1;
        card.values[1].min = MinVoltage * this.koefs.voltageKoef;
        card.values[1].max = 0.9 * this.koefs.voltageKoefU1;
        card.values[1].step = this.defineStep(card.values[1].max);

        card.values[2].value = this.koefs.voltageKoefU1 / 20;
        card.values[2].min = 0;
        card.values[2].max = this.koefs.voltageKoefU1 / 10;
        card.values[2].step = this.defineStep(card.values[2].max);
      }

    }else if (card.name === 'current'){
      card.values[0].value = (this.koefs.currentKoefI1 * 1000);
      card.values[0].min = (this.koefs.currentKoefI1 * 1000) / 1000;
      card.values[0].max = (this.koefs.currentKoefI1 * 1000);
      card.values[0].step = this.defineStep(card.values[0].max);

      card.values[1].value = (this.koefs.currentKoefI1 * 1000) / 1000;
      card.values[1].min = 0;
      card.values[1].max = (this.koefs.currentKoefI1 * 1000) / 10;
      card.values[1].step = this.defineStep(card.values[1].max);
    }else if (card.name === 'power'){
      card.values[0].value = this.koefs.powerMax;
      card.values[0].min = -this.koefs.powerMax;
      card.values[0].max = this.koefs.powerMax;
      card.values[0].step = this.defineStep(card.values[0].max);

      card.values[1].value = -this.koefs.powerMax;
      card.values[1].min = -this.koefs.powerMax;
      card.values[1].max = this.koefs.powerMax;
      card.values[1].step = this.defineStep(card.values[1].max);

      card.values[2].value = this.koefs.powerMax / 2000;
      card.values[2].min = 0;
      card.values[2].max = Math.round(card.values[0].max / 10);
      card.values[2].step = this.defineStep(card.values[2].max);
    }else if (card.name === 'power_all'){
      card.values[0].value = 3 * this.koefs.powerMax;
      card.values[0].min = -3 * this.koefs.powerMax;
      card.values[0].max = 3 * this.koefs.powerMax;
      card.values[0].step = this.defineStep(card.values[0].max);

      card.values[1].value = -3 * this.koefs.powerMax;
      card.values[1].min = -3 * this.koefs.powerMax;
      card.values[1].max = 3 * this.koefs.powerMax;
      card.values[1].step = this.defineStep(card.values[1].max);

      card.values[2].value = 3 * this.koefs.powerMax / 2000;
      card.values[2].min = 0;
      card.values[2].max = Math.round(card.values[0].max / 10);
      card.values[2].step = this.defineStep(card.values[2].max);
    }else if (card.name === 'reactive_power'){
      card.values[0].value = this.koefs.powerMax;
      card.values[0].min = -this.koefs.powerMax;
      card.values[0].max = this.koefs.powerMax;
      card.values[0].step = this.defineStep(card.values[0].max);

      card.values[1].value = -this.koefs.powerMax;
      card.values[1].min = -this.koefs.powerMax;
      card.values[1].max = this.koefs.powerMax;
      card.values[1].step = this.defineStep(card.values[1].max);

      card.values[2].value = this.koefs.powerMax / 2000;
      card.values[2].min = 0;
      card.values[2].max = Math.round(card.values[0].max / 10);
      card.values[2].step = this.defineStep(card.values[2].max);
    }else if (card.name === 'reactive_power_all'){
      card.values[0].value = 3 * this.koefs.powerMax;
      card.values[0].min = -3 * this.koefs.powerMax;
      card.values[0].max = 3 * this.koefs.powerMax;
      card.values[0].step = this.defineStep(card.values[0].max);

      card.values[1].value = -3 * this.koefs.powerMax;
      card.values[1].min = -3 * this.koefs.powerMax;
      card.values[1].max = 3 * this.koefs.powerMax;
      card.values[1].step = this.defineStep(card.values[1].max);

      card.values[2].value = 3 * this.koefs.powerMax / 2000;
      card.values[2].min = 0;
      card.values[2].max = Math.round(card.values[0].max / 10);
      card.values[2].step = this.defineStep(card.values[2].max);
    }

    this.scenarios[index].cards.splice(length - 2, 0, card)
  }

  removeCard(event: {index: number, cardName: string}) {
    this.formChanged = true;
    const { index, cardName} = event;
    this.scenarios[index].cards = this.scenarios[index].cards.filter((card) => {
      return card.name !== cardName || card.type !== PROTECT_TYPE
    })
  }

  removeScenario(index: number) {
    this.formChanged = true;
    this.scenarios.splice(index, 1)
  }

  async saveScenarios() {
    const scenarios = copyObj(this.scenarios);
    const convertedScenarios = [];
    let error = false;

    for (let i = 0; i < scenarios.length; i++) {
      const validCard = this.checkDefenceCardValues(scenarios[i].cards);

      if (!validCard) {
        this._notifyService.showMessage({
          message: 'В сценаріі повинно бути як мініму 1 картка захисту',
          type: 'error'
        })
        error = true;
        break;
      }

      const scenarioData = this.convertDefenceCardValues(scenarios[i].cards);
      convertedScenarios.push({
        name: scenarios[i].name,
        data: scenarioData
      });
    }

    if (error) {
      return;
    }

    this.formChanged = true;

    return new Promise((res, rej) => {
      this._socket.emit('user:defences:save', {
          relayId: this.relayId,
          defences: convertedScenarios,
          token: getToken()
        },
        (data) => {
          console.log('user:defences:save callback', data);
          if (data.success) {
            this._notifyService.showMessage({
              message: 'Чекаємо підтвердження від пристрою',
              type: 'success'
            })
            res(true);
          }else {
            this._notifyService.showMessage({
              message: 'Some errors, try later',
              type: 'error'
            })
            rej(true);
          }
        })
    })

  }

  checkDefenceCardValues(data: DefenceCardValues[]): boolean {
    let existProtect = false;
    let existDelay = false;
    let existAction = false;

    for (const card of data) {
      if (card.type === PROTECT_TYPE) {
        existProtect = true;
      }

      if (card.type === DELAY_TYPE) {
        existDelay = true;
      }

      if (card.type === ACTION_TYPE) {
        existAction = true;
      }
    }

    return existProtect && existDelay && existAction;
  }

  convertDefenceCardValues(data: DefenceCardValues[]): RelayScriptType {
    const res: any = {};

    for (const card of data) {
      const values: DefenceSettingsType[] = card.values;
      if (card.name === 'delay' || card.name === 'action' || card.name === 'order_phase') {
        for (const defence of values) {
          res[defence.name] = +defence.value
        }
        continue;
      }

      res[card.relayAlias] = card.values.map(item => {
          if ((this.typeRelay === TOP_323_HT5 || this.typeRelay === TOP_326_HT5) && item.divider) {
            item.value = +item.value * item.divider;
          }

          if (item.koef === 'voltageKoef') {
            return Math.round(+item.value * 1000 / (this.energySettings.voltageKoef || 1)) / 1000;
          }else if (item.koef === 'currentKoef') {
            return Math.round(+item.value * 1000 / (this.energySettings.currentKoef || 1) ) / 1000;
          }else if (item.koef === 'powerKoef') {
            const koef = (this.energySettings.currentKoef || 1) * (this.energySettings.voltageKoef || 1);

            return Math.round(+item.value * 1000 / koef ) / 1000;
          }else {
            return +item.value
          }
      });

      if (card.name === ACTIVE_POWER_ALL_DEFENCE.name || card.name === REACTIVE_POWER_ALL_DEFENCE.name) {
        res[card.relayAlias][3] = POWER_ALL_CHANELS;
      }

      if (card.name.match(/^level/)) {
        const levelSettings = this.levelSettings || {};
        let { zeroS20maValue = 0, fullS20maValue = 100 } = levelSettings;

        res[card.relayAlias][0] = (res[card.relayAlias][0] - zeroS20maValue) * 100/ (fullS20maValue -zeroS20maValue);
        res[card.relayAlias][0] = Math.round(res[card.relayAlias][0] * 100) / 100;
        res[card.relayAlias][1] = (res[card.relayAlias][1] - zeroS20maValue) * 100 / (fullS20maValue -zeroS20maValue);
        res[card.relayAlias][1] = Math.round(res[card.relayAlias][1] * 100) / 100;
        res[card.relayAlias][2] = (res[card.relayAlias][2] / (fullS20maValue - zeroS20maValue)) * 100;
        res[card.relayAlias][2] = Math.round(res[card.relayAlias][2] * 100) / 100;
      }

      if (card.relayAlias === 'T' && card.alias !== 'Температура корпуса' && card.chanel !== 1) {
        const listIndex = getSensorIndexByChannel(card.chanel)
        const enable = this.sensors.enable;
        const {zeroValue, fullValue} = this.sensors.list[listIndex] || {zeroValue: 0, fullValue: 100};

        if (enable) {
          res[card.relayAlias][0] = convertSensorValueToPercents({zeroValue, fullValue, value: res[card.relayAlias][0]});
          res[card.relayAlias][1] = convertSensorValueToPercents({zeroValue, fullValue, value: res[card.relayAlias][1]});
          res[card.relayAlias][2] = (res[card.relayAlias][2] / (fullValue - zeroValue)) * 100;
          res[card.relayAlias][2] = Math.round(res[card.relayAlias][2] * 100) / 100;
        }
      }

      // this is for temperature and frequency
      if (card.chanel) {
        res[card.relayAlias].push(card.chanel);
      }
    }

    return res;
  }

  defineStep(max: number): number {
    let newStep = 1;

    if (max <= 10) {
      newStep = 0.1;
    }else if (max > 10 && max <= 500) {
      newStep = 1;
    }else if (max > 500 && max <= 1999) {
      newStep = 10;
    }else if (max > 999) {
      newStep = 100;
    }

    return newStep;
  }

  convertRelayScript(script: RelayScriptType): DefenceCardValues[] {
    const res: DefenceCardValues[] = [];

    const card: DefenceCardValues = copyObj(this.DEFENCE_TYPES.action);
    card.values[0].value = typeof script.relay === 'number'? script.relay.toString(): '0';
    if (card.values[1]) {
      card.values[1].value = script.smooth? script.smooth.toString(): '0';
    }
    res.unshift(card);

    const cardDelay: DefenceCardValues = copyObj(this.DEFENCE_TYPES.delay);
    cardDelay.values[0].value = script.auto_turn !== undefined? script.auto_turn.toString(): '0';
    cardDelay.values[1].value = script.delay_on;
    cardDelay.values[2].value = script.delay_off;
    res.unshift(cardDelay);

    if (script.V && script.V.length){
      const card: DefenceCardValues = copyObj(this.DEFENCE_TYPES.voltage);

      const voltageKoef = this.energySettings.voltageKoef || 1;

      if (this.typeRelay === TOP_323_HT5 || this.typeRelay === TOP_326_HT5) {
        const divider = 1000;
        card.values[0].value = Math.round(100 * script.V[0] * voltageKoef / divider) / 100;
        card.values[0].min = 0.7 * MaxTop323HT5Voltage * voltageKoef / divider;
        card.values[0].max = MaxTop323HT5Voltage * voltageKoef / divider;
        card.values[0].step = card.values[0].max > 50? 1: 0.1;

        card.values[1].value = Math.round(100 * script.V[1] * voltageKoef / divider) / 100;
        card.values[1].min = MinTop323HT5Voltage * voltageKoef / divider;
        card.values[1].max = 0.9 * MaxTop323HT5Voltage * voltageKoef / divider;
        card.values[1].step = card.values[1].max > 50? 1: 0.1;

        card.values[2].value = Math.round(100 * script.V[2] * voltageKoef / divider) / 100;
        card.values[2].min = 0;
        card.values[2].max = card.values[0].max / 10;
        card.values[2].step = card.values[2].max > 5? 0.1: 0.01;

        card.values[3].value = script.V[3].toString();
      }else {
        card.values[0].value = script.V[0] * voltageKoef;
        card.values[0].min = 0.7 * this.koefs.voltageKoefU1;
        card.values[0].max = MaxVoltage * this.koefs.voltageKoef;
        card.values[0].step = this.defineStep(card.values[0].max);

        card.values[1].value = script.V[1] * voltageKoef;
        card.values[1].min = MinVoltage * this.koefs.voltageKoef;
        card.values[1].max = 0.9 * this.koefs.voltageKoefU1;
        card.values[1].step = this.defineStep(card.values[1].max);

        card.values[2].value = script.V[2] * voltageKoef;
        card.values[2].min = 0;
        card.values[2].max = this.koefs.voltageKoefU1 / 10;
        card.values[2].step =this.defineStep(card.values[2].max);

        card.values[3].value = script.V[3].toString();
      }

      res.unshift(card);
    }

    if (script.I && script.I.length){
      const card: DefenceCardValues = copyObj(this.DEFENCE_TYPES.current);
      const currentKoef = this.energySettings.currentKoef || 1;

      card.values[0].value = script.I[0] * currentKoef;
      card.values[0].min = (this.koefs.currentKoefI1 * 1000) / 1000;
      card.values[0].max = (this.koefs.currentKoefI1 * 1000);
      card.values[0].step = this.defineStep(card.values[0].max)

      card.values[1].value = script.I[1] * currentKoef;
      card.values[1].min = 0;
      card.values[1].max = (this.koefs.currentKoefI1 * 100);
      card.values[1].step = this.defineStep(card.values[1].max);

      card.values[2].value = script.I[2].toString();
      res.unshift(card);
    }

    if (script.P && script.P.length){
      const isAllPower = script.P[3].toString() === POWER_ALL_CHANELS.toString();

      const card: DefenceCardValues = isAllPower?
        copyObj(ACTIVE_POWER_ALL_DEFENCE):
        copyObj(ACTIVE_POWER_DEFENCE);

      const currentKoef = this.energySettings.currentKoef || 1;
      const voltageKoef = this.energySettings.voltageKoef || 1;
      const powerKoef = voltageKoef * currentKoef;
      const powerMax = isAllPower? 3 * this.koefs.powerMax: this.koefs.powerMax;

      card.values[0].value = script.P[0] * powerKoef;
      card.values[0].min = -powerMax;
      card.values[0].max = powerMax;
      card.values[0].step = this.defineStep(card.values[0].max);

      card.values[1].value = script.P[1] * powerKoef;
      card.values[1].min = -powerMax;
      card.values[1].max = powerMax;
      card.values[1].step = this.defineStep(card.values[1].max);

      card.values[2].value = script.P[2] * powerKoef;
      card.values[2].min = 0;
      card.values[2].max = Math.round(card.values[0].max / 10);
      card.values[2].step = this.defineStep(card.values[2].max);

      if (!isAllPower) {
        card.values[3].value = script.P[3].toString();
      }

      res.unshift(card);
    }

    if (script.Q && script.Q.length){
      const isAllPower = script.Q[3].toString() === POWER_ALL_CHANELS.toString();

      const card: DefenceCardValues = isAllPower?
        copyObj(REACTIVE_POWER_ALL_DEFENCE):
        copyObj(REACTIVE_POWER_DEFENCE);

      const currentKoef = this.energySettings.currentKoef || 1;
      const voltageKoef = this.energySettings.voltageKoef || 1;
      const powerKoef = voltageKoef * currentKoef;
      const powerMax = isAllPower? 3 * this.koefs.powerMax: this.koefs.powerMax;

      card.values[0].value = script.Q[0] * powerKoef;
      card.values[0].min = -powerMax;
      card.values[0].max = powerMax;
      card.values[0].step = this.defineStep(card.values[0].max);

      card.values[1].value = script.Q[1] * powerKoef;
      card.values[1].min = -powerMax;
      card.values[1].max = powerMax;
      card.values[1].step = this.defineStep(card.values[1].max);

      card.values[2].value = script.Q[2] * powerKoef;
      card.values[2].min = 0;
      card.values[2].max = Math.round(card.values[0].max / 10);
      card.values[2].step = this.defineStep(card.values[2].max);

      if (!isAllPower) {
        card.values[3].value = script.Q[3].toString();
      }

      res.unshift(card);
    }

    if (script.F && script.F.length){
      const card: DefenceCardValues = copyObj(FREQUENCY_DEFENCE);
      card.values[0].value = script.F[0];
      card.values[1].value = script.F[1];
      card.values[2].value = script.F[2];
      res.unshift(card);
    }

    if (script.T && script.T.length){
      if (this.typeRelay === TOC_326_T5) {
        try {
          const levelSettings = this.levelSettings || {};
          let { zeroS20maValue = 0, fullS20maValue = 100 } = levelSettings;

          const card: DefenceCardValues = copyObj(LEVEL_DEFENCE(script.T[3], this.sensors, this.levelSettings));
          card.values[0].value = zeroS20maValue + (fullS20maValue - zeroS20maValue) * script.T[0] / 100;
          card.values[1].value = zeroS20maValue + (fullS20maValue - zeroS20maValue) * script.T[1] / 100;
          card.values[2].value = (fullS20maValue - zeroS20maValue) * script.T[2] / 100;
          res.unshift(card);
        }catch(e){
          console.error(e);
        }
      }else {
        try {
          const listIndex = getSensorIndexByChannel(script.T[3])
          const enable = this.sensors.enable;
          const {zeroValue, fullValue} = this.sensors.list[listIndex] || {zeroValue: 0, fullValue: 100};
          const card: DefenceCardValues = copyObj(TEMPERATURE_DEFENCE(script.T[3], this.sensors));
          card.values[0].value = enable?
            convertSensorPercentsToValue({zeroValue, fullValue, value: script.T[0] }):
            script.T[0];
          card.values[1].value = enable?
            convertSensorPercentsToValue({zeroValue, fullValue, value: script.T[1] }):
            script.T[1];
          card.values[2].value = enable? (fullValue - zeroValue) * script.T[2] / 100: script.T[2];
          res.unshift(card);
        }catch(e){
          console.error(e);
        }
      }
    }

    if (script.I_dif && script.I_dif.length && this.typeRelay !== TOP_323_T01 && this.typeRelay !== TOP_323_T5
      && this.typeRelay !== TOP_323_HT5 && this.typeRelay !== TOP_326_HT5 && this.typeRelay !== TOP_324_T5 && this.typeRelay !== TOP_324_T01){
      const card: DefenceCardValues = copyObj(DIF_CURRENT_DEFENCE);
      card.values[0].value = (Math.round(script.I_dif[0] * (this.energySettings.currentKoef || 1) )).toString();
      card.values[1].value = script.I_dif[1].toString();
      res.unshift(card);
    }

    return res;
  }


}
