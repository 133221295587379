import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Validators, FormControl } from "@angular/forms";

import {AuthService} from "../services/auth-service";
import {NotifyService} from "../../lib/services/notify.service";

@Component({
  selector: "signup-cmp",
  templateUrl: "../templates/signup.html",
  styleUrls: ["../styles/login.scss", "../styles/signup.scss"]
})

export class SignupCmp {
  title: string = "Sign Up";
  username: string;
  password: string;
  id?: string;
  model: any = {};
  loading = false;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  usernameFormControl = new FormControl('', [
    Validators.required
  ]);
  passwordFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('([\\w-!@#$^&*]{6,20})')
  ]);


  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _notifyService: NotifyService
  ) {

  }

  signup() {
    this.loading = true;
    console.log('signup - ', this.model.username, this.model.password, this.model.email);
    this._authService.signup(this.model.username, this.model.password, this.model.email)
      .subscribe(
        data => {
          console.log(1);
          this.model.username = "";
          this.model.email = "";
          this.model.password = "";
          this._router.navigate(['/auth/login']);
        },
        error => {
          console.log('signup error - ', error);
          this.loading = false;
          let message = typeof error.error.message === 'string'?error.error.message:null;
          let otherMessages = error.error && error.error.errors;
          if (typeof otherMessages === 'object') {
            console.log('otherMessages MESSAGE', otherMessages);

            let keys = Object.keys(otherMessages);

            if (typeof otherMessages[keys[0]] === 'string') {
              message = otherMessages[keys[0]];
            }
          }

          this._notifyService.showMessage({
            text: message || 'Incorrect password or email',
            type: "error"
          });


        });
  }
}
