import * as moment from "moment";
import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input
} from "@angular/core";
import {ChoosenDateType, DateOptionsType} from "../relay.types";
import {MatDialog} from "@angular/material";
import {CustomPeriodModal} from './custom-period.component'
import {FormControl} from "@angular/forms";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from "@angular/material-moment-adapter";


@Component({
  selector: "relay-date-chooser",
  templateUrl: "../templates/relay-date-chooser.html",
  styleUrls: ["../styles/relay-date-chooser.scss"],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'uk-UA'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})

export class RelayDateChooserComponent implements OnInit {
  @Output() choosenDate = new EventEmitter<ChoosenDateType>();
  @Input() types: DateOptionsType[];
  @Input() dayCustomMode: boolean;
  isMenuOpen: boolean = false;
  dayChoose = new FormControl(new Date());

  constructor(
    private _dialog: MatDialog
  ) {
    this.outMenuClick = this.outMenuClick.bind(this)
  }

  ngOnInit() {
  }

  outMenuClick(){
    if(this.isMenuOpen) {
      this.isMenuOpen = false;
    }

    window.removeEventListener('click', this.outMenuClick)
  }

  toggleMenu(e) {
    this.isMenuOpen = !this.isMenuOpen;
    e && e.stopPropagation();
    if (this.isMenuOpen) {
      window.addEventListener('click', this.outMenuClick)
    }else {
      window.removeEventListener('click', this.outMenuClick)
    }
  }

  chooseOption(type: DateOptionsType) {
    const from = +type.from;
    const to = +type.to;
    const dayInMs = 24 * 60 * 60 * 1000;
    let dateString = moment(+type.from).format('DD MMM, YYYY') + ' - ' + moment(+type.to).format('DD MMM, YYYY');
    if ((to - from) < 1.1 * dayInMs) {
      dateString = moment(+type.from).format('DD MMM, YYYY');
    }

    console.log('chooseOption', type, dateString);
    this.choosenDate.emit({
      from,
      to,
      dateString,
      mode: type.value
    });
    this.isMenuOpen = !this.isMenuOpen;

    window.removeEventListener('click', this.outMenuClick)
  }

  openUserChooser() {
    window.removeEventListener('click', this.outMenuClick)
    let dialogRef = this._dialog.open(CustomPeriodModal, {
      width: '250px'
    });

    let self = this;

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;

      if (!result.fromStr || !result.toStr) return;

      const from = +result.from;
      const to = +result.to;
      const dayInMs = 24 * 60 * 60 * 1000;
      let dateString = `${result.fromStr} - ${result.toStr}`;
      if ((to - from) < 1.1 * dayInMs) {
        dateString = `${result.fromStr}`;
      }

      self.choosenDate.emit({
        from,
        to,
        dateString,
        mode: 'custom'
      });

      self.isMenuOpen = false;
      window.removeEventListener('click', self.outMenuClick)
    })
  }

  changeDayInput(event: any){
    console.log('changeDayInput', event);
    let date;

    date = event.value;
    date = moment(date);

    const from = +date.startOf('day');
    const to = +date.endOf('day');
    const dayInMs = 24 * 60 * 60 * 1000;
    let dateString = date.format('DD MMM, YYYY') + ' - ' + date.format('DD MMM, YYYY');
    if ((to - from) < 1.1 * dayInMs) {
      dateString = date.format('DD MMM, YYYY');
    }

    this.choosenDate.emit({
      from,
      to,
      dateString,
      mode: 'custom'
    });

    this.isMenuOpen = false;
    window.removeEventListener('click', this.outMenuClick)
  }

  chooseDatePicker(datePicker) {
    window.removeEventListener('click', this.outMenuClick)

    datePicker.open()
  }
}
