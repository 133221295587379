import { Injectable } from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute} from '@angular/router';
import {NotifyService} from "./notify.service";
import {RelayService} from "../../relay/services/relay-service";
import { Observable } from 'rxjs/Observable';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private router: Router, private notifyService: NotifyService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      let token = localStorage.getItem('token')
      let expireAt = localStorage.getItem('tokenExpireAt')
      let isExpire = true

      if (expireAt) {
        expireAt = JSON.parse(expireAt);
        isExpire = +new Date() > +expireAt;
      }
      if (token && !isExpire) {
        // logged in so return true
        return true;
      }

      if (isExpire) {
        localStorage.setItem('token', '');
        localStorage.setItem('tokenExpireAt', '');
      }else {
        this.notifyService.showMessage({
          text: 'Недостатьно прав. Спробуйте з іншим обліковим записом',
          type: "error"
        });
      }

      // not logged in so redirect to login page with the return url
      this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url }});
      return false;
    }catch(e){
      console.log(e);
      this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url }});
      return false;
    }

  }
}

@Injectable()
export class AuthToRelayGuardService implements CanActivate {

  constructor(private router: Router, private notifyService: NotifyService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      let token = localStorage.getItem('token');
      let expireAt = localStorage.getItem('tokenExpireAt');
      let user = JSON.parse(localStorage.getItem('currentUser') || '{}');
      let isExpire = true;
      let SN = route.params && route.params.sn

      if (!SN){
        let match = state.url.match(/relay\/([\w\d]+)/);
        SN = match && match[1];
      }

      if (expireAt) {
        expireAt = JSON.parse(expireAt);
        isExpire = +new Date() > +expireAt;
      }

      if (!token || isExpire || !user) {
        // logged in so return true
        localStorage.setItem('token', '');
        localStorage.setItem('tokenExpireAt', '');
        return false;
      }

      const relays = user['relays'];

      const existRelay = relays && relays.find((item) => {
        return item.serialNumber === SN;
      });

      if (!existRelay && !user.isAdmin) {
        this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url }});
        this.notifyService.showMessage({
          text: 'Недостатьно прав. Спробуйте з іншим обліковим записом',
          type: "error"
        });
        return false;
      }

      return true;
    }catch(e){
      console.log(e);
      this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url }});
      return false;
    }

  }
}

@Injectable()
export class AuthToPublicRelayGuardService implements CanActivate {

  constructor(private router: Router, private notifyService: NotifyService, private _relayService: RelayService,) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<boolean>|boolean {
    try {
      let SN = route.params && route.params.sn

      if (!SN){
        let match = state.url.match(/relay\/([\w\d]+)/);
        SN = match && match[1];
      }

      const subpageMatch = state.url.match(/relay\/[\w\d]+\/([\w]+)/);
      const subpage = subpageMatch && subpageMatch[1]? subpageMatch[1]: 'energy';

      return this._relayService.get(SN)
        .map((success) => {
            const relay = success["relay"];

            if (relay.publicTabs[subpage]) {
              return true;
            }else {
              let validSubpage = '';
              Object.entries(relay.publicTabs).find(([key, val]) => {
                if (val && !validSubpage) {
                  validSubpage = key
                }
                return val
              });


              if (!validSubpage) {
                return false;
              }
              this.router.navigate([`/public/relay/${SN}/${validSubpage}`]);
              return true;
            }
          },
          (error) => {
            console.log(error);
            return false
          });

    }catch(e){
      console.log(e);
      this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url }});
      return false;
    }

  }
}
