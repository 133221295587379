import {
  Inject,
  Injectable
} from "@angular/core";

import {
  Observable,
  BehaviorSubject
} from "rxjs";

import {round} from '../../utils/math';

import {AuthService} from "../../auth/services/auth-service";
import {Router} from '@angular/router';
//import {AuthHttp} from 'angular2-jwt';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {ActivatedRoute, ParamMap} from '@angular/router';
import * as moment from "moment";

import "rxjs/add/operator/map";
import {ChoosenDateType} from "../relay.types";
import {
  PHASE_3_RELAY_TYPES,
  TOP_323_HT5,
  TOP_326_HT5
} from '../constants';
import {PublicTabs} from "../components/relay-settings.component";


@Injectable()
export class RelayService {
  private ENDPOINT: string = 'api/relay/';
  private relayValue: BehaviorSubject<any>;
  sn: string;
  voltage: any;
  current: any;
  power: any;
  energy: any;
  relay: any = {};
  relayState: boolean;
  mode: string;
  fromDate: string;
  toDate: string;
  dateString: string;
  dateType: ChoosenDateType;


  constructor(
    private _authHttp: HttpClient,
    @Inject(HttpClient) private _http: HttpClient,
    private route: ActivatedRoute
  ) {
    this.sn = '';
    this.sn = this.route.snapshot.paramMap.get('sn');

    if (!this.sn) {
      let snArr = window.location.href.match(/relay\/(\w*)/);

      this.sn = snArr ? snArr[1] : '';
    }

    this.relayValue = new BehaviorSubject<any>({});
    console.log('relay service init SN ', this.sn);
  }

  get(sn: string): Observable<any> {
    this.sn = sn;
    return this._authHttp
      .get(this.ENDPOINT + sn)
      .map((r) => {
        this.relay = r && r['relay'];
        this.setRelayValue(this.relay);
        return r
      });
  }

  getLocal(sn: string): any {
    this.sn = sn;
    return this.relay;
  }

  saveLocal(relay: any): any {
    this.relay = { ...(this.relay || {}), ...relay };
    this.setRelayValue(this.relay);
  }

  getRelayValue(): Observable<boolean> {
    return this.relayValue.asObservable();
  }

  setRelayValue(newValue): void {
    this.relayValue.next(newValue);
  }

  getWithCosts(sn: string): Observable<any> {
    this.sn = sn;
    console.log('relay service SN ', sn);

    return this._authHttp
      .get(`${this.ENDPOINT}${sn}/withCosts`)
      .map((r) => {

        if (r['relay']) {
          r['relay'].cm_negEnergyPA = round(r['relay'].cm_negEnergyPA / 1000, 2);
          r['relay'].cm_negEnergyQA = round(r['relay'].cm_negEnergyQA / 1000, 2);
          r['relay'].cm_negEnergySA = round(r['relay'].cm_negEnergySA / 1000, 2);
          r['relay'].cm_posEnergyPA = round(r['relay'].cm_posEnergyPA / 1000, 2);
          r['relay'].cm_posEnergyQA = round(r['relay'].cm_posEnergyQA / 1000, 2);
          r['relay'].cm_posEnergySA = round(r['relay'].cm_posEnergySA / 1000, 2);

          r['relay'].lm_negEnergyPA = round(r['relay'].lm_negEnergyPA / 1000, 2);
          r['relay'].lm_negEnergyQA = round(r['relay'].lm_negEnergyQA / 1000, 2);
          r['relay'].lm_negEnergySA = round(r['relay'].lm_negEnergySA / 1000, 2);
          r['relay'].lm_posEnergyPA = round(r['relay'].lm_posEnergyPA / 1000, 2);
          r['relay'].lm_posEnergyQA = round(r['relay'].lm_posEnergyQA / 1000, 2);
          r['relay'].lm_posEnergySA = round(r['relay'].lm_posEnergySA / 1000, 2);

          r['relay'].tot_negEnergyPA = round(r['relay'].tot_negEnergyPA / 1000, 2);
          r['relay'].tot_negEnergyQA = round(r['relay'].tot_negEnergyQA / 1000, 2);
          r['relay'].tot_negEnergySA = round(r['relay'].tot_negEnergySA / 1000, 2);
          r['relay'].tot_posEnergyPA = round(r['relay'].tot_posEnergyPA / 1000, 2);
          r['relay'].tot_posEnergyQA = round(r['relay'].tot_posEnergyQA / 1000, 2);
          r['relay'].tot_posEnergySA = round(r['relay'].tot_posEnergySA / 1000, 2);
        }

        this.sn = sn;
        this.relay = r['relay'];
        return r;
      });
  }

  getReadings(page: number, perPage: number): Observable<any> {
    return this._authHttp
      .get(this.ENDPOINT + this.sn + '/readings')
      .map((r) => r);
  }

  getDividerFor100VRelay(typeRelay: string, readingType: string): number {
    return (typeRelay === TOP_323_HT5 || typeRelay === TOP_326_HT5) && readingType === 'voltage'? 1000: 1;
  }

  getReadingsByType(type: string = '', from?: number, to?: number) {
    let now = new Date(),
      resEndpoint = this.ENDPOINT + this.sn + '/readings';

    const fromString: number = from? +moment(from).startOf('day'): +moment().startOf('day');
    const toString: number = to? +moment(to).endOf('day'): +moment().endOf('day');

    resEndpoint += '?from=' + fromString + '&to=' + toString;

    if (type) {
      resEndpoint += '&type=' + type;
    }

    return this._authHttp
      .get(resEndpoint)
      .map((success) => {
          let values = {
            line: [],
            range: [],
            rangeMinMax:{
              min: '0',
              max: '0'
            },
            lineA: [],
            rangeA: [],
            rangeAMinMax:{
              min: '0',
              max: '0'
            },
            lineB: [],
            rangeB: [],
            rangeBMinMax:{
              min: '0',
              max: '0'
            },
            lineC: [],
            rangeC: [],
            rangeCMinMax:{
              min: '0',
              max: '0'
            },
            limits: [],
            limitsMinMax: {
              min: '0',
              max: '0'
            },
            voltageKoefs: [],
            currentKoefs: []
          };

          const DIVIDER_FOR_TOP_323_HT5 = this.getDividerFor100VRelay(success['typeRelay'], type);

          const limits = success['limits'];
          const existLimits = limits && (limits.max !== undefined || limits.min !== undefined);

          limits.min = limits.min || 0;
          limits.max = limits.max || 0;

          limits.min = limits.min / DIVIDER_FOR_TOP_323_HT5;
          limits.max = limits.max / DIVIDER_FOR_TOP_323_HT5;

          const rangeMinMax = {
            min: [],
            max: []
          };
          const rangeAMinMax = {
            min: [],
            max: []
          };
          const rangeBMinMax = {
            min: [],
            max: []
          };
          const rangeCMinMax = {
            min: [],
            max: []
          };

          let start = +new Date();
          let lastTimestamp
          if (success["data"] && success["data"].length) {
            const typeRelay = PHASE_3_RELAY_TYPES.indexOf(success["typeRelay"]) > -1 ? 3 : 1;

            let lastTimestamp = +from;

            for (let i = 0; i < success['data'].length; i++) {
              let item = success['data'][i];

              if (typeof item.timestamp !== 'number') {
                continue;
              }

              let count = 0;
              let readingTimestamp = +moment(item.timestamp).startOf('minute');

              while (readingTimestamp - lastTimestamp > 60 * 1000 && count < 60 * 24) {
                if (typeRelay === 1) {
                  values['line'].push([lastTimestamp, null]);
                  values['range'].push([lastTimestamp, null, null]);
                  existLimits && values['limits'].push([lastTimestamp, limits.min, limits.max]);
                } else {
                  values['lineA'].push([lastTimestamp, null]);
                  values['lineB'].push([lastTimestamp, null]);
                  values['lineC'].push([lastTimestamp, null]);
                  values['rangeA'].push([lastTimestamp, null, null]);
                  values['rangeB'].push([lastTimestamp, null, null]);
                  values['rangeC'].push([lastTimestamp, null, null]);
                  existLimits && values['limits'].push([lastTimestamp, limits.min, limits.max]);
                }
                lastTimestamp += 60 * 1000;
                count++
              }

              if (item[type].voltageKoef && values.voltageKoefs[values.voltageKoefs.length - 1] !== item[type].voltageKoef) {
                values.voltageKoefs.push(item[type].voltageKoef)
              }

              if (item[type].currentKoef && values.currentKoefs[values.currentKoefs.length - 1] !== item[type].currentKoef) {
                values.currentKoefs.push(item[type].currentKoef)
              }

              if (typeRelay === 1) {
                values['line'].push([item.timestamp, item[type].actual / DIVIDER_FOR_TOP_323_HT5]);
                existLimits && values['limits'].push([item.timestamp, limits.min, limits.max]);
              } else {
                  values['lineA'].push([item.timestamp, item[type].actual[0] / DIVIDER_FOR_TOP_323_HT5]);
                  values['lineB'].push([item.timestamp, item[type].actual[1] / DIVIDER_FOR_TOP_323_HT5]);
                  values['lineC'].push([item.timestamp, item[type].actual[2] / DIVIDER_FOR_TOP_323_HT5]);

                existLimits && values['limits'].push([item.timestamp, limits.min, limits.max]);
              }

              if (typeRelay === 1) {
                values['range'].push([item.timestamp, item[type]['min'] / DIVIDER_FOR_TOP_323_HT5, item[type]['max'] / DIVIDER_FOR_TOP_323_HT5]);
                rangeMinMax.min.push(item[type]['min'] / DIVIDER_FOR_TOP_323_HT5);
                rangeMinMax.max.push(item[type]['max'] / DIVIDER_FOR_TOP_323_HT5);
              } else {
                values['rangeA'].push([item.timestamp, item[type]['min'][0] / DIVIDER_FOR_TOP_323_HT5, item[type]['max'][0] / DIVIDER_FOR_TOP_323_HT5]);
                rangeAMinMax.min.push(item[type]['min'][0] / DIVIDER_FOR_TOP_323_HT5);
                rangeAMinMax.max.push(item[type]['max'][0] / DIVIDER_FOR_TOP_323_HT5);
                values['rangeB'].push([item.timestamp, item[type]['min'][1] / DIVIDER_FOR_TOP_323_HT5, item[type]['max'][1] / DIVIDER_FOR_TOP_323_HT5]);
                rangeBMinMax.min.push(item[type]['min'][1] / DIVIDER_FOR_TOP_323_HT5);
                rangeBMinMax.max.push(item[type]['max'][1] / DIVIDER_FOR_TOP_323_HT5);
                values['rangeC'].push([item.timestamp, item[type]['min'][2] / DIVIDER_FOR_TOP_323_HT5, item[type]['max'][2] / DIVIDER_FOR_TOP_323_HT5]);
                rangeCMinMax.min.push(item[type]['min'][2] / DIVIDER_FOR_TOP_323_HT5);
                rangeCMinMax.max.push(item[type]['max'][2] / DIVIDER_FOR_TOP_323_HT5);
              }

              lastTimestamp = readingTimestamp + 60 * 1000;
            }

            if (typeRelay === 3) {
              values.line = [];
              values.range = [];
              values['rangeAMinMax'].min = Math.min(...rangeAMinMax.min).toFixed(type === 'voltage'? 2: 3);
              values['rangeAMinMax'].max = Math.max(...rangeAMinMax.max).toFixed(type === 'voltage'? 2: 3);

              values['rangeBMinMax'].min = Math.min(...rangeBMinMax.min).toFixed(type === 'voltage'? 2: 3);
              values['rangeBMinMax'].max = Math.max(...rangeBMinMax.max).toFixed(type === 'voltage'? 2: 3);

              values['rangeCMinMax'].min = Math.min(...rangeCMinMax.min).toFixed(type === 'voltage'? 2: 3);
              values['rangeCMinMax'].max = Math.max(...rangeCMinMax.max).toFixed(type === 'voltage'? 2: 3);
            } else {
              values.lineA = [];
              values.lineB = [];
              values.lineC = [];
              values.rangeA = [];
              values.rangeB = [];
              values.rangeC = [];

              values['rangeMinMax'].min = Math.min(...rangeMinMax.min).toFixed(type === 'voltage'? 2: 3);
              values['rangeMinMax'].max = Math.max(...rangeMinMax.max).toFixed(type === 'voltage'? 2: 3);
            }
          }
          this[type] = values;

          values.limitsMinMax = limits;

          values.limitsMinMax.max = (limits.max || 0).toFixed(type === 'voltage'? 2: 3);
          values.limitsMinMax.min = (limits.min || 0).toFixed(type === 'voltage'? 2: 3);
          return values;
        },
        (error) => {
          console.log('ERROR', error);
        });
  }

  getReadingsTemperature(from: number, to: number) {
    let resEndpoint;
    resEndpoint = '/' + this.ENDPOINT + this.sn + '/readings/temperature?from=' + from + '&to=' + to;

    return this._authHttp
      .get(resEndpoint)
      .map((success) => {
          let values = {
            lines: [],
          };

          const linesCount = success['data'][0] && success['data'][0].temperature? success['data'][0].temperature.length: 3;

          for (let i = 0; i < linesCount; i++) {
            values.lines.push([]);
          }

          let start = +new Date();
          let lastTimestamp
          if (success['data'] && success['data'].length) {
            let lastTimestamp = +from;

            success['data'] = success['data'].sort((first, second) => {
             return first.timestamp - second.timestamp;
            });

            for (let i = 0; i < success['data'].length; i++) {
              let item = success['data'][i];

              if (typeof item.timestamp !== 'number') {
                continue;
              }

              let count = 0;
              let readingTimestamp = +moment(item.timestamp).startOf('minute');

              while (readingTimestamp - lastTimestamp > 60 * 1000 && count < 60 * 24) {
                for (let i = 0; i < linesCount; i++) {
                  values['lines'][i].push([lastTimestamp, null]);
                }
                lastTimestamp += 60 * 1000;
                count++
              }

              for (let i = 0; i < linesCount; i++) {
                values['lines'][i].push([item.timestamp, round(item['temperature'][i], 2)]);
              }

              lastTimestamp = readingTimestamp + 60 * 1000;
            }
          }

          return values;
        },
        (error) => {
          console.log('ERROR', error);
        });
  }

  getReadingsLevel(from: number, to: number) {
    let resEndpoint;
    resEndpoint = '/' + this.ENDPOINT + this.sn + '/readings/level?from=' + from + '&to=' + to;

    return this._authHttp
      .get(resEndpoint)
      .map((success) => {
          let values = {
            lineA: [],
            lineB: [],
            lineC: []
          };

          let lastTimestamp
          if (success['data'] && success['data'].length) {
            let lastTimestamp = +moment(from);

            success['data'] = success['data'].sort((first, second) => {
              return first.timestamp - second.timestamp;
            });

            for (let i = 0; i < success['data'].length; i++) {
              let item = success['data'][i];

              if (typeof item.timestamp !== 'number') {
                continue;
              }

              let count = 0;
              let readingTimestamp = +moment(item.timestamp).startOf('minute');

              while (readingTimestamp - lastTimestamp > 60 * 1000 && count < 60 * 24) {
                values['lineA'].push([lastTimestamp, null]);
                values['lineB'].push([lastTimestamp, null]);
                values['lineC'].push([lastTimestamp, null]);

                lastTimestamp += 60 * 1000;
                count++
              }

              values['lineA'].push([item.timestamp, item['act_s20mA'][0]]);
              values['lineB'].push([item.timestamp, item['act_s20mA'][1]]);
              values['lineC'].push([item.timestamp, item['act_s20mA'][2]]);

              lastTimestamp = readingTimestamp + 60 * 1000;
            }
          }

          return values;
        },
        (error) => {
          console.log('ERROR', error);
        });
  }

  groupEnergyReadings(readings: any[], mode: string): any {
    const resObj = {};

    for (let i = 0; i < readings.length; i++) {
      const reading = readings[i];
      const readingDate = moment(reading.ts).startOf('hour');

      const id = {
        day: readingDate.date(),
        month: readingDate.month()+1,
        year: readingDate.year(),
        hour: readingDate.hour(),
      };

      let key;

      if (mode === "day") {
        key = `${id.year}-${id.month}-${id.day}`;
        reading._id = {
          year: id.year,
          month: id.month,
          day: id.day,
        }
      }else if (mode === "hour") {
        key = `${id.year}-${id.month}-${id.day}-${id.hour}`;
        reading._id = {
          year: id.year,
          month: id.month,
          day: id.day,
          hour: id.hour,
        }
      }else {
        key = `${id.year}-${id.month}`;

        reading._id = {
          year: id.year,
          month: id.month,
        }
      }

      if (!resObj[key]){
        resObj[key] = {
          first: {...reading},
          last: {...reading},
          firstTs: reading.ts,
          lastTs: reading.ts,
          firstPosP1: reading.posP1,
          lastPosP2: reading['posP2']
        };
        continue;
      }

      if (+resObj[key].firstTs > +reading['ts']) {
        resObj[key].firstTs = reading['ts'];
        resObj[key].first = {...reading};
        resObj[key].firstPosP1 = reading['posP1'];
      }

      if (+resObj[key].lastTs < +reading['ts']) {
        resObj[key].last = {...reading};
        resObj[key].lastTs = reading['ts'];
        resObj[key].lastPosP2 = reading['posP2'];
      }
    }

    const keys = Object.keys(resObj)
    for (let i = 0; i < keys.length; i++) {
      const currentKey = keys[i];
      const prevKey = i > 0? keys[i - 1]: null;

      if (!prevKey) {
        continue;
      }

      resObj[prevKey].last = resObj[currentKey].first
      resObj[prevKey].lastPosP2 = resObj[currentKey].firstPosP1;
    }

    return resObj;
  }

  convertEnergyReadingsOnePhase(resObj: any): any[] {
    let result = [];

    for (let key in resObj) {
      const reading = resObj[key];

      const res = {
        posS1: reading.first.posS1,
        posS2: reading.last.posS2,
        posS: reading.last.posS2 - reading.first.posS1,
        negS1: reading.first.negS1,
        negS2: reading.last.negS2,
        negS: reading.last.negS2 - reading.first.negS1,
        posP1: reading.first.posP1,
        posP2: reading.last.posP2,
        posP: reading.last.posP2 - reading.first.posP1,
        posQ1: reading.first.posQ1,
        posQ2: reading.last.posQ2,
        posQ: reading.last.posQ2 - reading.first.posQ1,
        negP1: reading.first.negP1,
        negP2: reading.last.negP2,
        negP: reading.last.negP2 - reading.first.negP1,
        negQ1: reading.first.negQ1,
        negQ2: reading.last.negQ2,
        negQ: reading.last.negQ2 - reading.first.negQ1,
        _id: reading.first._id,
        dateStart: reading.first.date,
        dateEnd: reading.last.date,
        ts: reading.first['ts']
      };

      result.push(res);
    }

    result = result.sort((first, second) => {
      return first.ts - second.ts;
    });

    let res = 0
    for (let i = 0; i < result.length; i++) {
      if (result[i + 1]) {
        result[i].posP = result[i + 1].posP1 - result[i].posP1;
        result[i].posQ = result[i + 1].posQ1 - result[i].posQ1;
        result[i].posS = result[i + 1].posS1 - result[i].posS1;
        result[i].negP = -result[i + 1].negP1 + result[i].negP1;
        result[i].negQ = -result[i + 1].negQ1 + result[i].negQ1;
        result[i].negS = -result[i + 1].negS1 + result[i].negS1;
      } else {
        result[i].posP = result[i].posP2 - result[i].posP1;
        result[i].posQ = result[i].posQ2 - result[i].posQ1;
        result[i].posS = result[i].posS2 - result[i].posS1;
        result[i].negP = -result[i].negP2 + result[i].negP1;
        result[i].negQ = -result[i].negQ2 + result[i].negQ1;
        result[i].negS = -result[i].negS2 + result[i].negS1;
      }

      res = res + result[i].posP;
    }

    return result;
  }

  convertEnergyReadingsThreePhase(resObj: any): any[] {
    let result = [];
    const keys =['posP', 'posQ', 'posS', 'negP', 'negQ', 'negS'];

    for (let key in resObj) {
      const reading = resObj[key];
      const res = {
        _id: reading.first._id,
        dateStart: reading.first.date,
        dateEnd: reading.last.date,
        ts: reading.first['ts'],
        voltageKoef: reading.first['voltageKoef'] || 1,
        currentKoef: reading.first['currentKoef'] || 1,
      };

      const energyKoef = res.voltageKoef * res.currentKoef;

      keys.forEach((name) => {
        const negKoef = name.includes('neg')? -1: 1;

        let keyName = name + '1';
        res[keyName] = reading.first[keyName] * energyKoef;

        keyName = name + '2';
        res[keyName] = reading.last[keyName] * energyKoef;

        keyName = name;
        res[keyName] = negKoef * (reading.last[keyName + '2'] - reading.first[keyName + '2']);

        keyName = name + '1A';
        res[keyName] = reading.first[keyName];

        keyName = name + '2A';
        res[keyName] = reading.last[keyName];

        keyName = name + 'PerPhaseA';
        res[keyName] = negKoef * (reading.last[name + '2A'] - reading.first[name + '2A']);


        keyName = name + '1B';
        res[keyName] = reading.first[keyName];

        keyName = name + '2B';
        res[keyName] = reading.last[keyName];

        keyName = name + 'PerPhaseB';
        res[keyName] = negKoef * (reading.last[name + '2B'] - reading.first[name + '2B']);

        keyName = name + '1C';
        res[keyName] = reading.first[keyName];

        keyName = name + '2C';
        res[keyName] = reading.last[keyName];

        keyName = name + 'PerPhaseC';
        res[keyName] = negKoef * (reading.last[name + '2C'] - reading.first[name + '2C']);

        keyName = name + 'PerPhase';
        res[keyName] = [res[keyName + 'A'], res[keyName + 'B'], res[keyName + 'C']]
      });

      result.push(res);
    }

    result = result.sort((first, second) => {
      return first.ts - second.ts;
    });

    for (let i = 0; i < result.length; i++) {
      const voltageKoefChanged = result[i].voltageKoef !== (result[i+1] && result[i+1].voltageKoef);
      const currentKoefChanged = result[i].currentKoef !== (result[i+1] && result[i+1].currentKoef);

      if (result[i + 1] && !voltageKoefChanged && !currentKoefChanged) {
        result[i].posP = result[i + 1].posP1 - result[i].posP1;
        result[i].posQ = result[i + 1].posQ1 - result[i].posQ1;
        result[i].posS = result[i + 1].posS1 - result[i].posS1;
        result[i].negP = result[i + 1].negP1 - result[i].negP1;
        result[i].negQ = result[i + 1].negQ1 - result[i].negQ1;
        result[i].negS = result[i + 1].negS1 - result[i].negS1;

        result[i].posPPerPhase = [
          result[i + 1].posP1A - result[i].posP1A,
          result[i + 1].posP1B - result[i].posP1B,
          result[i + 1].posP1C - result[i].posP1C,
        ];

        result[i].posQPerPhase = [
          result[i + 1].posQ1A - result[i].posQ1A,
          result[i + 1].posQ1B - result[i].posQ1B,
          result[i + 1].posQ1C - result[i].posQ1C,
        ];

        result[i].negPPerPhase = [
          result[i + 1].negP1A - result[i].negP1A,
          result[i + 1].negP1B - result[i].negP1B,
          result[i + 1].negP1C - result[i].negP1C,
        ];

        result[i].negQPerPhase = [
          result[i + 1].negQ1A - result[i].negQ1A,
          result[i + 1].negQ1B - result[i].negQ1B,
          result[i + 1].negQ1C - result[i].negQ1C,
        ];
      } else {
        result[i].posP = result[i].posP2 - result[i].posP1;
        result[i].posQ = result[i].posQ2 - result[i].posQ1;
        result[i].posS = result[i].posS2 - result[i].posS1;
        result[i].negP = result[i].negP2 - result[i].negP1;
        result[i].negQ = result[i].negQ2 - result[i].negQ1;
        result[i].negS = result[i].negS2 - result[i].negS1;

        result[i].posPPerPhase = [
          result[i].posP2A - result[i].posP1A,
          result[i].posP2B - result[i].posP1B,
          result[i].posP2C - result[i].posP1C,
        ];

        result[i].posQPerPhase = [
          result[i].posQ2A - result[i].posQ1A,
          result[i].posQ2B - result[i].posQ1B,
          result[i].posQ2C - result[i].posQ1C,
        ];

        result[i].negPPerPhase = [
          result[i].negP2A - result[i].negP1A,
          result[i].negP2B - result[i].negP1B,
          result[i].negP2C - result[i].negP1C,
        ];

        result[i].negQPerPhase = [
          result[i].negQ2A - result[i].negQ1A,
          result[i].negQ2B - result[i].negQ1B,
          result[i].negQ2C - result[i].negQ1C,
        ];
      }
    }

    return result;
  }

  getReadingsEnergy(from: string, to: string, hourMode: boolean = false) {
    let resEndpoint;
    const fromConverted = hourMode? moment().startOf('hour'): moment(from).startOf('day');
    const toConverted = hourMode? moment().endOf('hour'): moment(to).endOf('day');
    let timezone;
    try {
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    }catch(e) {
      timezone = "Europe/Kiev"
    }

    resEndpoint = this.ENDPOINT + this.sn + '/readings/energy?from=' + fromConverted + '&to=' + toConverted + '&timezone=' + timezone;

    return this._authHttp
      .get(resEndpoint)
      .map((success) => {
          const is3Phase = !!(success['data'] && success['data'].length && success['data'][0]['posP1A'] !== undefined);
          let mode = 'day'; // 3 type of mode: hour - get energy by 0.5 hours, day - get energy values by days, month - get values by month

          let diffDays = (+toConverted - +fromConverted) / (24 * 60 * 60 * 1000);

          if (diffDays > 32) {
            mode = 'month';
          } else if (diffDays <= 1.1) {
            mode = 'hour'
          }

          const groupedReadings = this.groupEnergyReadings(success['data'], mode);
          success['data'] = is3Phase? this.convertEnergyReadingsThreePhase(groupedReadings): this.convertEnergyReadingsOnePhase(groupedReadings);

          success['data'].forEach((item) => {
            item.negP = -Math.abs(round(item.negP / 1000, 3));
            item.negQ = -Math.abs(round(item.negQ / 1000, 3));
            item.negS = -Math.abs(round(item.negS / 1000, 3));
            item.posP = Math.abs(round(item.posP / 1000, 3));
            item.posQ = Math.abs(round(item.posQ / 1000, 3));
            item.posS = Math.abs(round(item.posS / 1000, 3));

            item.negPPerPhase = item.negPPerPhase? item.negPPerPhase.map((phaseItem) => -Math.abs(round(phaseItem / 1000, 3))): -Math.abs(item.negP / 1000);
            item.negQPerPhase = item.negQPerPhase? item.negQPerPhase.map((phaseItem) => -Math.abs(round(phaseItem / 1000, 3))): -Math.abs(item.negQ / 1000);
            item.posPPerPhase = item.posPPerPhase? item.posPPerPhase.map((phaseItem) => Math.abs(round(phaseItem / 1000, 3))): Math.abs(item.posP / 1000);
            item.posQPerPhase = item.posQPerPhase? item.posQPerPhase.map((phaseItem) => Math.abs(round(phaseItem / 1000, 3))): Math.abs(item.posQ / 1000);
          });

          success['total'].totalNegP = round(success['total'].totalNegP / 1000, 3);
          success['total'].totalNegQ = round(success['total'].totalNegQ / 1000, 3);
          success['total'].totalNegS = round(success['total'].totalNegS / 1000, 3);
          success['total'].totalPosP = round(success['total'].totalPosP / 1000, 3);
          success['total'].totalPosQ = round(success['total'].totalPosQ / 1000, 3);
          success['total'].totalPosS = round(success['total'].totalPosS / 1000, 3);

          success['data'] = success['data'].sort((first, second) => {
            return first.ts - second.ts;
          });

          return {
            data: success['data'],
            total: success['total'],
            mode: mode
          };
        },
        (error) => {
          console.log('ERROR', error);
        });
  }

  makeAction(on: boolean): Observable<any> {
    return this._authHttp
      .post(this.ENDPOINT + this.sn + '/action', {on: on})
      .map((r) => r);
  }

  getNowReadings(): Observable<any> {
    return this._authHttp
      .get(this.ENDPOINT + this.sn + '/relayData')
      .map((r) => r);
  }

  getSettings(sn: string) {
    return this._authHttp
      .get(this.ENDPOINT + sn + '/settings')
      .map((r) => r);
  }

  saveUsersRestriction(sn: string, usersRestriction: any) {
    return this._authHttp
      .post(this.ENDPOINT + sn + '/usersRestriction', {
        usersRestriction: usersRestriction
      })
      .map((r) => r);
  }

  changeUsers(sn: string, userId: string, userRole: string) {
    return this._authHttp
      .post(this.ENDPOINT + sn + '/users', {userId: userId, userRole: userRole})
      .map((r) => r);
  }

  removeUser(sn: string, userId: string) {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}), body: {userId: userId}
    };

    return this._authHttp
      .delete(this.ENDPOINT + sn + '/users', httpOptions)
      .map((r) => r);
  }

  getLogs(serialNumber: string, page: number, limit: number) {
    return this._authHttp
      .get(this.ENDPOINT + serialNumber + '/logs?page=' + page + '&limit=' + limit)
      .map((r) => r);
  }

  makePublic(sn: string, isPublic: boolean, publicTabs: PublicTabs, isOnFrontPage: boolean, publicRelayName: string) {
    return this._authHttp
      .post(this.ENDPOINT + sn + '/makePublic', {
        isPublic: isPublic,
        publicTabs,
        isOnFrontPage,
        publicName: publicRelayName,
      })
      .map((r) => r);
  }

  saveOwnerSettings(sn: string, data: object) {
    return this._authHttp
      .post(this.ENDPOINT + sn + '/saveOwnerSettings', data)
      .map((r) => r);
  }

  compareCosts(sn: string, from: string, to: string) {
    let endpoint = this.ENDPOINT + sn + '/costs/compare?from=' + from + '&to=' + to;

    return this._authHttp
      .get(endpoint)
      .map((r) => r);
  }

  getCosts(sn: string, from: number, to: number) {

    let endpoint = this.ENDPOINT + sn + '/costs?from=' + from + '&to=' + to;

    return this._authHttp
      .get(endpoint)
      .map((r) => r);
  }

  setApplianceName(sn: string, name: string, key_appliance: number) {
    return this._authHttp
      .post(this.ENDPOINT + sn + '/saveApplianceName', {name: name, key_appliance: key_appliance})
      .map((r) => r);
  }

  getApplianceMonthEnergy(sn: string, year: number, month: number) {
    return this._authHttp
      .get(`${this.ENDPOINT}${sn}/appliance/events/${year}/${month}`)
      .map((r) => r);
  }

  addApplianceEvent(appEvents: any[] | null, value: any) {
    if (appEvents) {
      appEvents.push(value);
    }else {
      appEvents = [value];
    }

    return appEvents;
  }

  convertApplianceEvents(applianceEvents: any, relayEvents: any, mode: 'hour' | 'day' | 'month' = 'hour') {
    let res = {
      appliances: {},
      dates: [],
      relay: {
        P: [],
        Q: [],
      }
    };

    const relayEventsArr = Object.entries(relayEvents);
    let lastAppTurnOnEvent = {};
    relayEventsArr.forEach((event, i) => {
      const [key, relayEvent] = event;

      const PFirst = relayEvent.first.posP1;
      const PLast = relayEventsArr[i + 1]? relayEventsArr[i + 1][1]['first'].posP1: relayEvent.last.posP2;
      const QFirst = relayEvent.first.posQ1;
      const QLast = relayEventsArr[i + 1]? relayEventsArr[i + 1][1]['first'].posQ1: relayEvent.last.posQ2;

      res.relay.P.push(PLast - PFirst);
      res.relay.Q.push(QLast - QFirst);

      res.dates.push(relayEvent.first._id);

      /* check applianceEvents */
      for (let appId in applianceEvents) {
        const appliance = applianceEvents[appId]
        if (appliance[key]) {
          res.appliances[appId] = res.appliances[appId] || {P: [], Q: [], posP1: [], posP2: [], posQ1: [], posQ2: []};
          res.appliances[appId].name = appliance[key].first.name;
          // create
          const appEvent = appliance[key];

          const nextReadingKey = relayEventsArr[i + 1]? relayEventsArr[i + 1][0]: null;
          const nextReading = applianceEvents[appId]? applianceEvents[appId][nextReadingKey]: null;
          const nextP = nextReading? nextReading.first.posP1: null;
          const nextQ = nextReading? nextReading.first.posQ1: null;

          const PAppFirst = appEvent.first.posP1;
          const PAppLast = nextP || appEvent.last.posP2;
          const QAppFirst = appEvent.first.posQ1;
          const QAppLast = nextQ || appEvent.last.posQ2;

          const p = Math.abs(PAppLast - PAppFirst) / 3600;
          const q = Math.abs(QAppLast - QAppFirst) / 3600;

          if (lastAppTurnOnEvent[appId]) {
            // распределяем энергию в зависимости от времени
            const startTime = +lastAppTurnOnEvent[appId].lastTs;
            const endTime = +appEvent.firstTs;
            const startPeriod = +moment(startTime).endOf(mode);
            const endPeriod = +moment(endTime).startOf(mode);
            const timeDiff = endTime - startTime;
            const startP = +lastAppTurnOnEvent[appId].last.posP2;
            const endP = +appEvent.first.posP1;
            const startQ = +lastAppTurnOnEvent[appId].last.posQ2;
            const endQ = +QAppLast;
            const p = Math.abs(endP - startP) / 3600;
            const q = Math.abs(endQ - startQ) / 3600;
            const pPerMs = p / timeDiff;
            const qPerMs = q / timeDiff;

            let emptyPeriodMin = 60;
            if (mode !== 'hour') {
              emptyPeriodMin = mode === 'day'? 24 * 60: 365 * 24 * 60;
            }

            const lastIndex = res.appliances[appId].P.length;
            let lastNotNullIndex = lastIndex;

            [...res.appliances[appId].P].reverse().find((el, i) => {
              if (el !== null) {
                lastNotNullIndex = lastIndex - i;
                return true;
              }
            });

            const emptyTimePeriods = lastIndex - lastNotNullIndex;

            // это часть энергии от текущей даты необходимая для прошлой даты
            const firstPeriodAdditionalP = (startPeriod - startTime) * pPerMs;
            const firstPeriodAdditionalQ = (startPeriod - startTime) * qPerMs;

            // это пересчет прошлой даты от начальной даты до конечной (без начальных показаний след даты)
            const firstPeriodMainP = Math.abs(lastAppTurnOnEvent[appId].last.posP2 - lastAppTurnOnEvent[appId].first.posP1) / 3600;
            const firstPeriodMainQ = Math.abs(lastAppTurnOnEvent[appId].last.posQ2 - lastAppTurnOnEvent[appId].first.posQ1) / 3600;

            res.appliances[appId].P[lastIndex - emptyTimePeriods-1] = firstPeriodMainP + firstPeriodAdditionalP;
            res.appliances[appId].Q[lastIndex - emptyTimePeriods-1] = firstPeriodMainQ + firstPeriodAdditionalQ;

            const lastPeriodP = (endTime - endPeriod) * pPerMs + Math.abs(PAppLast - PAppFirst) / 3600;
            const lastPeriodQ = (endTime - endPeriod) * qPerMs + Math.abs(QAppLast - QAppFirst) / 3600;

            for (let i = 0; i < emptyTimePeriods; i++) {
              const index = lastIndex - emptyTimePeriods + i;
              res.appliances[appId].P[index] = emptyPeriodMin * 60 * 1000 * pPerMs;
              res.appliances[appId].Q[index] = emptyPeriodMin * 60 * 1000 * qPerMs;
            }

            res.appliances[appId].P.push(lastPeriodP);
            res.appliances[appId].Q.push(lastPeriodQ);
            res.appliances[appId].posP2.push(endP);
            res.appliances[appId].posQ2.push(endQ);
          }else {
            res.appliances[appId].P.push(p);
            res.appliances[appId].Q.push(q);
            res.appliances[appId].posP2.push(PAppLast);
            res.appliances[appId].posQ2.push(QAppLast);
          }

          if (appEvent.last['conditionLast']) {
            lastAppTurnOnEvent[appId] = appEvent;
          }else {
            lastAppTurnOnEvent[appId] = null;
          }

        }else {
          res.appliances[appId] = res.appliances[appId] || {P: [], Q: [], posP2: [], posQ2: []};

          res.appliances[appId].P.push(null);
          res.appliances[appId].Q.push(null);
          res.appliances[appId].posP2.push(null);
          res.appliances[appId].posQ2.push(null);
        }
      }

    });

    return res;
  }

  getApplianceEvents(sn: string, from: number, to: number) {
    return this._authHttp
      .get(`${this.ENDPOINT}${sn}/appliance/events?from=${from}&to=${to}`)
      .map((r) => {
        const events = r['events'];
        const relayEvents = r['relayEvents'];

        let mode: 'hour' | 'day' | 'month' = 'day'; // 3 type of mode: hour - get energy by 0.5 hours, day - get energy values by days, month - get values by month

        let diffDays = (to - from) / (24 * 60 * 60 * 1000);

        if (diffDays > 32) {
          mode = 'month';
        } else if (diffDays <= 1.1) {
          mode = 'hour'
        }

        const appEventsById = {};

        events.forEach(ev => {
          if (appEventsById[ev._id]) {
            appEventsById[ev._id].push(ev);
          }else {
            appEventsById[ev._id] = [ev];
          }
        });

        let groupedRelayReadings = this.groupEnergyReadings(relayEvents.data, mode);

        for (let key in appEventsById) {
          appEventsById[key] = this.groupEnergyReadings(appEventsById[key], mode);
        }

        const appEvents = this.convertApplianceEvents(appEventsById, groupedRelayReadings, mode);

        return {events: appEvents, relayEvents: relayEvents, mode};
      });
  }

  getRelaySchedules(sn: string) {
    return this._authHttp
      .get(`${this.ENDPOINT}${sn}/schedule`)
      .map((r) => r);
  }

  saveDate(dateType: ChoosenDateType | undefined) {
    if(dateType) {
      this.dateType = dateType
    }else {
      this.dateType = {
        from: +moment().startOf('day'),
        to: +moment().endOf('day'),
        dateString: moment().format('DD MMM, YYYY'),
        mode: 'today'
      };
    }

    return this.dateType;
  }

  getDate():ChoosenDateType {
    return this.dateType || {
      from: +moment().startOf('day'),
      to: +moment().endOf('day'),
      dateString: moment().format('DD MMM, YYYY'),
      mode: 'today'
    };
  }

  getDefences(serialNumber: string) {
    return this._authHttp
      .get(`${this.ENDPOINT}${serialNumber}/defences`)
      .map((r) => r);
  }

  setLogsViaTelegram(serialNumber: string, sendLogsTelegram: boolean) {
    return this._authHttp
      .post(`${this.ENDPOINT}${serialNumber}/users/setLogsViaTelegram`,
        {sendLogsTelegram: sendLogsTelegram}
        )
      .map((r) => r);
  }

  saveWebhookIntegration(serialNumber: string, endpoint: string) {
    return this._authHttp
      .post(`${this.ENDPOINT}${serialNumber}/webhook/setIntegration`,
        {endpoint: endpoint}
      )
      .map((r) => r);
  }

  removeWebhookIntegration(serialNumber: string) {
    return this._authHttp
      .delete(`${this.ENDPOINT}${serialNumber}/webhook/setIntegration`)
      .map((r) => r);
  }
}
