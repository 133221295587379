
import {
  Inject,
  Injectable,
  OnInit
} from "@angular/core";

import { Router } from '@angular/router';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import "rxjs/add/operator/map";
import {Observable} from "rxjs";

@Injectable()
export class AuthService implements OnInit {
  static ENDPOINT: string = "/api/users/";
  isLoggedIn: boolean = false;
  user: any = {};

  constructor(
    //@Inject(AuthHttp) private _authHttp: AuthHttp,
    @Inject(HttpClient) private _http: HttpClient,
    private _router: Router
  ) {

  }

  ngOnInit() {
    // get user from localstorage if exist
    if (localStorage.getItem('currentUser')) {
      this.user = JSON.parse(localStorage.getItem('currentUser'));
    }
  }

  getUsername():string {
    try {
      if (localStorage.getItem('currentUser')) {
        this.user = JSON.parse(localStorage.getItem('currentUser'));
        return this.user.username || ''
      }else {
        return ''
      }
    }catch(e){
      //ignore
      return ''
    }

  }

  loggedIn(): Observable<boolean>|boolean {
    return localStorage.getItem('token')?true:false;
    //return tokenNotExpired();
  }

  isAuthenticated(): Observable<any> {
       return this._http
      .get(AuthService.ENDPOINT)
      .map(response => {

          let r = response;
          if (r["logged"]) {
            this.isLoggedIn = r["logged"] ? true : false;
          }
          return r;
        }
      );
  }

  getRole(id: string):Observable<any> {
    return this._http
      .get(AuthService.ENDPOINT + "/" + id)
      .map((r) => r);
  }

  login(password: string, email: string):Observable<any> {
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');

    return this._http
      .post('api/users/login',
        { password: password, email: email },
        {headers: headers})
      .map((response) => {
        // login successful if there's a jwt token in the response
        let user = response;
        console.log('login response ', response);
        if (user) {
          // store user details and jwt token in bearer storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem('token', user["token"]);
          localStorage.setItem('tokenExpireAt', user["expireAt"]);

          this.user = user;
          this.isLoggedIn = true;

          return user;
        }
      });
  }

  resetPassword(email: string):Observable<any> {
    return this._http
      .get(`api/users/resetPassword?email=${email}`)
      .map((response) => {
        return response;
      });
  }

  changePassword(password: string, repeatPassword: string, token: string) {

    return this._http
      .post('api/users/changePassword',
        {password: password, repeatPassword: repeatPassword, passwordToken: token})
  }

  signup(username: string, password: string, email: string):Observable<any> {
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');

    return this._http
      .post('api/users/signup',
        { username: username, password: password, email: email },
        {headers: headers})
      .map(data => {
          console.log('success signup - ', data);

        },
        error => {
          console.log('error signup - ', error);
        });
  }

  logout(): void {
    const token = localStorage.getItem('token')
    let headers = new HttpHeaders();
    headers.set('Authorization', token);

    this._http
      .get('api/users/logout', {headers: headers})
      .subscribe((response) => {
        // remove user from bearer storage to log user out
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpireAt');
        localStorage.removeItem('currentUser');
        this._router.navigate(['/']);
      });
  }
}
