export interface SettingsType {
  confirm: boolean;
  common: {
    timeToOn: number;
    autoTurn: boolean;
  },
  voltage: {
    min: number;
    max: number;
    diff: number;
    enable: boolean;
  },
  current: {
    max: number;
    category: string;
    enable: boolean;
  },
  power: {
    max: number;
    enable: boolean;
  },
  frequency: {
    min: number;
    max: number;
    diff: number;
    enable: boolean;
  },
  temperature: {
    max: number;
    enable: boolean;
  },
  difCurrent: {
    max: string;
    enable: boolean;
  }
};

export const DefaultSettings = {
  confirm: false,
  common: {
    timeToOn: 30,
    autoTurn: true
  },
  voltage: {
    min: 198,
    max: 242,
    diff: 5,
    enable: false
  },
  current: {
    max: 60,
    category: '2',
    enable: false
  },
  power: {
    max: 12,
    enable: false
  },
  frequency: {
    min: 49.9,
    max: 50.1,
    diff: 0.1,
    enable: false
  },
  temperature: {
    max: 70,
    enable: false
  },
  difCurrent: {
    max: '300',
    enable: false
  }
};

export const convertRelayDataToCards = (koefs: any, settings: any): SettingsType => {
  const convertedSettings:SettingsType = JSON.parse(JSON.stringify(DefaultSettings));

  for (let key in settings) {
    if (settings[key] && settings[key]) {
      if (key === 'current') {
        convertedSettings[key].category = settings[key] && '' + settings[key].category;
        // ток с реле приходит в милиамперах, а должен показываться в Амперах. Поэтому делится на 1000
        convertedSettings[key].max = settings[key] && (settings[key].max * koefs.currentKoef)/1000;
        convertedSettings[key].enable = !!settings[key].enable;
      }else if (key === 'difCurrent') {
        convertedSettings[key].max = settings[key] && '' + (settings[key].max * koefs.currentKoef);
        convertedSettings[key].enable = !!settings[key].enable;
      }else if (key === 'voltage') {
        convertedSettings[key].max = settings[key] && (settings[key].max * koefs.voltageKoef);
        convertedSettings[key].min = settings[key] && (settings[key].min * koefs.voltageKoef);
        convertedSettings[key].diff = settings[key] && settings[key].diff;
        convertedSettings[key].enable = !!settings[key].enable;
      }else if(key === 'power') {
        // мощность с реле приходит в ваттах, а должен показываться в киловаттах. Поэтому делится на 1000
        convertedSettings[key].max = settings[key] && settings[key].max * (koefs.currentKoef * koefs.voltageKoef) / 1000;
        convertedSettings[key].enable = !!settings[key].enable;
      }else {
        convertedSettings[key] = settings[key];
      }
    }
  }

  return convertedSettings;
}

export const getKoefs = (energySettings: any) => {
  const koefs = {
    voltageKoef: energySettings && energySettings['voltageKoef'] || 1,
    voltageKoefU1: energySettings && energySettings['voltageKoefU1'] || 220,
    voltageKoefU2: energySettings && energySettings['voltageKoefU2'] || 220,
    currentKoef: energySettings && energySettings['currentKoef'] || 1,
    currentKoefI1: energySettings && energySettings['currentKoefI1'] || 60,
    currentKoefI2: energySettings && energySettings['currentKoefI2'] || 60,
    powerKoef: 1,
  };

  koefs.powerKoef = koefs.voltageKoef * koefs.currentKoef;

  return koefs;
}

export default {
  getKoefs,
  convertRelayDataToCards
}
