import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'change-relay-name-dialog',
  templateUrl: '../templates/makeActionDialog.html',
})
export class makeActionDialog {
  constructor(
    public dialogRef: MatDialogRef<makeActionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
