import {Component, OnInit, OnDestroy} from "@angular/core";
import {AuthService} from "../../../auth/services/auth-service";
import {ActivatedRoute, ParamMap, Router} from '@angular/router';

import {HttpClient} from '@angular/common/http';
import {Socket} from 'ng-socket-io';

import {NotifyService} from '../../../lib/services/notify.service';
import {getToken} from '../../../utils/authTokens';
import { ALL_RELAY_TYPES } from '../../../relay/constants';
import {
  RelayService
} from "../../../relay/services/relay-service";

import { LOG_MESSAGES } from '../../../relay/constants';
import {UserService} from "../../../user/services/user-service";
import {PRODUCER_ROLE} from "../../constants";


@Component({
  selector: "test-relay",
  templateUrl: "../templates/test-relay.html",
  styleUrls: ["../styles/test-relay.scss"]
})
export class TestRelayComponent implements OnInit, OnDestroy {
  user: any = {};
  relay: any;
  relayInterval: any;
  errors: any = [];
  errorKeys: any = [];
  errorsPage: number = 1;
  errorsPerPage: number = 5;
  readings: any = [];
  readingsKeys: any = [];
  errorsTotal: number = 0;
  noErrorData: boolean = false;
  ENDPOINT: string = 'api/users/';
  loading: boolean = true;
  relaySerialNumber: string = '';
  socketData: any = {};
  firmwares: any = [];
  firmware: string = '';
  typesRelay: string[] = Object.values(ALL_RELAY_TYPES);
  isBroken: Boolean = false;
  wsEvents: {time: string, eventName: string, data: string}[] = [];
  logs: any[] = [];
  page: number = 1;
  firstPage:boolean = true;
  lastPage: boolean = true;
  limit: number = 50;
  lengthLogs: number = 0;
  socketEventName: string = '';
  socketEventBody: string = '';
  socketEventResult: string = '';
  isSocketEventInProgress: boolean = false;
  isProducer: boolean = true;
  energySocketEventResult: string = '';
  isEnergySocketEventInProgress: boolean = false;

  constructor(private _authService: AuthService,
              private _authHttp: HttpClient,
              private _router: Router,
              private _route: ActivatedRoute,
              private socket: Socket,
              private _notifyService: NotifyService,
              private _relayService: RelayService,
              private _userService: UserService,
  ) {
    this.user = {};
    this.relay = {};

    this.relaySerialNumber = this._route.snapshot.paramMap.get('serialNumber');
  }

  ngOnInit() {
    this._userService.getMe()
      .subscribe(
        (user) => {
          this.user = user;
          this.isProducer = this.user.adminRole === PRODUCER_ROLE;
        }
      );

    this._route.paramMap
      .subscribe((params) => {
        this.relaySerialNumber = params.get('sn')
        if (!this.relaySerialNumber) {
          this.relaySerialNumber = this._route.snapshot.paramMap.get('serialNumber');
        }


        let endpoint = 'api/admin/relay/' + this.relaySerialNumber;
        this._authHttp.get(endpoint)
          .subscribe(
            (success) => {

              this.relay = success["relay"] ? success["relay"] : {};
              this.firmwares = success["firmwares"] ? success["firmwares"] : [];

              this.socket.emit('join room serialNumber', {serialNumber: this.relaySerialNumber, token: getToken()});

              this.getLogs(1);
              this.loading = false;
            },
            (error) => {
              console.log('this.relay error ', error);
            }
          );

        this.socket.on('server:relay:receive-data', (info) => {
          console.log('server:relay:receive-data');
          const { eventName, data } = info;

          try {
            this.wsEvents = this.wsEvents.slice(0, 100);
            const date = new Date();
            this.wsEvents.push({time: date.toLocaleTimeString(), eventName: eventName, data: JSON.stringify(data, null, 2)});

            if (this.wsEvents.length > 1000) {
              const length = this.wsEvents.length;
              this.wsEvents = this.wsEvents.slice(length - 1000);
            }
          }catch(e) {
            console.error(e);
          }
        });


        this.socket.emit('admin.join-relay-room', {
            serialNumber: this.relaySerialNumber, token: getToken()
          },
          (data) => {
            const {success, message} = data;

            if (!success) {
              this._notifyService.showMessage({
                text: message || 'Сталася помилка',
                type: 'error'
              });
            }
          });

        this.relayInterval = setInterval(() => {
          this.getRelay();
        }, 60000);

        this.socket.on('reconnect', () => {
            console.log('reconnect');
            this.socket.emit('join room', {relayId: this.relay.idDb, token: getToken()});
            this.socket.emit('admin.join-relay-room', {
                serialNumber: this.relaySerialNumber, token: getToken()
              },
              (data) => {
                const {success, message} = data;

                if (!success) {
                  this._notifyService.showMessage({
                    text: message || 'Сталася помилка',
                    type: 'error'
                  });
                }
              });
          }
        );


        this.socket.on('metrology.relay-get-metrology', (data) => {
            console.log('metrology.relay-get-metrology');

            this.socketData = {
              type: "metrology data",
              data: data
            }
          }
        );

        this.socket.on('metrology.relay-get-relay-data', (data) => {
            console.log('metrology.relay-get-relay-data');

            this.socketData = {
              type: "relay data",
              data: data
            }
          }
        );

        this.socket.on('metrology.relay-success-set-relay-data', (data) => {
            console.log('metrology.relay-success-set-relay-data', data);

            this.socketData = {
              type: "success set relay data",
              data: data
            }
          }
        );

        this.socket.on('admin error', (data) => {
            this._notifyService.showMessage({
              text: data.message || 'Some error',
              type: "error"
            });
            console.log('admin error ', data);
          }
        );

        this.socket.on('admin success', (data) => {
            this._notifyService.showMessage({
              text: data.message || 'Success',
              type: "success"
            });
            console.log('admin success ', data);
          }
        );

      });



  }

  ngOnDestroy() {
    if (this.relayInterval) {
      clearInterval(this.relayInterval);
    }

    this.socket.emit('leave room serialNumber',
      {serialNumber: this.relaySerialNumber, token: getToken()},
      (data) => {
        const {success, message} = data;

        if (!success) {
          this._notifyService.showMessage({
            text: message || 'Сталася помилка',
            type: 'error'
          });
        }
      });
  }

  getRelay() {
    this.loading = true;

    let endpoint = 'api/admin/relay/' + this.relaySerialNumber;
    this._authHttp.get(endpoint)
      .subscribe(
        (success) => {

          this.relay = success["relay"] ? success["relay"] : {};
          this.firmwares = success["firmwares"] ? success["firmwares"] : [];

          this.loading = false;
        },
        (error) => {
          console.log('this.relay error ', error);
        }
      );
  }

  setRelayData() {
    console.log("setRelayData ", this.relaySerialNumber, this.relay);
  }

  getRelayData() {

    console.log("getRelayData ", this.relaySerialNumber);
    this.socket.emit('admin.relay-need-relay-data', {
        serialNumber: this.relaySerialNumber, token: getToken()
      },
      (data) => {
        const {success, message} = data;

        if (!success) {
          this._notifyService.showMessage({
            text: message || 'Сталася помилка',
            type: 'error'
          });
        }
      });
  }

  getMetrologyData() {
    console.log("getMetrologyData ", this.relaySerialNumber);
    this.socket.emit('admin.relay-need-metrology', {
        serialNumber: this.relaySerialNumber, token: getToken()
      },
      (data) => {
        const {success, message} = data;

        if (!success) {
          this._notifyService.showMessage({
            text: message || 'Сталася помилка',
            type: 'error'
          });
        }
      });
  }

  updateFirmware() {
    console.log("updateFirmware ");

    if (!this.relaySerialNumber || !this.firmware) {
      console.log('No firmware to update')
      this._notifyService.showMessage({
        text: 'No firmware to update',
        type: "error"
      });
      return
    }
    this.socket.emit('admin.relay-update-firmware', {
        serialNumber: this.relaySerialNumber,
        firmware: this.firmware,
        token: getToken()
      },
      (data) => {
        const {success, message} = data;

        if (!success) {
          this._notifyService.showMessage({
            text: message || 'Сталася помилка',
            type: 'error'
          });
        } else {
          this._notifyService.showMessage({
            text: message || 'Операція прошла успішно',
            type: 'success'
          });
        }
      });
  }

  changeRelayData(event: any, field: string) {
    console.log('changeRelayData', event, field)

    this.relay[field] = event.target.value.trim();
  }

  moveToProduction(): void {
    this.loading = true;
    let endpoint = `api/admin/relay/${this.relaySerialNumber}/moveToProduction`;
    this._authHttp.post(endpoint, {})
      .subscribe(
        (success) => {
          this.getRelay();

          this.loading = false;
        },
        (error) => {
          console.log('this.relay error ', error);
          this.loading = false;
        }
      );
  }

  updateProdRelay(): void {
    if (!this.relay.idDb || !this.relay.typeRelay) {
      this._notifyService.showMessage({
        text: 'Некоректні дані',
        type: "error"
      });
      return
    }

    this.loading = true;
    let endpoint = `api/admin/relay/${this.relaySerialNumber}/updateProdRelay`;
    this._authHttp.post(endpoint, {
      idDb: this.relay.idDb,
      typeRelay: this.relay.typeRelay,
      isBroken: !!this.relay.isBroken
    })
      .subscribe(
        (success) => {
          this.loading = false;
          this._notifyService.showMessage({
            text: 'Збережено',
            type: "success"
          });
        },
        (error) => {
          console.log('this.relay error ', error);
          this._notifyService.showMessage({
            text: 'Помилка при збереженні',
            type: "error"
          });
          this.loading = false;
        }
      );
  }

  getLastErrors(page: number) {
    if (!this.relaySerialNumber) {
      this._notifyService.showMessage({
        text: 'Некоректні дані',
        type: "error"
      });
      return
    }

    this.loading = true;
    let endpoint = `api/admin/relay/${this.relaySerialNumber}/errors?page=${page || 1}`;
    this._authHttp.get(endpoint)
      .subscribe(
        (success) => {
          this.loading = false;
          this.errorsPage = page;
          this.errorsTotal = success['total']

          this.errors = [];
          let length = success['errors'].length;
          this.noErrorData = !length;

          for (let i = 0; i < length; i++) {
            let error = success['errors'][i];
            let reading = error['reading'];

            error = {...error, ...reading};
            delete error['reading'];

            this.errors.push(error);
          }
          this.errorKeys = Object.keys(this.errors[1] || {});
        },
        (error) => {
          console.log('this.relay error ', error);
          this._notifyService.showMessage({
            text: 'Помилка ',
            type: "error"
          });
          this.loading = false;
        }
      );
  }

  getLastErrorReadings(index: number, timestamp: number, page: number) {
    if (!this.relaySerialNumber || !timestamp) {
      this._notifyService.showMessage({
        text: 'Некоректні дані',
        type: "error"
      });
      return
    }

    this.errors = [this.errors[index]];

    this.loading = true;
    let endpoint = `api/admin/relay/${this.relaySerialNumber}/errors/readings?page=${page || 1}&timestamp=${timestamp}`;
    this._authHttp.get(endpoint)
      .subscribe(
        (success) => {
          this.loading = false;

          this.readings = [];
          let length = success['readings'].length;

          for (let i = 0; i < length; i++) {
            let reading = success['readings'][i];
            let data = reading['data'];

            reading = {
              ...reading,
              timestamp: data.timestamp,
              date: data.date,
              stamp_period: data.stamp_period,
              state: data.state,
              tot_negEnergyPA: data.tot_negEnergyPA,
              tot_negEnergyQA: data.tot_negEnergyQA,
              tot_negEnergySA: data.tot_negEnergySA,
              tot_posEnergyPA: data.tot_posEnergyPA,
              tot_posEnergyQA: data.tot_posEnergyQA,
              tot_posEnergySA: data.tot_posEnergySA
            };

            delete reading['data'];

            this.readings.push(reading);
          }
          this.readingsKeys = Object.keys(this.readings[1] || {});
        },
        (error) => {
          console.log('this.relay error ', error);
          this._notifyService.showMessage({
            text: 'Помилка ',
            type: "error"
          });
          this.loading = false;
        }
      );
  }

  saveAllErrorReading() {
    if (!this.relaySerialNumber) {
      this._notifyService.showMessage({
        text: 'Некоректні дані',
        type: "error"
      });
      return
    }

    this.loading = true;
    let endpoint = `api/admin/relay/${this.relaySerialNumber}/errors/saveAll`;
    this._authHttp.get(endpoint)
      .subscribe(
        (success) => {
          this.loading = false;
          let length = success['length'];
          let savedLength = success['savedLength'];

          this._notifyService.showMessage({
            text: `Збережено ${savedLength}. Залишилось ${length - savedLength} записів`,
            type: "success"
          });

        },
        (error) => {
          console.log('save all error ', error);
          this.loading = false;
          if (error['errorReading']) {
            this._notifyService.showMessage({
              text: `Помилка при збереженны запису з id ${error['errorReading']._id}, createdAt - ${error['errorReading'].createdAt}`,
              type: "error"
            });
          } else {
            this._notifyService.showMessage({
              text: 'Помилка ',
              type: "error"
            });
          }
        }
      );
  }

  removeErrorReading(index: number) {
    if (!this.relaySerialNumber) {
      console.log(this.relaySerialNumber);
      this._notifyService.showMessage({
        text: 'Некоректні дані',
        type: "error"
      });
      return
    }
    let id = this.errors[index] && this.errors[index]['_id'];

    console.log('id', id, this.errors[index]);
    if (!id) {
      console.log(this.relaySerialNumber);
      this._notifyService.showMessage({
        text: 'Некоректні дані',
        type: "error"
      });
      return
    }

    this.loading = true;
    let endpoint = `api/admin/relay/${this.relaySerialNumber}/errors/${id}`;
    this._authHttp.delete(endpoint)
      .subscribe(
        (success) => {
          this.loading = false;

          this.errors = this.errors.filter((item) => {
            return item._id !== id
          })
          this._notifyService.showMessage({
            text: 'Видалено',
            type: "success"
          });

        },
        (error) => {
          console.log('removeErrorReading error ', error);
          this.loading = false;

          this._notifyService.showMessage({
            text: 'Помилка ',
            type: "error"
          });
        }
      );
  }

  saveErrorReading(index: number) {
    if (!this.relaySerialNumber) {
      console.log(this.relaySerialNumber);
      this._notifyService.showMessage({
        text: 'Некоректні дані',
        type: "error"
      });
      return
    }
    let id = this.errors[index] && this.errors[index]['_id'];

    console.log('id', id, this.errors[index]);
    if (!id) {
      console.log(this.relaySerialNumber);
      this._notifyService.showMessage({
        text: 'Некоректні дані',
        type: "error"
      });
      return
    }

    this.loading = true;
    let endpoint = `api/admin/relay/${this.relaySerialNumber}/errors/${id}`;
    this._authHttp.post(endpoint, {})
      .subscribe(
        (success) => {
          this.loading = false;

          this.errors = this.errors.filter((item) => {
            return item._id !== id
          })
          this._notifyService.showMessage({
            text: 'Збережено',
            type: "success"
          });

        },
        (error) => {
          console.log('saveErrorReading error ', error);
          this.loading = false;

          const message = error && error.message;

          this._notifyService.showMessage({
            text: message || 'Помилка ',
            type: "error"
          });
        }
      );
  }

  getLogs(page: number) {
    const logTypes = {
      ...LOG_MESSAGES
    };

    this._relayService.getLogs(this.relaySerialNumber, page, this.limit)
      .subscribe(
        (success) => {
          const logs = []
          for (let i = 0; i < success['data'].length; i++) {
            const item = success['data'][i];

            const converted = {
              timestamp: item.timestamp,
              type: logTypes[item.code] || 'Невідомо',
              code: item.code,
              message: item.message,
              cp_time: item.cp_time
            };

            logs.push(converted);
          }
          this.logs = logs;
          this.lengthLogs = success['length'];
          this.page = page;
          this.firstPage = this.page === 1;
          this.lastPage = this.page*this.limit >= this.lengthLogs;
          this.loading = false;
        },
        (error) => {
          console.log('error', error);
          this.loading = false;
        });
  }

  changePage(page: number) {
    this.getLogs(page);
  }

  removeStats() {
    if (confirm("Впевнені ви хочете видалити всі хвилинні дані реле?")) {
      let endpoint = `api/admin/relay/${this.relay.idDb}/removeAllReadings`;
      this._authHttp.post(endpoint, {})
        .subscribe(
          (success) => {
            console.log(123, success);

            if (success['success']) {
              this.loading = false;

              this._notifyService.showMessage({
                text: 'Видалено',
                type: "success"
              });
            }else {
              this._notifyService.showMessage({
                text: 'Помилка ',
                type: "error"
              });
            }
          },
          (error) => {
            console.log('removeStats error ', error);
            this.loading = false;

            const message = error && error.message;

            this._notifyService.showMessage({
              text: message || 'Помилка ',
              type: "error"
            });
          }
        );
    }
  }

  sendSocketEvent() {
    this.socketEventResult = '';
    let convertedBody = {};
    if (!this.socketEventName || !this.socketEventBody) {
      this.socketEventResult = 'Незаповнена назва події або тіло';
      return;
    }

    try {
      convertedBody = JSON.parse(this.socketEventBody);
    } catch(e) {
      console.log(e);
      this.socketEventResult = 'Тіло запиту не валідний JSON';
      return;
    }

    this.isSocketEventInProgress = true;

    console.log('sendSocketEvent ', this.socketEventName, this.socketEventBody);
    this.socket.emit(this.socketEventName.trim(), {...convertedBody, token: getToken(), relayId: this.relay.idDb},
      (data) => {
        const {success, message} = data;

        if (!success) {
          this._notifyService.showMessage({
            text: message || 'Сталася помилка',
            type: 'error'
          });
        } else {
          this._notifyService.showMessage({
            text: message || 'Операція прошла успішно',
            type: 'success'
          });
        }
      });

    this.isSocketEventInProgress = false;
    this.socketEventName = '';
    this.socketEventBody = '';
  }

  sendEnergySocketEvent() {
    let energyBody = {};

    this.isEnergySocketEventInProgress = true;

    console.log('sendEnergySocketEvent ');
    this.socket.emit('admin:energy:sendServerSavedEnergy', {energy: energyBody, token: getToken(), relayId: this.relay.idDb, serialNumber: this.relay.serialNumber},
      (data) => {
        const {success, message} = data;

        if (!success) {
          this._notifyService.showMessage({
            text: message || 'Сталася помилка',
            type: 'error'
          });
        } else {
          this._notifyService.showMessage({
            text: message || 'Операція прошла успішно',
            type: 'success'
          });
        }

        this.isEnergySocketEventInProgress = false;
      });
  }

}


