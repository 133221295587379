import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Validators, FormControl } from "@angular/forms";

import {AuthService} from "../services/auth-service";
import {NotifyService} from "../../lib/services/notify.service";

@Component({
  selector: "change-password-cmp",
  templateUrl: "../templates/change-password.html",
  styleUrls: ["../styles/login.scss"]
})
export class ChangePasswordCmp implements OnInit, OnDestroy{
  title: string = "Change Password";
  passwordRepeat: string;
  password: string;
  token: string;
  isPasswordChanged: boolean = false;
  loading = false;
  passwordRepeatFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(6)
  ]);
  passwordFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(6)
  ]);
  timeout: any;

  constructor(
    private _route: ActivatedRoute,
    private _authService: AuthService,
    private _router: Router,
    private _notifyService: NotifyService
  ) {
    this.token = this._route.snapshot.paramMap.get('token');
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpireAt');
    localStorage.removeItem('currentUser');
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.timeout && clearTimeout(this.timeout)
  }

  changePassword() {
    this._authService.changePassword(this.password, this.passwordRepeat, this.token)
      .subscribe(
        () => {
          this._notifyService.showMessage({
            text: 'Пароль змінено. Залогінтеся з новим паролем.',
            type: "error",
            duration: 15000,
          });
          this.isPasswordChanged = true;

          this.timeout = setTimeout(() => {
            this._router.navigate(['/login']);
          }, 5000)

        },
        (error) => {
          console.log('error1 - ', error);
          this.loading = false;
          let message = 'Сталася помилка. Спробуйте ще раз пізніше';

          if (error.customError) {
            if (error.customError === 'PASSWORD_MISMATCH') {
              message = 'Паролі не збігаються. Спробуйте ще.';
            }else if (error.customError === 'INVALID_TOKEN') {
              message = 'Час дії посилання скінчився. Сгенеруйте нове.';
            }
          }

          this._notifyService.showMessage({
            text: message || 'Incorrect password or email',
            type: "error",
            duration: 15000,
          });

        }
      )
  }
}
