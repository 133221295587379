import {TOT_104_TH3, SWITCHER_3_TYPES } from "../relay/constants";

export const copyObj = (obj: any): any => {
  const res: any = Object.prototype.toString.call(obj).match(/array/i)? []: {};

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === 'object') {
        res[key] = copyObj(obj[key]);
      }else {
        res[key] = obj[key];
      }
    }
  }

  return res;
}


export const isDefined = (value: any): boolean => {
  return typeof value === 'undefined'
}

export const getTorStateByType = (relayType: string) => {
  if (SWITCHER_3_TYPES.includes(relayType)) {
    return [0, 0, 0];
  }

  if (relayType === TOT_104_TH3) {
    return [0, 0, 0, 0]
  }

  return [0, 0, 0, 0, 0, 0];
};
