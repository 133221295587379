import { Component, Input } from '@angular/core';

@Component({
  selector: 'elemeter-amplifier-indicator',
  templateUrl: "../templates/amplifier-indicator.html",
  styleUrls: ["../styles/amplifier-indicator.css"]
})
export class AmplifierIndicatorCmp {
  private _value: number;
  private cellsNumber : number = 40;
  @Input()  set value (newValue: number) {

    this.changedValue(newValue);
  }

  get value() {
    return this._value;
  }

  @Input()  name: string;
  @Input()  measure: string;
  @Input() settings: object;
  @Input() normal: any;
  @Input() warning: any;
  @Input() bad: any;
  relayId: string;

  cellsArray: any;
  cellsArrayColor: string = '';

  constructor() {
    this.cellsArray = [];
    for (let i = 0; i < this.cellsNumber; i++) {
      this.cellsArray.push({color: ''});
    }

    this.cellsArrayColor = 'normal';
  }

  changedValue(newValue: number) {
    newValue = Number(newValue);
    this._value = Number(newValue);

    if (this.normal && this.normal.length) {
      for (let i = 0; i < this.normal.length; i++) {
        let item = this.normal[i];
        let min = Number(item[0]);
        let max = Number(item[1]);

        if (newValue >= min && newValue <= max) {
          this.cellsArrayColor = 'normal';
        }
      };
    }

    if (this.warning && this.warning.length) {
      for (let i = 0; i < this.warning.length; i++) {
        let item = this.warning[i];
        let min = Number(item[0]);
        let max = Number(item[1]);

        if (newValue >= min && newValue <= max) {
          this.cellsArrayColor = 'warning';
        }
      };
    }

    if (this.bad && this.bad.length) {
      for (let i = 0; i < this.bad.length; i++) {
        let item = this.bad[i];
        let min = Number(item[0]);
        let max = Number(item[1]);

        if (newValue >= min && newValue <= max) {
          this.cellsArrayColor = 'bad';
        }
      };
    }

    if (!this.settings) this.settings = {};

    this.settings['max'] = this.settings['max']?Number(this.settings['max']):newValue * 1.1;

    for (let j = 0; j < this.cellsNumber; j++) {
      if (newValue*this.cellsNumber/this.settings['max'] >= j) {
        this.cellsArray[j] = {
          color: this.cellsArrayColor
        };
      }else {
        this.cellsArray[j] = {
          color: ''
        };
      }
    }
  }

}
