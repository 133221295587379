import {DateOptionsType} from "./relay.types";
import * as moment from "moment";

export const KMP_121_60 = 'KMP_121_60';
export const KMA_111_40 = 'KMA_111_40';
export const KIA_111_16 = 'KIA_111_16';
export const KCP_321_40 = 'KCP_321_40';
export const KCP_321_60 = 'KCP_321_60';
export const KMI_121_60 = 'KMI_121_60';
export const KMI_121_60_V2 = 'KMI_121_60_V2';
export const KCI_321_40 = 'KCI_321_40';
export const TCP_320_T5 = 'TCP_320_T5';
export const TOP_326_T01: string = 'TOP_326_T01';
export const TOP_326_T5: string = 'TOP_326_T5';
export const TOP_323_T01: string = 'TOP_323_T01';
export const TOP_323_T5: string = 'TOP_323_T5';
export const TOP_323_HT5: string = 'TOP_323_HT5';
export const KCP_301_60: string = 'KCP_301_60';
export const TOC_326_T5: string = 'TOC_326_T5';
export const KCP_323_60: string = 'KCP_323_60';
export const TOP_326_HT5: string = 'TOP_326_HT5';
export const KCP_303_60: string = 'KCP_303_60';
export const TOT_104_TH3: string = 'TOT_104_TH3';
export const TOT_106_TH6: string = 'TOT_106_TH6';
export const KMT_101_40: string = 'KMT_101_40';
export const TOP_324_T01: string = 'TOP_324_T01';
export const TOP_324_T5: string = 'TOP_324_T5';
export const TEP_321_T01: string = 'TEP_321_T01';

export const ALL_RELAY_TYPES: {[key: string]: string} = {
  KMP_121_60: KMP_121_60,
  KMA_111_40: KMA_111_40,
  KCP_321_40: KCP_321_40,
  KCP_321_60: KCP_321_60,
  KMI_121_60: KMI_121_60,
  KCI_321_40: KCI_321_40,
  TCP_320_T5: TCP_320_T5,
  TOP_326_T01: TOP_326_T01,
  TOP_323_T01: TOP_323_T01,
  TOP_324_T01: TOP_324_T01,
  TOP_323_T5: TOP_323_T5,
  TOP_324_T5: TOP_324_T5,
  KCP_323_60: KCP_323_60,
  KIA_111_16: KIA_111_16,
  TOP_323_HT5: TOP_323_HT5,
  TOP_326_T5: TOP_326_T5,
  KCP_301_60: KCP_301_60,
  TOC_326_T5: TOC_326_T5,
  TOP_326_HT5: TOP_326_HT5,
  KCP_303_60: KCP_303_60,
  TOT_104_TH3: TOT_104_TH3,
  KMT_101_40: KMT_101_40,
  TOT_106_TH6: TOT_106_TH6,
  KMI_121_60_V2: KMI_121_60_V2,
  TEP_321_T01: TEP_321_T01
};

// KMA_LIKE_RELAY_TYPES relays do not count reactive energy, do not have energy koeffs, etc
export const KMA_LIKE_RELAY_TYPES = [KMA_111_40, KIA_111_16, KMT_101_40];

export const TOP_RELAYS = [TOP_326_T01, TOP_323_T01, TOP_323_T5, KCP_323_60, TOP_323_HT5, TOP_326_T5, TOC_326_T5,
  TOP_326_HT5, KCP_303_60, TOT_104_TH3, TOT_106_TH6, TOP_324_T5, TOP_324_T01];
export const TEMPERATURE_RELAY_TYPES = [TOP_326_T01, TOP_323_T01, TOP_323_T5, TOP_326_T5, TOP_323_HT5, TOP_326_HT5,
  TOT_104_TH3, TOT_106_TH6, TOP_324_T5, TOP_324_T01];
export const PHASE_3_RELAY_TYPES = [KCP_321_40, KCP_321_60, KCI_321_40, TCP_320_T5, TOP_326_T01, TOP_323_T01,
  TOP_323_T5, KCP_323_60, TOP_323_HT5, TOP_326_T5, KCP_301_60, TOP_326_HT5, TOC_326_T5, KCP_303_60, TOT_104_TH3,
  TOP_324_T5, TOP_324_T01, TEP_321_T01];

export const SWITCHER_3_TYPES: string[] = [TOP_323_T01, TOP_323_T5, KCP_323_60, TOP_323_HT5, KCP_303_60];

export const SENSORS_3_RELAYS = [TOP_323_T01, TOP_323_T5, KCP_323_60, TOP_323_HT5, KCP_303_60, TOP_326_T01, TOP_326_T5,
  TOC_326_T5, TOP_326_HT5, TOT_104_TH3, TOP_324_T5, TOP_324_T01];
export const SENSORS_4_RELAYS = [];
export const SENSORS_6_RELAYS = [TOT_106_TH6];

export const RELAYS_3_NAMED = [TOP_323_T01, TOP_323_T5, KCP_323_60, TOP_323_HT5, KCP_303_60];
export const RELAYS_4_NAMED = [TOT_104_TH3, TOP_324_T5, TOP_324_T01];
export const RELAYS_6_NAMED = [TOP_326_T01, TOP_326_T5, TOC_326_T5, TOP_326_HT5, TOT_106_TH6];

export const VOLTAGE_KOEF_RELAY_TYPES = [TOP_323_HT5, TOP_326_HT5];
export const CURRENT_KOEF_RELAY_TYPES = [TOP_323_HT5, TOP_326_HT5, TCP_320_T5, TOP_326_T01, TOC_326_T5, TOP_323_T5, TOP_323_T01,
  TOP_326_T5, TOP_326_T01, TOP_324_T5, TOP_324_T01, TEP_321_T01];


export interface RelayNames {
  enable: boolean,
  list: {
    name: string;
    index: number;
  }[]
}

export interface SensorsNames {
  enable: boolean,
  list:  {
    name: string,
    index: number,
    measure: string,
    zeroValue: number,
    fullValue: number,
  }[]
}

const defualtRelayNames = (i: number) => ({
      name: `Реле Р${i+1}`,
      index: i
    }
);

export const getDefaultRelayNames = (typeRelay: string): RelayNames | null => {
  if (RELAYS_3_NAMED.includes(typeRelay)) {
    return {
      enable: false,
      list: [
        defualtRelayNames(0),
        defualtRelayNames(1),
        defualtRelayNames(2)
      ]
    }
  }

  if (RELAYS_6_NAMED.includes(typeRelay)) {
    return {
      enable: false,
      list: [
        defualtRelayNames(0),
        defualtRelayNames(1),
        defualtRelayNames(2),
        defualtRelayNames(3),
        defualtRelayNames(4),
        defualtRelayNames(5)
      ]
    }
  }

  if (RELAYS_4_NAMED.includes(typeRelay)) {
    return {
      enable: false,
      list: [
        defualtRelayNames(0),
        defualtRelayNames(1),
        defualtRelayNames(2),
        defualtRelayNames(3),
      ]
    }
  }

  return null;
};

const defaultSensorName = (i: number, isTopRelay: boolean) => { return {
  name: `Датчик t${i+1}`,
  index: i,
  measure: isTopRelay? 'градус': '',
  zeroValue: 0,
  fullValue: isTopRelay? 100: 0,
}};

export const getDefaultSensorNames = (typeRelay: string): SensorsNames | null => {
  if (SENSORS_3_RELAYS.includes(typeRelay)) {
     return {
      enable: false,
      list: [
        defaultSensorName(0, TOP_RELAYS.includes(typeRelay)),
        defaultSensorName(1, TOP_RELAYS.includes(typeRelay)),
        defaultSensorName(2, TOP_RELAYS.includes(typeRelay)),
      ]
    };
  }

  if (SENSORS_4_RELAYS.includes(typeRelay)) {
    return {
      enable: false,
      list: [
        defaultSensorName(0, TOP_RELAYS.includes(typeRelay)),
        defaultSensorName(1, TOP_RELAYS.includes(typeRelay)),
        defaultSensorName(2, TOP_RELAYS.includes(typeRelay)),
        defaultSensorName(3, TOP_RELAYS.includes(typeRelay))
      ]
    };
  }

  if (SENSORS_6_RELAYS.includes(typeRelay)) {
    return {
      enable: false,
      list: [
        defaultSensorName(0, TOP_RELAYS.includes(typeRelay)),
        defaultSensorName(1, TOP_RELAYS.includes(typeRelay)),
        defaultSensorName(2, TOP_RELAYS.includes(typeRelay)),
        defaultSensorName(3, TOP_RELAYS.includes(typeRelay)),
        defaultSensorName(4, TOP_RELAYS.includes(typeRelay)),
        defaultSensorName(5, TOP_RELAYS.includes(typeRelay)),
      ]
    };
  }

  return null;
};


export const energyDateTypes: DateOptionsType[] = [
  {
    label: 'Сьогодні', value: 'today',
    from: moment().startOf('day'),
    to:  moment().endOf('day'),
    dateString: (from, to) => `${from.format('DD MMM, YYYY')}`
  },
  {
    label: 'Вчора', value: 'yesterday',
    from: moment().subtract(1, 'days').startOf('day'),
    to:  moment().subtract(1, 'days').endOf('day'),
    dateString: (from, to) => `${from.format('DD MMM, YYYY')}`
  },
  {
    label: 'Цей місяць', value: 'this month',
    from: moment().startOf('months'),
    to:  moment().endOf('months'),
    dateString: (from, to) => `${from.format('DD MMM, YYYY')} - ${to.format('DD MMM, YYYY')}`
  },
  {
    label: 'Минулий місяць', value: 'last month',
    from: moment().subtract(1, 'months').startOf('month'),
    to:  moment().subtract(1, 'months').endOf('month'),
    dateString: (from, to) => `${from.format('DD MMM, YYYY')} - ${to.format('DD MMM, YYYY')}`
  },
  {
    label: 'Цей рік', value: 'this year',
    from: moment().startOf('years'),
    to:  moment().endOf('years'),
    dateString: (from, to) => `${from.format('DD MMM, YYYY')} - ${to.format('DD MMM, YYYY')}`
  },
  {
    label: 'Минулий рік', value: 'last year',
    from: moment().subtract(1, 'years').startOf('year'),
    to:  moment().subtract(1, 'years').endOf('year'),
    dateString: (from, to) => `${from.format('DD MMM, YYYY')} - ${to.format('DD MMM, YYYY')}`
  },
  {
    label: 'Остання година', value: 'last hour',
    from: moment().startOf('hour'),
    to:  moment().endOf('hour'),
    dateString: (from) => `${from.format('HH')} година`
  },
];

export const applianceDateTypes: DateOptionsType[] = [
  {
    label: 'Сьогодні', value: 'today',
    from: moment().startOf('day'),
    to:  moment().endOf('day'),
    dateString: (from, to) => `${from.format('DD MMM, YYYY')}`
  },
  {
    label: 'Вчора', value: 'yesterday',
    from: moment().subtract(1, 'days').startOf('day'),
    to:  moment().subtract(1, 'days').endOf('day'),
    dateString: (from, to) => `${from.format('DD MMM, YYYY')}`
  },
  {
    label: 'Цей місяць', value: 'this month',
    from: moment().startOf('months'),
    to:  moment().endOf('months'),
    dateString: (from, to) => `${from.format('DD MMM, YYYY')} - ${to.format('DD MMM, YYYY')}`
  },
  {
    label: 'Минулий місяць', value: 'last month',
    from: moment().subtract(1, 'months').startOf('month'),
    to:  moment().subtract(1, 'months').endOf('month'),
    dateString: (from, to) => `${from.format('DD MMM, YYYY')} - ${to.format('DD MMM, YYYY')}`
  }
];

export const LOG_MESSAGES = {
  0: 'UNKNOWN',
  1: 'Переповнення кількість записів подій в оперативній пам\'яті',
  2: 'Переповнення кількість записів подій на диску',
  3: 'Старт контролера після перезавантаження',
  4: 'Старт оноволення прошивки',
  5: 'Виконано оноволення прошивки',
  6: 'Помилка ініацалізації АЦП лічильника',
  7: 'Помилка читання даних з ADE',
  8: 'Помилка обробки переривання з ADE',
  10: 'Перезавантаження ESP через довгий час автономної роботи',
  11: 'Перезавантаження ESP через не проходження циклу loop',
  12:	'Перезавантаження ESP через натискання кнопки',
  20:	'Втрачено дані з обох файлів енергії',
  21:	'Втрачено дані першого файла енергії',
  22:	'Втрачено дані другого файла енергії',
  23:	'Дані файла енергії зібрані з двох файлів енергії',
  24:	'Є різниця між фалами енергії',
  25: 'Втрата забракованих сервером даних',
  26: 'Не встиг зберегти дані Т1 до появи нових',
  27: 'Не знайшов файл, хоча він мав бути за умовою',
  100: 'Вимкнення реле',
  110: 'Увімкнення реле',
  120: 'Перевищення діючої напруги',
  121: 'Перевищення миттєвої напруги',
  122: 'Зменшення діючої напруги',
  123: 'Перевищення диференційного струму',
  124: 'Перевищення діючого струму',
  125: 'Перевищення максимальної температури приладу',
  126: 'Перевищення користувацької температури приладу',
  127: 'Умова активної потужності',
  128: 'Умова частоти',
  129: 'Зміна налаштувань користувача',
  130: 'Захист у нормі',
  131: 'Захист лінії 1 у нормі',
  132: 'Захист лінії 2 у нормі',
  133: 'Захист лінії 3 у нормі',
  134: 'Захист лінії 4 у нормі',
  135: 'Захист лінії 5 у нормі',
  136: 'Захист лінії 6 у нормі',
  137: 'Захист лінії 7 у нормі',
  138: 'Захист лінії 8 у нормі',
  139: 'Захист лінії 9 у нормі',
  140: 'Перевищення миттєвого струму А або Умова реактивної потужності',
  141: 'Перевищення миттєвого струму B або Умова послідовності фаз',
  200: 'Зникнення живлення приладу',
  201: 'Поява живлення приладу',
  202: 'Зникало живлення приладу не точний час',
  300: 'Натискання кнопки Off',
  310: 'Натискання кнопки On',
  320: 'Натискання кнопки WPS Stop',
  321: 'Натискання кнопки WPS Start',
  322: 'Натискання кнопки AP Stop',
  323: 'Натискання кнопки AP Start',
  324: 'Натискання кнопки Скидання налаштувань',
  1001: 'Увімкнення приладу користувачем',
  1002: 'Вимкнення приладу користувачем',
  1011: 'Увімкнення розкладу користувачем',
  1012: 'Вимкнення розкладу користувачем',
  1021: "Прилад офлайн",
  1022: "Прилад онлайн",
};

export const DEFAULT_SENSOR_SETTINGS = {
  zeroSensorValue: 0,
  fullSensorValue: 100,
  measure: 'C'
}

export const sensorsCountByType = {
  TOC_326_T5: 3,
  TOP_323_HT5: 3,
  TOP_323_T01: 3,
  TOP_323_T5: 3,
  TOP_324_T5: 3,
  TOP_324_T01: 3,
  TOP_326_T5: 3,
  TOP_326_HT5: 3,
  TOP_326_T01: 3,
  TOT_104_TH3: 3,
  TOT_106_TH6: 3
}

export const convertSensorPercentsToValue = ({fullValue = 100, zeroValue = 0, value = 0}) =>{
  const koef = (fullValue - zeroValue) / 100;
  return Math.round((zeroValue + koef * value) * 100) / 100;
}

export const convertSensorValueToPercents = ({fullValue = 100, zeroValue = 0, value = 0}) =>{
  return Math.round((value - zeroValue) * 100 * 100 / (fullValue - zeroValue)) / 100
}

export const getSensorIndexByChannel = (chanel: number) => {
  let listIndex = 0

  switch (chanel) {
    case 4:
      listIndex = 1;
      break;
    case 8:
      listIndex = 2;
      break;
    case 16:
      listIndex = 3;
      break;
    case 32:
      listIndex = 4;
      break;
    case 64:
      listIndex = 5;
      break;
  }

  return listIndex
}
