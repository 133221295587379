import {Component, OnInit, OnDestroy} from "@angular/core";
import {AuthService} from "../../../auth/services/auth-service";
import {ActivatedRoute, ParamMap, Router} from '@angular/router';

import {HttpClient} from '@angular/common/http';

import {NotifyService} from '../../../lib/services/notify.service';
import {getToken} from '../../../utils/authTokens';
import {
  RelayService
} from "../../../relay/services/relay-service";
import * as moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {Moment} from 'moment';
import {FormControl} from '@angular/forms';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const DEFAULT_STATS = {
  relaysCount: 0,
  relaysCountThisMonth: 0,
  relaysMore5Users: 0,
  relaysMore10Users: 0,
  relaysMore20Users: 0,
  uniqueUsers: 0,
  registeredUsers: 0,
  registersUsersThisMonth: 0,
  admins: 0,
  usersMore5Relays: 0,
  usersMore10Relays: 0,
  usersMore20Relays: 0,
  apiRequests: 0,
}

@Component({
  selector: "report-users",
  templateUrl: "../templates/report-users.html",
  styleUrls: ["../styles/report-users.scss"],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'uk-UA'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})

export class ReportUsersComponent implements OnInit, OnDestroy {
  date = new FormControl(moment());
  dateObj: Moment = moment()
  users: any = [];
  relays: any = [];
  requests: any = [];
  usersLoaded: boolean = false;
  relaysLoaded: boolean = false;
  stats = {...DEFAULT_STATS};
  loading: boolean = false;

  constructor(private _authService: AuthService,
              private _authHttp: HttpClient,
              private _router: Router,
              private _route: ActivatedRoute,
              private _notifyService: NotifyService,
              private _relayService: RelayService
  ) {
  }

  ngOnInit() {
    this.getStats();
  }

  ngOnDestroy() {

  }

  getStats() {
    this.loading = true;
    const month = this.dateObj.format('M')
    const year = this.dateObj.format('YYYY')
    let endpoint = `api/admin/usersReport?month=${month}&year=${year}`;
    this.stats = {...DEFAULT_STATS};
    this._authHttp.get(endpoint)
      .subscribe(
        (success) => {

          this.relays = success["relays"]?success["relays"]:[];
          this.users = success["users"]?success["users"]:[];
          this.requests = success["requests"]?success["requests"]:[];

          console.log('this.relays ', this.relays, this.users, this.requests);

          const startOfMonth = +moment().startOf('month')
          const endOfMonth = +moment().endOf('month')
          this.relays.forEach((relay) => {
            this.stats.relaysCount++

            if (+new Date(relay.createdAt) > startOfMonth && +new Date(relay.createdAt) < endOfMonth) {
              this.stats.relaysCountThisMonth++
            }

            if (relay.users.length > 5) {
              this.stats.relaysMore5Users++
            }

            if (relay.users.length > 10) {
              this.stats.relaysMore10Users++
            }

            if (relay.users.length > 20) {
              this.stats.relaysMore20Users++
            }

            relay.users.forEach(u => {
              if (u.role === 'owner') {
                this.stats.admins++
              }
            })
          })

          this.users.forEach(user => {
            this.stats.registeredUsers++

            if (+new Date(user.createdAt) > startOfMonth && +new Date(user.createdAt) < endOfMonth) {
              this.stats.registersUsersThisMonth++
            }

            if (user.relays.length > 5) {
              this.stats.usersMore5Relays++
            }

            if (user.relays.length > 10) {
              this.stats.usersMore10Relays++
            }

            if (user.relays.length > 20) {
              this.stats.usersMore20Relays++
            }
          })

          let totalRequests = 0
          this.requests.forEach(r => {
            this.stats.uniqueUsers++

            Object.values(r.requests).forEach((req: {count: number}) => {
              totalRequests += req.count
            })
          })

          this.stats.apiRequests = totalRequests

          this.loading = false
        },
        (error) => {
          console.log('this.relays error ', error);
          this.loading = false;
          this._notifyService.showMessage({
            text: 'Сталася помилка',
            type: "error"
          })
        }
      );
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }

  changeDayInput(event: any) {
    console.log(234, event, this.date, this.dateObj);
    this.dateObj = event.value

    this.getStats()
  }
}


