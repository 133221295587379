import {
  Component,
  OnInit,
  OnDestroy,
  Input
} from "@angular/core";


import { RelayService } from "../services/relay-service";

import { NotifyService } from '../../lib/services/notify.service';

import {NavigationStart, Router, ActivatedRoute, ParamMap} from '@angular/router';
import 'rxjs/add/operator/switchMap';
import {UserService} from "../../user/services/user-service";

@Component({
  selector: "relay-logs",
  templateUrl: "../templates/relay-logs.html",
  styleUrls: ["../styles/relay.scss", "../styles/relay-logs.scss"]
})
export class RelayLogsCmp implements OnInit, OnDestroy {
  loading: boolean = true;
  relayId: string;
  logs: any = [];
  page: number = 1;
  firstPage:boolean = true;
  lastPage: boolean = true;
  limit: number = 50;
  lengthLogs: number = 0;
  user: any;
  relays: any;

  constructor(
    public _relayService: RelayService,
    private route: ActivatedRoute,
    private _notifyService: NotifyService,
    private _userService: UserService
    ) {
    this.loading = true;



  }

  ngOnInit() {
    this.getLogs(this.page);
    let user = this._userService.getLocalUser()

    if (user && user['_id']){
      this.user = user;
      this.relays = user['relays'];
    }else {
      let userId = JSON.parse(localStorage.getItem('currentUser'))._id;
      this._userService.getUser(userId)
        .subscribe((user) => {
          this.user = user;
          this.relays = user['relays'];
        }, (e) => {
          console.log(e);
        })
    }
  }

  getLogs(page: number) {
    this._relayService.getLogs('', page, this.limit)
      .subscribe(
        (success) => {
          console.log('success', success);
          this.logs = success['data'];
          this.lengthLogs = success['length'];
          this.page = page;
          this.firstPage = this.page === 1;
          this.lastPage = this.page*this.limit >= this.lengthLogs;
          this.loading = false;
        },
        (error) => {
          this._notifyService.showMessage({
            message: error,
            type: 'error'
          });
          console.log('error', error);
          this.loading = false;
        });
  }

  changePage(page: number) {
    this.getLogs(page);
  }

  ngOnDestroy() {
    console.log('RelayStatsCmp Destroy');
  }

}
