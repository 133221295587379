import {
  Component,
  Input
} from "@angular/core";

@Component({
  selector: "info-icon-component",
  template: `
    <div class="info-helper" [matTooltip]="infoText" #tooltip="matTooltip" (click)="tooltip.toggle()">
      <i class="fa fa-info" aria-hidden="true"></i>
    </div>
    
  `,
  styles: [`    
    .info-helper {
      margin: 0 10px;
      color: #555;
      padding: 3px;
      border: 1px solid #555;
      border-radius: 50%;
      display: inline-flex;
      font-size: 12px;
      width: 12px;
      height: 12px;
      justify-content: center;
      align-items: center;
    }
  `]
})
export class InfoComponent{
  @Input() infoText: string;

}
